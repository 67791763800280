type StageLogoProps = {
  alt?: string;
  className?: string;
};

/**
 * Stage+ logo.
 */
export function StageLogo({ alt, className }: StageLogoProps) {
  return (
    <svg viewBox="0 0 310 64" width={310} height={64} className={className}>
      {alt && <title>{alt}</title>}
      <g className="text-white light:text-brand-stage" fill="currentColor">
        <path
          d="m16.5 62.1999c-7.4 0-13.4-3.8-16.5-9.5l6-4.5c2.3 3.2 5.4 6.2 10.5 6.2 3.8 0 6.1-1.8 6.1-4.7 0-2.8-2.1-3.8-5.4-5.3l-4.6-2.1c-5.9-2.7-10.4-6.2-10.4-12.3 0-7.4 6.2-12.1 14.2-12.1 6.8 0 11.6 3.4 14.1 8.1l-6 4.6c-2.1-3.1-4.7-4.9-8.2-4.9-2.9 0-5.3 1.3-5.3 4.2 0 2.5 1.9 3.5 5 4.9l4.9 2.1c6.7 3 10.6 6.4 10.6 12.5 0 7.9-6.5 12.8-15 12.8z"
          opacity=".5"
        />
        <path d="m68.9 61.7h-8.8v-36h-13.1v-7.5h35v7.5h-13.1z" opacity=".6" />
        <path
          d="m135.4 61.7001h-9.2l-3.5-9.7h-16.4l-3.5 9.7h-9.2l16.6-43.6h8.6zm-20.8-32.5-5.6 15.6h11.2z"
          opacity=".7"
        />
        <path
          d="m173.5 62.2999c-10.7 0-21.4-6.9-21.4-22.3s11.1-22.2999 22.1-22.2999c10.9 0 18.6 6.0999 19.3 15.2999h-8.8c-.8-4.8-4.9-7.7999-10.6-7.7999-8.2 0-13.6 5.8999-13.6 14.7999 0 9 5.5 15.2001 13.5 15.2001 5.7 0 11.2-4.2001 11.2-8.4001 0-1.2-.8-2.0999-2.1-2.0999h-8.2v-6.5h11.7c3.8 0 6.8 2.9999 6.8 6.7999v16.7001h-7.8v-6.4001l-.6-.0999c-1.4 4.2-6 7.0999-11.5 7.0999z"
          opacity=".8"
        />
        <path d="m246.1 61.7h-28.2v-43.5h28.2v7.5h-19.4v10h16.4v7.5h-16.4v11h19.4z" opacity=".9" />
        <path d="m309 18.2585014h-18.258503v-18.2585014h-7.482994v18.2585014h-18.258503v7.4829932h18.258503v18.2585034h7.482994v-18.2585034h18.258503z" />
      </g>
    </svg>
  );
}
