import { Fragment } from 'react';
import ChevronDownIcon from '@stageplus/icons/react/chevron-down';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import ContextMenu from 'src/components/context-menu';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useTranslate from 'src/hooks/use-translate';
import { SupportedLocale } from 'src/types';
import { supportedLocales } from 'src/utilities/i18n-helpers';

const localesNames: Record<SupportedLocale, string> = { en: 'English', de: 'Deutsch', ja: '日本語' };

export default function NavigationLanguageSwitcher() {
  const { asPath, pathname, query, push } = useRouter();
  const currentLocale = useCurrentLocale();
  const t = useTranslate();

  async function handleLocaleSelect(locale: SupportedLocale) {
    // Set `NEXT_LOCALE` cookie to persist locale selection between sessions for 1 year
    const cookies = new Cookies();
    cookies.set('NEXT_LOCALE', locale, { path: '/', maxAge: 1000 * 60 * 60 * 24 * 365 });
    // Redirect to the same page with the new locale
    await push({ pathname, query }, asPath, { locale });
  }

  return (
    <ContextMenu data-test="language-switcher" className="relative h-full">
      <ContextMenu.Button as={Fragment}>
        {({ open }) => (
          <button
            aria-label={t('language_switcher__label')}
            className={clsx(
              'group h-full w-11 select-none outline-none mouse:hover:text-brandYellowC1',
              open ? 'text-brandYellowC1' : '',
            )}
          >
            <span className="flex items-center justify-center rounded-sm px-1 py-4 outline-offset-2 group-focus-visible:focus-outline">
              <span className="dg-text-regular-4 pr-1 uppercase">{currentLocale}</span>
              <ChevronDownIcon className="size-4 shrink-0" aria-hidden />
            </span>
          </button>
        )}
      </ContextMenu.Button>
      <ContextMenu.Items>
        {supportedLocales?.map((locale) => (
          <ContextMenu.Item key={locale} onClick={() => handleLocaleSelect(locale)}>
            <span
              className={
                locale === currentLocale ? 'dg-text-medium-8 text-mainBgBlueC2' : 'text-greyG2 hover:text-mainBgBlueC2'
              }
            >
              {localesNames[locale]}
            </span>
          </ContextMenu.Item>
        ))}
      </ContextMenu.Items>
    </ContextMenu>
  );
}
