globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"4fcc04e256e105c7a6f9ddb3bee1f9fa2266e10a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import { enabledSentryEnvironment } from 'src/config/sentry.mjs';
import { Vendor, checkVendorConsent, onConsentChange } from 'src/utilities/consent-manager';

// get the environment, keep in mind these values will be overwritten during the build process on the server
const environment = process.env.NEXT_PUBLIC_VERCEL_ENV;

// initialize Sentry logging on the client side
// keep in mind that the server side logging is handled in the `instrumentation.ts` file

function initSentry() {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // control the amount of the logged transactions, adjust even lower if hitting the Sentry billing threshold
    tracesSampleRate: 0.1,
    // use release number and hash fragment when identifying sentry logs
    release: process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    // allow to filter errors by environment (production, preview, development)
    environment,
    // the sentry debugging can be enabled by setting the debug flag to `true`
    debug: false,
    integrations: [
      // disable the default global onError handler, as this is handled by the error boundary
      Sentry.globalHandlersIntegration({
        onerror: false,
        onunhandledrejection: false,
      }),
    ],
    // before the event is sent to Sentry,
    // remove any sensitive data from the request variables
    beforeSend: (event) => {
      // check if the error is coming from mutation
      if (
        'error' in event &&
        typeof event.error === 'object' &&
        event.error !== null &&
        'request' in event.error &&
        typeof event.error.request === 'object' &&
        event.error.request !== null &&
        'variables' in event.error.request &&
        typeof event.error.request.variables === 'object' &&
        event.error.request.variables !== null &&
        'input' in event.error.request.variables &&
        typeof event.error.request.variables.input === 'object' &&
        event.error.request.variables.input !== null
      ) {
        // remove password
        if (
          'password' in event.error.request.variables.input &&
          typeof event.error.request.variables.input.password === 'string'
        ) {
          event.error.request.variables.input.password = '***';
        }
        // remove old password when changing password
        if (
          'oldPassword' in event.error.request.variables.input &&
          typeof event.error.request.variables.input.oldPassword === 'string'
        ) {
          event.error.request.variables.input.oldPassword = '***';
        }
        // remove new password when changing password
        if (
          'newPassword' in event.error.request.variables.input &&
          typeof event.error.request.variables.input.newPassword === 'string'
        ) {
          event.error.request.variables.input.newPassword = '***';
        }
      }
      return event;
    },
  });
}

// enable sentry only if the client conditions are met
function verifyAndInit() {
  const userGivenConsent = checkVendorConsent(Vendor.Sentry);
  // verify if the environment is enabled and the user has given consent
  if (userGivenConsent && enabledSentryEnvironment) {
    // if the user has given consent, initialize sentry immediately
    initSentry();
  } else if (userGivenConsent === false && Sentry.isInitialized()) {
    // if Sentry is running and the user has revoked previously consent (consent value is now `false`, but not `undefined`),
    // close the client so it stops sending logs
    void Sentry.close();
  } else {
    // otherwise, do nothing
  }
}

// try to initialize Sentry logging immediately
verifyAndInit();

// also, wait until the user has updated their tracking consent
onConsentChange(verifyAndInit);
