import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
};

export type AddFavoriteInput = {
  /** id of an album, video or performance work */
  contentId: Scalars['ID']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type AddFavoritePayload = {
  __typename: 'AddFavoritePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Favorite>;
};

export type Album = Node & {
  __typename: 'Album';
  /** The album artists and groups as displayed on the DG website, comma separated. */
  artistAndGroupDisplayInfo?: Maybe<Scalars['String']['output']>;
  artists?: Maybe<ArtistWithRoleConnection>;
  atmosUpc?: Maybe<Scalars['String']['output']>;
  copyright: Scalars['String']['output'];
  curatedContent: Array<CuratedContentContainerV2>;
  groups: Array<Group>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  label: Label;
  originalReleaseDate?: Maybe<Scalars['Date']['output']>;
  partners: Array<Partner>;
  phonographicCopyright: Scalars['String']['output'];
  pictures: Array<Picture>;
  releaseDate?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  title: Scalars['String']['output'];
  /** Total duration of all tracks combined in seconds */
  totalDuration: Scalars['Int']['output'];
  trackSets: Array<TrackSet>;
  upc: Scalars['String']['output'];
};

export type AlbumArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

export type AlbumConnection = {
  __typename: 'AlbumConnection';
  edges: Array<AlbumEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type AlbumEdge = {
  __typename: 'AlbumEdge';
  cursor: Scalars['String']['output'];
  node: Album;
};

export type AlbumWithArtistRoleConnection = {
  __typename: 'AlbumWithArtistRoleConnection';
  edges: Array<AlbumWithArtistRoleEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type AlbumWithArtistRoleEdge = {
  __typename: 'AlbumWithArtistRoleEdge';
  cursor: Scalars['String']['output'];
  node: Album;
  role: Role;
};

export type ApiError = {
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

export type Artist = Node & {
  __typename: 'Artist';
  albums?: Maybe<AlbumWithArtistRoleConnection>;
  biography?: Maybe<Scalars['String']['output']>;
  courtesyOf?: Maybe<Scalars['String']['output']>;
  curatedContent: Array<CuratedContentContainerV2>;
  /** Year string made up out of integers. No guarantees on length. */
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  /** Year string made up out of integers. No guarantees on length. */
  dateOfDeath?: Maybe<Scalars['String']['output']>;
  epoch?: Maybe<Epoch>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pictures: Array<Picture>;
  roles: Array<Role>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  sortLetter?: Maybe<Scalars['String']['output']>;
  sortName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArtistAlbumsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ArtistConnection = {
  __typename: 'ArtistConnection';
  edges: Array<ArtistEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type ArtistEdge = {
  __typename: 'ArtistEdge';
  cursor: Scalars['String']['output'];
  node: Artist;
};

export type ArtistOrdering = {
  direction?: InputMaybe<SortDirection>;
  sort?: InputMaybe<ArtistSort>;
};

/** Only return artists that hold one of the specified roles on this asset */
export const ArtistRoleFilter = {
  /** All roles */
  All: 'ALL',
  /** Conductors */
  Conductors: 'CONDUCTORS',
  /** Vocal and Instrumental Solists */
  Soloists: 'SOLOISTS',
} as const;

export type ArtistRoleFilter = (typeof ArtistRoleFilter)[keyof typeof ArtistRoleFilter];
export const ArtistSort = {
  /** Manual Sort Order fixed by editors in the AdminUI. */
  Position: 'POSITION',
} as const;

export type ArtistSort = (typeof ArtistSort)[keyof typeof ArtistSort];
export type ArtistWithRoleConnection = {
  __typename: 'ArtistWithRoleConnection';
  edges: Array<ArtistWithRoleEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type ArtistWithRoleEdge = {
  __typename: 'ArtistWithRoleEdge';
  cursor: Scalars['String']['output'];
  node: Artist;
  role: Role;
};

export type AuthorizeDeviceForUserInput = {
  /** Code of the device the user wants to authorize */
  userCode: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type AuthorizeDeviceForUserInputPayload = {
  __typename: 'AuthorizeDeviceForUserInputPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The authorized device */
  result?: Maybe<Device>;
};

export type Banner = {
  __typename: 'Banner';
  description: Scalars['String']['output'];
  link: Scalars['String']['output'];
  pictures: Array<Picture>;
  title: Scalars['String']['output'];
};

export type BasicError = ApiError & {
  __typename: 'BasicError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type CancelSubscriptionPayload = {
  __typename: 'CancelSubscriptionPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<SubscriptionCancelation>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  newPasswordConfirmation: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ChangePasswordPayload = {
  __typename: 'ChangePasswordPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ChangeStripePaymentMethodPayload = {
  __typename: 'ChangeStripePaymentMethodPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<SetupIntentClientSecret>;
};

export type ChangeStripeSubscriptionPlanInput = {
  productId: Scalars['String']['input'];
  prorationDate: Scalars['Int']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ChangeStripeSubscriptionPlanPayload = {
  __typename: 'ChangeStripeSubscriptionPlanPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckETicketPayload = {
  __typename: 'CheckETicketPayload';
  /** Voucher metadata when a valid code is given */
  voucher?: Maybe<Voucher>;
};

export type CheckRegistrationDataInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export const Client = {
  Android: 'ANDROID',
  AndroidTv: 'ANDROID_TV',
  Ios: 'IOS',
  SmartTv: 'SMART_TV',
  Tvos: 'TVOS',
  VisionOs: 'VISION_OS',
  Web: 'WEB',
} as const;

export type Client = (typeof Client)[keyof typeof Client];
export type ConfirmPurchaseAmazonInput = {
  customerId: Scalars['String']['input'];
  receiptId: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ConfirmPurchaseAmazonPayload = {
  __typename: 'ConfirmPurchaseAmazonPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmPurchaseAppleInput = {
  transactionJws: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ConfirmPurchaseApplePayload = {
  __typename: 'ConfirmPurchaseApplePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type ConsentTrackingInput = {
  consent: TrackingConsentType;
  service: TrackingServiceType;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ConsentTrackingPayload = {
  __typename: 'ConsentTrackingPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type Country = {
  __typename: 'Country';
  displayName: Scalars['String']['output'];
  iso: Scalars['String']['output'];
};

export type Coupon = {
  __typename: 'Coupon';
  durationInMonths?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  percentOff: Scalars['Float']['output'];
  /** List of productIds this coupon is limited to. Applies to all productIds when empty. */
  productIdsLimitedTo: Array<Scalars['String']['output']>;
  promotionCode: Scalars['String']['output'];
};

export type CuePoint = {
  __typename: 'CuePoint';
  /** Offset of the cue-point marker within this performance work in seconds. Starts at zero again for each performance work. */
  mark: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type CuratedContent = {
  __typename: 'CuratedContent';
  /** @deprecated use curated_content instead */
  contentContainers: Array<CuratedContentContainer>;
  curatedContent: Array<CuratedContentContainerV2>;
  slug: CuratedContentSlug;
  /** @deprecated use stage_slider instead */
  stage: Array<StageItem>;
  stageSlider: Array<StageItemV2>;
};

export type CuratedContentCuratedContentArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type CuratedContentContainer = Banner | Slider;

export type CuratedContentContainerV2 = Banner | ListSlider;

export const CuratedContentSlug = {
  Audio: 'AUDIO',
  Discover: 'DISCOVER',
  LandingPage: 'LANDING_PAGE',
  Live: 'LIVE',
  Search: 'SEARCH',
} as const;

export type CuratedContentSlug = (typeof CuratedContentSlug)[keyof typeof CuratedContentSlug];
/** Payload of this mutation - contains either a result on success or a list of errors */
export type DeleteUserPayload = {
  __typename: 'DeleteUserPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<User>;
};

export type DenyDeviceInput = {
  /** Code of the device the user wants to deny (cancel the device flow) */
  userCode: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type DenyDeviceInputPayload = {
  __typename: 'DenyDeviceInputPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The denied pending device */
  result?: Maybe<Device>;
};

export type Device = {
  __typename: 'Device';
  deviceInfo: Scalars['String']['output'];
  /** UUID that is also part of a devices JWTs */
  id: Scalars['String']['output'];
  insertedAt: Scalars['DateTime']['output'];
  /** The last time the device info was updated OR login OR token refresh happened */
  updatedAt: Scalars['DateTime']['output'];
};

export type Epoch = Node & {
  __typename: 'Epoch';
  composers: Array<Artist>;
  curatedContent: Array<CuratedContentContainerV2>;
  description?: Maybe<Scalars['String']['output']>;
  endYear?: Maybe<Scalars['Int']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  pictures: Array<Picture>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  startYear?: Maybe<Scalars['Int']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type EpochConnection = {
  __typename: 'EpochConnection';
  edges: Array<EpochEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type EpochEdge = {
  __typename: 'EpochEdge';
  cursor: Scalars['String']['output'];
  node: Epoch;
};

export const FacetType = {
  Composers: 'COMPOSERS',
  Conductors: 'CONDUCTORS',
  Ensembles: 'ENSEMBLES',
  Soloists: 'SOLOISTS',
  Venue: 'VENUE',
} as const;

export type FacetType = (typeof FacetType)[keyof typeof FacetType];
export type Favorite = Node & {
  __typename: 'Favorite';
  content: FavoriteContent;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FavoriteConnection = {
  __typename: 'FavoriteConnection';
  edges: Array<FavoriteEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FavoriteContent = Album | PerformanceWork | Video;

export type FavoriteEdge = {
  __typename: 'FavoriteEdge';
  cursor: Scalars['String']['output'];
  node: Favorite;
};

export type FavoriteOrdering = {
  direction?: InputMaybe<SortDirection>;
  sort?: InputMaybe<FavoriteSort>;
};

export const FavoriteSort = {
  Id: 'ID',
  UpdatedAt: 'UPDATED_AT',
} as const;

export type FavoriteSort = (typeof FavoriteSort)[keyof typeof FavoriteSort];
export const FavoriteType = {
  /** Return only albums */
  Album: 'ALBUM',
  /** Return all types of content */
  All: 'ALL',
  /** Return only performance works */
  PerformanceWork: 'PERFORMANCE_WORK',
  /** Return videos and performance works */
  PerformanceWorkAndVideo: 'PERFORMANCE_WORK_AND_VIDEO',
  /** Return only videos */
  Video: 'VIDEO',
} as const;

export type FavoriteType = (typeof FavoriteType)[keyof typeof FavoriteType];
/** Payload of this mutation - contains either a result on success or a list of errors */
export type GeneratePinCodePayload = {
  __typename: 'GeneratePinCodePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<PinCode>;
};

export type Genre = Node & {
  __typename: 'Genre';
  curatedContent: Array<CuratedContentContainerV2>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  pictures: Array<Picture>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  title: Scalars['String']['output'];
};

export type GenreConnection = {
  __typename: 'GenreConnection';
  edges: Array<GenreEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type GenreEdge = {
  __typename: 'GenreEdge';
  cursor: Scalars['String']['output'];
  node: Genre;
};

export const GeoAccessMode = {
  Allow: 'ALLOW',
  Deny: 'DENY',
} as const;

export type GeoAccessMode = (typeof GeoAccessMode)[keyof typeof GeoAccessMode];
export type GeoinfoPayload = {
  __typename: 'GeoinfoPayload';
  /** An ISO 3166-1 two letter country code for the country the implicit or explicit ip resides in OR the user country (when called authenticated) */
  countryCode: Scalars['String']['output'];
  /** An ISO 4217 three letter currency code connected to the country code and supported by DG OR the currency used for the first Stripe subscription */
  currencyCode: Scalars['String']['output'];
  /** The IP address that was used to lookup the country code */
  ip: Scalars['String']['output'];
};

export type Group = Node & {
  __typename: 'Group';
  albums: Array<Album>;
  curatedContent: Array<CuratedContentContainerV2>;
  description?: Maybe<Scalars['String']['output']>;
  footnote?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pictures: Array<Picture>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  sortLetter?: Maybe<Scalars['String']['output']>;
  type: GroupType;
  typeDisplayName: Scalars['String']['output'];
  vodConcerts?: Maybe<VodConcertConnection>;
};

export type GroupVodConcertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupConnection = {
  __typename: 'GroupConnection';
  edges: Array<GroupEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type GroupEdge = {
  __typename: 'GroupEdge';
  cursor: Scalars['String']['output'];
  node: Group;
};

export const GroupType = {
  Band: 'BAND',
  ChamberEnsemble: 'CHAMBER_ENSEMBLE',
  ChamberOrchestra: 'CHAMBER_ORCHESTRA',
  Choir: 'CHOIR',
  Combo: 'COMBO',
  Duo: 'DUO',
  Ensemble: 'ENSEMBLE',
  Octett: 'OCTETT',
  Orchestra: 'ORCHESTRA',
  Quartet: 'QUARTET',
  Trio: 'TRIO',
  Various: 'VARIOUS',
} as const;

export type GroupType = (typeof GroupType)[keyof typeof GroupType];
export const ItemSortOrder = {
  Custom: 'CUSTOM',
} as const;

export type ItemSortOrder = (typeof ItemSortOrder)[keyof typeof ItemSortOrder];
export type Label = Node & {
  __typename: 'Label';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ListSlider = {
  __typename: 'ListSlider';
  headline: Scalars['String']['output'];
  id: Scalars['String']['output'];
  items: Array<SliderItem>;
  totalResults: Scalars['Int']['output'];
};

export type ListSliderItemsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type LiveConcert = Node & {
  __typename: 'LiveConcert';
  artists?: Maybe<ArtistWithRoleConnection>;
  copyright?: Maybe<Scalars['String']['output']>;
  courtesyOf?: Maybe<Scalars['String']['output']>;
  curatedContent: Array<CuratedContentContainerV2>;
  /** The end time of the first stream stream (not reruns) */
  endTime: Scalars['DateTime']['output'];
  /** The max of the concerts end time and the end time of all reruns */
  finalEndTime: Scalars['DateTime']['output'];
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>;
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode;
  groups: Array<Group>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isAtmos: Scalars['Boolean']['output'];
  isHdr: Scalars['Boolean']['output'];
  isLossless: Scalars['Boolean']['output'];
  isrc?: Maybe<Scalars['String']['output']>;
  licensor?: Maybe<Scalars['String']['output']>;
  longFormConcertIntroduction?: Maybe<Scalars['String']['output']>;
  maxResolution: MaxResolution;
  partners: Array<Partner>;
  performanceWorks: Array<LivePerformanceWork>;
  photographer?: Maybe<Scalars['String']['output']>;
  pictures: Array<Picture>;
  /** Once all reruns have concluded, this live concert is planned to become a VoD-concert */
  plannedForVod: Scalars['Boolean']['output'];
  production?: Maybe<Scalars['String']['output']>;
  /** The level that is needed for a user to access the stream-content. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel;
  reruns: Array<LiveConcertRerun>;
  /** Maximum length: 25 characters */
  seriesTitle?: Maybe<Scalars['String']['output']>;
  /** Maximum length 750 characters */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  /** The start time of the first stream (not reruns) */
  startTime: Scalars['DateTime']['output'];
  /** Live stream configuration */
  stream: LiveStream;
  /** The start time of the first stream (not reruns), start of pre-rolls, etc. */
  streamStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** Maximum length: 25 characters */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * The max of the concerts end time and the end time of all reruns
   * @deprecated Renamed to avoid confusion with `takedownDate` in `video` and `vodConcert`. Use field `finalEndTime` instead
   */
  takedownDate?: Maybe<Scalars['DateTime']['output']>;
  /** Maximum length: 70 characters */
  title: Scalars['String']['output'];
  /** The estimated total duration of the concert in seconds. */
  totalDuration: Scalars['Int']['output'];
  trailerIsrc?: Maybe<Scalars['String']['output']>;
  trailerStream?: Maybe<Stream>;
  type: LiveConcertType;
  typeDisplayName: Scalars['String']['output'];
  videoDirector?: Maybe<Scalars['String']['output']>;
  vodConcert?: Maybe<VodConcert>;
};

export type LiveConcertArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

export type LiveConcertConnection = {
  __typename: 'LiveConcertConnection';
  edges: Array<LiveConcertEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type LiveConcertEdge = {
  __typename: 'LiveConcertEdge';
  cursor: Scalars['String']['output'];
  node: LiveConcert;
};

export type LiveConcertReminder = {
  __typename: 'LiveConcertReminder';
  liveConcertId: Scalars['String']['output'];
  rerunId?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export type LiveConcertRerun = {
  __typename: 'LiveConcertRerun';
  endTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  /** The delayed rerun stream */
  stream: RerunStream;
  streamStartTime?: Maybe<Scalars['DateTime']['output']>;
};

export const LiveConcertType = {
  Live: 'LIVE',
  Premiere: 'PREMIERE',
} as const;

export type LiveConcertType = (typeof LiveConcertType)[keyof typeof LiveConcertType];
/** Different than `PerformanceWork`, because for live there is no stream and no cue points */
export type LivePerformanceWork = Node & {
  __typename: 'LivePerformanceWork';
  artists?: Maybe<ArtistWithRoleConnection>;
  /** Estimated duration of the performance work in seconds */
  duration: Scalars['Int']['output'];
  groups?: Maybe<GroupConnection>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isrc: Scalars['String']['output'];
  work: Work;
};

/** Different than `PerformanceWork`, because for live there is no stream and no cue points */
export type LivePerformanceWorkArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

/** Different than `PerformanceWork`, because for live there is no stream and no cue points */
export type LivePerformanceWorkGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LiveStream = {
  __typename: 'LiveStream';
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: Scalars['String']['output'];
};

export type LogOutUserInput = {
  /** Optional, provide device push token to disable push notifications after log out */
  pushToken?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type LogOutUserPayload = {
  __typename: 'LogOutUserPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** Log out */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type LoginUserWithPinCodeInput = {
  /**
   * This affiliate group is applied to the user on successful login when the following conditions are met:
   * * User's account is not older than 30m
   * * User's existing affiliate_group is null or "general-web"
   *
   * Affiliates for TV apps are set this way because of the following:
   * * Users cannot create accounts via TV app, so the TV app cannot set the affiliate upon registration
   * * Affiliate cannot be reliably passed to the device that the user will use to register an account
   *
   * This flow allows us to attribute the registration to the TV app manufacturer through the login flow
   *
   * Sample: "store-appletv"
   */
  affiliateGroup?: InputMaybe<Scalars['String']['input']>;
  /** Optional unique and stable identifier for the device */
  clientDeviceId?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the device used e.g. "Firefox 97 (macOS 12.2.1)" or "iPhone 13 (iOS 15.3.1) " */
  deviceInfo: Scalars['String']['input'];
  /** Use either the exact 8-digit PIN code or its 4-digit prefix */
  pinCode: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type LoginUserWithPinCodePayload = {
  __typename: 'LoginUserWithPinCodePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<UserAndToken>;
};

export const MaxResolution = {
  Resolution_4K: 'RESOLUTION_4K',
  ResolutionHd: 'RESOLUTION_HD',
  ResolutionSd: 'RESOLUTION_SD',
} as const;

export type MaxResolution = (typeof MaxResolution)[keyof typeof MaxResolution];
export type Movement = Node & {
  __typename: 'Movement';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type NodeConnectionConnection = {
  __typename: 'NodeConnectionConnection';
  edges: Array<NodeConnectionEdge>;
  pageInfo: PageInfo;
  /** The maximum publication_level of all items in this content container */
  publicationLevel?: Maybe<PublicationLevel>;
  totalResults: Scalars['Int']['output'];
};

export type NodeConnectionEdge = {
  __typename: 'NodeConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Node;
};

export const OsIdType = {
  Ios: 'IOS',
  TvOs: 'TV_OS',
  VisionOs: 'VISION_OS',
} as const;

export type OsIdType = (typeof OsIdType)[keyof typeof OsIdType];
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Partner = Node & {
  __typename: 'Partner';
  albums: Array<Album>;
  curatedContent: Array<CuratedContentContainerV2>;
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  liveConcerts: Array<LiveConcert>;
  name: Scalars['String']['output'];
  pictures: Array<Picture>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  sortLetter?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<PartnerSubtype>;
  subtypeDisplayName?: Maybe<Scalars['String']['output']>;
  type: PartnerType;
  typeDisplayName: Scalars['String']['output'];
  vodConcerts: Array<VodConcert>;
};

export type PartnerConnection = {
  __typename: 'PartnerConnection';
  edges: Array<PartnerEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type PartnerEdge = {
  __typename: 'PartnerEdge';
  cursor: Scalars['String']['output'];
  node: Partner;
};

export const PartnerSubtype = {
  ChamberMusicHall: 'CHAMBER_MUSIC_HALL',
  Club: 'CLUB',
  ConcertHall: 'CONCERT_HALL',
  OpenAir: 'OPEN_AIR',
  OperaHouse: 'OPERA_HOUSE',
  Theatre: 'THEATRE',
} as const;

export type PartnerSubtype = (typeof PartnerSubtype)[keyof typeof PartnerSubtype];
export const PartnerType = {
  Curator: 'CURATOR',
  Festival: 'FESTIVAL',
  ProductionCompany: 'PRODUCTION_COMPANY',
  RecordingCompany: 'RECORDING_COMPANY',
  Series: 'SERIES',
  Sponsor: 'SPONSOR',
  Venue: 'VENUE',
} as const;

export type PartnerType = (typeof PartnerType)[keyof typeof PartnerType];
export type PaymentIntentClientSecret = {
  __typename: 'PaymentIntentClientSecret';
  clientSecret: Scalars['String']['output'];
};

export const PaymentProvider = {
  Amazon: 'AMAZON',
  Apple: 'APPLE',
  None: 'NONE',
  Stripe: 'STRIPE',
} as const;

export type PaymentProvider = (typeof PaymentProvider)[keyof typeof PaymentProvider];
export type PerformanceWork = Node & {
  __typename: 'PerformanceWork';
  artists?: Maybe<ArtistWithRoleConnection>;
  cuePoints: Array<CuePoint>;
  /** Duration of the work in seconds */
  duration: Scalars['Int']['output'];
  groups?: Maybe<GroupConnection>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isrc: Scalars['String']['output'];
  /** The stream for the performance work */
  stream: Stream;
  vodConcert: VodConcert;
  work: Work;
};

export type PerformanceWorkArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

export type PerformanceWorkGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Picture = Node & {
  __typename: 'Picture';
  /** The ID of an object */
  id: Scalars['ID']['output'];
  type: PictureType;
  url: Scalars['String']['output'];
};

export const PictureType = {
  Banner: 'BANNER',
  Cover: 'COVER',
  ImmersiveEnvironmentImage: 'IMMERSIVE_ENVIRONMENT_IMAGE',
  Logo: 'LOGO',
  Teaser: 'TEASER',
  TeaserSquare: 'TEASER_SQUARE',
} as const;

export type PictureType = (typeof PictureType)[keyof typeof PictureType];
export type PinCode = {
  __typename: 'PinCode';
  expiresAt: Scalars['DateTime']['output'];
  /** 8-digit PIN code. Login is also possible with its 4-digit prefix and clients may choose to display that instead */
  pinCode: Scalars['String']['output'];
};

export type PlaybackProgress = Node & {
  __typename: 'PlaybackProgress';
  content: PlaybackProgressContent;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  /** Playback position in seconds */
  mark: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlaybackProgressConnection = {
  __typename: 'PlaybackProgressConnection';
  edges: Array<PlaybackProgressEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type PlaybackProgressContent = PerformanceWork | Track | Video;

export type PlaybackProgressEdge = {
  __typename: 'PlaybackProgressEdge';
  cursor: Scalars['String']['output'];
  node: PlaybackProgress;
};

export type PlaybackProgressOrdering = {
  direction?: InputMaybe<SortDirection>;
  sort?: InputMaybe<FavoriteSort>;
};

export const PlaybackProgressType = {
  /** Return all types of content */
  All: 'ALL',
  /** Return only performance works */
  PerformanceWork: 'PERFORMANCE_WORK',
  /** Return videos and performance works */
  PerformanceWorkAndVideo: 'PERFORMANCE_WORK_AND_VIDEO',
  /** Return only tracks */
  Track: 'TRACK',
  /** Return only videos */
  Video: 'VIDEO',
} as const;

export type PlaybackProgressType = (typeof PlaybackProgressType)[keyof typeof PlaybackProgressType];
export type PostalAddress = {
  __typename: 'PostalAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  firstLine?: Maybe<Scalars['String']['output']>;
  insertedAt: Scalars['DateTime']['output'];
  province?: Maybe<Scalars['String']['output']>;
  secondLine?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use province instead */
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Price = {
  __typename: 'Price';
  /**
   * ATTENTION: The amount is currency dependent wether it supports
   *   fractions are not e.g. EUR/USD in cents, JPY full Yen only. This is relevant for displaying formatted prices.
   *
   *   Maybe lower compared to amountUndiscounted, if a coupon is applied.
   */
  amount: Scalars['Int']['output'];
  /** The amount without any discount applied */
  amountUndiscounted: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Province = {
  __typename: 'Province';
  code: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
};

export const PublicationLevel = {
  AccountRequired: 'ACCOUNT_REQUIRED',
  NoAuthentication: 'NO_AUTHENTICATION',
  TicketRequired: 'TICKET_REQUIRED',
} as const;

export type PublicationLevel = (typeof PublicationLevel)[keyof typeof PublicationLevel];
/** Payload of this mutation - contains either a result on success or a list of errors */
export type ReactivateSubscriptionPayload = {
  __typename: 'ReactivateSubscriptionPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Ticket>;
};

export type RecordPlaybackProgressInput = {
  /** Client identifier */
  client: Client;
  /** Information on the device the client is running on */
  clientDevice: Scalars['String']['input'];
  /** Client Unix timestamp in seconds */
  clientTimestamp: Scalars['Int']['input'];
  /** Client version */
  clientVersion: Scalars['String']['input'];
  /** id of a Track, Video, PerformanceWork or LiveConcert */
  contentId: Scalars['ID']['input'];
  /** The heartbeat rate in seconds */
  heartbeatRate: Scalars['Int']['input'];
  /** Playback position in seconds */
  mark: Scalars['Int']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RecordPlaybackProgressPayload = {
  __typename: 'RecordPlaybackProgressPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<PlaybackProgress>;
};

export const RecordingSetting = {
  Live: 'LIVE',
  Studio: 'STUDIO',
  Unknown: 'UNKNOWN',
} as const;

export type RecordingSetting = (typeof RecordingSetting)[keyof typeof RecordingSetting];
/** Payload of this mutation - contains either a result on success or a list of errors */
export type RedeemETicketPayload = {
  __typename: 'RedeemETicketPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<RedeemETicketResult>;
};

export type RedeemETicketResult = {
  __typename: 'RedeemETicketResult';
  /** Ticket generated from the code */
  ticket?: Maybe<Ticket>;
  /** Voucher used to redeem the ticket */
  voucher?: Maybe<Voucher>;
};

export type RegisterUserInput = {
  academicTitle?: InputMaybe<Scalars['String']['input']>;
  affiliateGroup?: InputMaybe<Scalars['String']['input']>;
  agreeToTos: Scalars['Boolean']['input'];
  /** Optional unique and stable identifier for the device */
  clientDeviceId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for the device used e.g. "Firefox 97 (macOS 12.2.1)" or "iPhone 13 (iOS 15.3.1) " */
  deviceInfo: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  /** Meta param that identifies a click conversion event https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc */
  metaFbc?: InputMaybe<Scalars['String']['input']>;
  /** Meta param that identifies the client that generated the conversion event https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc */
  metaFbp?: InputMaybe<Scalars['String']['input']>;
  /** User consent for meta tracking */
  metaTrackingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** User consent for mixpanel tracking */
  mixpanelTrackingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  sendNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RegisterUserPayload = {
  __typename: 'RegisterUserPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<UserAndToken>;
};

export type RegistrationDataCheckResult = {
  __typename: 'RegistrationDataCheckResult';
  emailLikelyValid?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveDeviceInput = {
  /** UUID of the device from the users device list */
  id: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RemoveDeviceInputPayload = {
  __typename: 'RemoveDeviceInputPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** UUID of the removed device */
  result?: Maybe<Scalars['String']['output']>;
};

export type RemoveFavoriteByContentIdInput = {
  /** id of favorited content e.g. an id of an album */
  contentId: Scalars['ID']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RemoveFavoriteByContentIdPayload = {
  __typename: 'RemoveFavoriteByContentIdPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveFavoriteByContentTypeInput = {
  contentType: StreamType;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RemoveFavoriteByContentTypePayload = {
  __typename: 'RemoveFavoriteByContentTypePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveFavoriteByIdInput = {
  /** id of a favorite */
  id: Scalars['ID']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type RemoveFavoriteByIdPayload = {
  __typename: 'RemoveFavoriteByIdPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type RerunStream = {
  __typename: 'RerunStream';
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: Scalars['String']['output'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ResendEmailConfirmationPayload = {
  __typename: 'ResendEmailConfirmationPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type ResetAllPlaybackProgressInput = {
  contentType?: InputMaybe<StreamType>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ResetAllPlaybackProgressPayload = {
  __typename: 'ResetAllPlaybackProgressPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type ResetPasswordPayload = {
  __typename: 'ResetPasswordPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type Role = Node & {
  __typename: 'Role';
  displayName: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  type: RoleType;
};

export type RoleConnection = {
  __typename: 'RoleConnection';
  edges: Array<RoleEdge>;
  pageInfo: PageInfo;
};

export type RoleEdge = {
  __typename: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node: Role;
};

export const RoleType = {
  Arrangement: 'ARRANGEMENT',
  Author: 'AUTHOR',
  ChorusMaster: 'CHORUS_MASTER',
  Composer: 'COMPOSER',
  Conductor: 'CONDUCTOR',
  Director: 'DIRECTOR',
  Engineer: 'ENGINEER',
  InstrumentalSoloist: 'INSTRUMENTAL_SOLOIST',
  Mixing: 'MIXING',
  Orchestrator: 'ORCHESTRATOR',
  Other: 'OTHER',
  Production: 'PRODUCTION',
  VocalSoloist: 'VOCAL_SOLOIST',
} as const;

export type RoleType = (typeof RoleType)[keyof typeof RoleType];
export type RootMutationType = {
  __typename: 'RootMutationType';
  addFavorite?: Maybe<AddFavoritePayload>;
  /** Authorize a device for the current user - the device is added to the users list of devices */
  authorizeDeviceForUser?: Maybe<AuthorizeDeviceForUserInputPayload>;
  cancelStripeSubscription?: Maybe<CancelSubscriptionPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  changeStripePaymentMethod?: Maybe<ChangeStripePaymentMethodPayload>;
  changeStripeSubscriptionPlan?: Maybe<ChangeStripeSubscriptionPlanPayload>;
  confirmPurchaseAmazon?: Maybe<ConfirmPurchaseAmazonPayload>;
  confirmPurchaseApple?: Maybe<ConfirmPurchaseApplePayload>;
  consentTracking?: Maybe<ConsentTrackingPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deny an authorization request for the given device of the current user */
  denyDevice?: Maybe<DenyDeviceInputPayload>;
  generatePinCode?: Maybe<GeneratePinCodePayload>;
  /** Notify backend that user log out from app */
  logOutUser?: Maybe<LogOutUserPayload>;
  loginUserWithPinCode?: Maybe<LoginUserWithPinCodePayload>;
  reactivateStripeSubscription?: Maybe<ReactivateSubscriptionPayload>;
  recordPlaybackProgress?: Maybe<RecordPlaybackProgressPayload>;
  redeemETicketCode?: Maybe<RedeemETicketPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  /** Remove a previously authorized device of the current user */
  removeDevice?: Maybe<RemoveDeviceInputPayload>;
  /** Remove a favorite by the id of the favorited content, eg. `album_xxx` */
  removeFavoriteByContentId?: Maybe<RemoveFavoriteByContentIdPayload>;
  /** Removes ALL favorites of a content type at once */
  removeFavoriteByContentType?: Maybe<RemoveFavoriteByContentTypePayload>;
  /** Remove the favorite by its own id, eg. `favorite_xxx` */
  removeFavoriteById?: Maybe<RemoveFavoriteByIdPayload>;
  resendEmailConfirmation?: Maybe<ResendEmailConfirmationPayload>;
  resetAllPlaybackProgress?: Maybe<ResetAllPlaybackProgressPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  sendChangeEmailAddressEmail?: Maybe<SendChangeEmailAddressEmailPayload>;
  sendResetPasswordEmail?: Maybe<SendResetPasswordEmailPayload>;
  setCancelationReason?: Maybe<SetCancelationReasonPayload>;
  subscribeDgNewsletter?: Maybe<SubscribeDgNewsletterPayload>;
  subscribeStagePlusEmailNotification?: Maybe<SubscribeStagePlusEmailNotificationPayload>;
  subscribeViaStripe?: Maybe<SubscribeViaStripePayload>;
  subscribeViaStripeV2?: Maybe<SubscribeViaStripeV2Payload>;
  switchLiveConcertReminder?: Maybe<SwitchLiveConcertReminderPayload>;
  /** Register device for push notification and update device info */
  updateDevice?: Maybe<UpdateDeviceInputPayload>;
  updateNewsletterInfo?: Maybe<UpdateNewsletterInfoPayload>;
  /** @deprecated Use updateShippingAddress instead */
  updatePostalAddress?: Maybe<UpdatePostalAddressPayload>;
  updateShippingAddress?: Maybe<UpdateShippingAddressPayload>;
  updateTrackingInformation?: Maybe<UpdateTrackingInformationPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
};

export type RootMutationTypeAddFavoriteArgs = {
  input?: InputMaybe<AddFavoriteInput>;
};

export type RootMutationTypeAuthorizeDeviceForUserArgs = {
  input?: InputMaybe<AuthorizeDeviceForUserInput>;
};

export type RootMutationTypeChangePasswordArgs = {
  input?: InputMaybe<ChangePasswordInput>;
};

export type RootMutationTypeChangeStripeSubscriptionPlanArgs = {
  input?: InputMaybe<ChangeStripeSubscriptionPlanInput>;
};

export type RootMutationTypeConfirmPurchaseAmazonArgs = {
  input?: InputMaybe<ConfirmPurchaseAmazonInput>;
};

export type RootMutationTypeConfirmPurchaseAppleArgs = {
  input?: InputMaybe<ConfirmPurchaseAppleInput>;
};

export type RootMutationTypeConsentTrackingArgs = {
  input?: InputMaybe<ConsentTrackingInput>;
};

export type RootMutationTypeDenyDeviceArgs = {
  input?: InputMaybe<DenyDeviceInput>;
};

export type RootMutationTypeLogOutUserArgs = {
  input?: InputMaybe<LogOutUserInput>;
};

export type RootMutationTypeLoginUserWithPinCodeArgs = {
  input?: InputMaybe<LoginUserWithPinCodeInput>;
};

export type RootMutationTypeRecordPlaybackProgressArgs = {
  input?: InputMaybe<RecordPlaybackProgressInput>;
};

export type RootMutationTypeRedeemETicketCodeArgs = {
  code: Scalars['String']['input'];
};

export type RootMutationTypeRegisterUserArgs = {
  input?: InputMaybe<RegisterUserInput>;
};

export type RootMutationTypeRemoveDeviceArgs = {
  input?: InputMaybe<RemoveDeviceInput>;
};

export type RootMutationTypeRemoveFavoriteByContentIdArgs = {
  input?: InputMaybe<RemoveFavoriteByContentIdInput>;
};

export type RootMutationTypeRemoveFavoriteByContentTypeArgs = {
  input?: InputMaybe<RemoveFavoriteByContentTypeInput>;
};

export type RootMutationTypeRemoveFavoriteByIdArgs = {
  input?: InputMaybe<RemoveFavoriteByIdInput>;
};

export type RootMutationTypeResetAllPlaybackProgressArgs = {
  input?: InputMaybe<ResetAllPlaybackProgressInput>;
};

export type RootMutationTypeResetPasswordArgs = {
  input?: InputMaybe<ResetPasswordInput>;
};

export type RootMutationTypeSendChangeEmailAddressEmailArgs = {
  input?: InputMaybe<SendChangeEmailAddressEmailInput>;
};

export type RootMutationTypeSendResetPasswordEmailArgs = {
  input?: InputMaybe<SendResetPasswordEmailInput>;
};

export type RootMutationTypeSetCancelationReasonArgs = {
  input?: InputMaybe<SetCancelationReasonInput>;
};

export type RootMutationTypeSubscribeDgNewsletterArgs = {
  input?: InputMaybe<SubscribeDgNewsletterInput>;
};

export type RootMutationTypeSubscribeViaStripeArgs = {
  input?: InputMaybe<SubscribeViaStripeInput>;
};

export type RootMutationTypeSubscribeViaStripeV2Args = {
  input?: InputMaybe<SubscribeViaStripeInput>;
};

export type RootMutationTypeSwitchLiveConcertReminderArgs = {
  input?: InputMaybe<SwitchLiveConcertReminderInput>;
};

export type RootMutationTypeUpdateDeviceArgs = {
  input?: InputMaybe<UpdateDeviceInput>;
};

export type RootMutationTypeUpdateNewsletterInfoArgs = {
  input?: InputMaybe<UpdateNewsletterInfoInput>;
};

export type RootMutationTypeUpdatePostalAddressArgs = {
  input?: InputMaybe<UpdatePostalAddressInput>;
};

export type RootMutationTypeUpdateShippingAddressArgs = {
  input?: InputMaybe<UpdateShippingAddressInput>;
};

export type RootMutationTypeUpdateTrackingInformationArgs = {
  input?: InputMaybe<UpdateTrackingInformationInput>;
};

export type RootMutationTypeUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type RootQueryType = {
  __typename: 'RootQueryType';
  album?: Maybe<Album>;
  albums?: Maybe<AlbumConnection>;
  artist?: Maybe<Artist>;
  artists?: Maybe<ArtistConnection>;
  cancelationReasons: Array<Scalars['String']['output']>;
  checkETicketCode?: Maybe<CheckETicketPayload>;
  checkRegistrationData?: Maybe<RegistrationDataCheckResult>;
  countries: Array<Country>;
  curatedContent: CuratedContent;
  currentUser?: Maybe<User>;
  epoch?: Maybe<Epoch>;
  epochs?: Maybe<EpochConnection>;
  favoriteByContentId?: Maybe<Favorite>;
  favoritesByVodConcertId?: Maybe<Array<Maybe<Favorite>>>;
  genre?: Maybe<Genre>;
  genres?: Maybe<GenreConnection>;
  geoinfo?: Maybe<GeoinfoPayload>;
  group?: Maybe<Group>;
  groups?: Maybe<GroupConnection>;
  liveConcert?: Maybe<LiveConcert>;
  liveConcertReminder?: Maybe<Array<Maybe<LiveConcertReminder>>>;
  liveConcerts?: Maybe<LiveConcertConnection>;
  node?: Maybe<Node>;
  partner?: Maybe<Partner>;
  partners?: Maybe<PartnerConnection>;
  playbackProgressByContentId?: Maybe<PlaybackProgress>;
  previewSubscriptionPlanStripe: SubscriptionPlanPreviewStripe;
  /** Returns a list of provinces of the specified country */
  provinces: Array<Province>;
  role?: Maybe<Role>;
  roles?: Maybe<RoleConnection>;
  schemaVersion: Scalars['Int']['output'];
  searchByFacetType?: Maybe<SearchByFacetTypeResult>;
  searchByFacetValue?: Maybe<SearchByFacetValueResult>;
  /** Only used by SmartTV-devices as they have too little resources for on device-processing. Do not use with with search-as-you-type-searches as the performance would not be good enough. */
  searchByText?: Maybe<SearchByTextResult>;
  slider?: Maybe<Slider>;
  subscriptionPlansAmazon: Array<SubscriptionPlanAmazon>;
  subscriptionPlansApple: Array<SubscriptionPlanApple>;
  subscriptionPlansStripe: SubscriptionPlansStripe;
  video?: Maybe<Video>;
  videos: VideoConnection;
  vodConcert?: Maybe<VodConcert>;
  vodConcerts?: Maybe<VodConcertConnection>;
  work?: Maybe<Work>;
};

export type RootQueryTypeAlbumArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeAlbumsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeArtistArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<RoleType>;
};

export type RootQueryTypeCheckETicketCodeArgs = {
  code: Scalars['String']['input'];
};

export type RootQueryTypeCheckRegistrationDataArgs = {
  input: CheckRegistrationDataInput;
};

export type RootQueryTypeCuratedContentArgs = {
  slug: CuratedContentSlug;
};

export type RootQueryTypeEpochArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeEpochsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeFavoriteByContentIdArgs = {
  contentId: Scalars['ID']['input'];
};

export type RootQueryTypeFavoritesByVodConcertIdArgs = {
  contentId: Scalars['ID']['input'];
};

export type RootQueryTypeGenreArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeGenresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeGeoinfoArgs = {
  ip?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGroupArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeLiveConcertArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeLiveConcertReminderArgs = {
  liveConcertId: Scalars['ID']['input'];
};

export type RootQueryTypeLiveConcertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeNodeArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePartnerArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<PartnerType>;
};

export type RootQueryTypePlaybackProgressByContentIdArgs = {
  contentId: Scalars['ID']['input'];
};

export type RootQueryTypePreviewSubscriptionPlanStripeArgs = {
  productId: Scalars['String']['input'];
};

export type RootQueryTypeProvincesArgs = {
  countryCode: Scalars['String']['input'];
};

export type RootQueryTypeRoleArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeSearchByFacetTypeArgs = {
  input?: InputMaybe<SearchByFacetTypeInput>;
};

export type RootQueryTypeSearchByFacetValueArgs = {
  input?: InputMaybe<SearchByFacetValueInput>;
};

export type RootQueryTypeSearchByTextArgs = {
  input?: InputMaybe<SearchByTextInput>;
};

export type RootQueryTypeSliderArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeSubscriptionPlansStripeArgs = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeVideoArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeVodConcertArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypeVodConcertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RootQueryTypeWorkArgs = {
  id: Scalars['ID']['input'];
};

export type SearchAlbum = {
  __typename: 'SearchAlbum';
  albumTracks: Array<Maybe<Scalars['String']['output']>>;
  albumWorks: Array<Maybe<Scalars['String']['output']>>;
  artists: Array<Maybe<Scalars['String']['output']>>;
  artistsAndGroups: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<Maybe<SearchImage>>;
  title: Scalars['String']['output'];
};

export type SearchAlbumsResult = {
  __typename: 'SearchAlbumsResult';
  items: Array<SearchAlbum>;
  totalResults: Scalars['Int']['output'];
};

export type SearchArtist = {
  __typename: 'SearchArtist';
  id: Scalars['String']['output'];
  images: Array<SearchImage>;
  name: Scalars['String']['output'];
  roles: Array<Maybe<Scalars['String']['output']>>;
  sortName: Scalars['String']['output'];
};

export type SearchArtistsResult = {
  __typename: 'SearchArtistsResult';
  items: Array<SearchArtist>;
  totalResults: Scalars['Int']['output'];
};

export type SearchByFacetTypeInput = {
  facetType: FacetType;
};

export type SearchByFacetTypeResult = {
  __typename: 'SearchByFacetTypeResult';
  /** The sections for this facet, sorted by glyphs. */
  sections: Array<SearchByFacetTypeSection>;
};

export type SearchByFacetTypeSection = {
  __typename: 'SearchByFacetTypeSection';
  glyph: Scalars['String']['output'];
  /** The items in this section, sorted by their normalized sort name (Unicode Normalization Form D, stripped of non-whitelisted characters). Should be displayed in exactly this order in the client. */
  items: Array<SearchGlyphItem>;
};

export type SearchByFacetValueInput = {
  facetType: FacetType;
  facetValue: Scalars['String']['input'];
};

export type SearchByFacetValueResult = {
  __typename: 'SearchByFacetValueResult';
  albums: SearchAlbumsResult;
  artists: SearchArtistsResult;
  documentaries: SearchVideosResult;
  groups: SearchGroupsResult;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  interviews: SearchVideosResult;
  liveConcerts: SearchLiveConcertsResult;
  partners: SearchPartnersResult;
  shorts: SearchVideosResult;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  vodConcerts: SearchVodConcertsResult;
};

export type SearchByFacetValueResultAlbumsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultArtistsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultDocumentariesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultGroupsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultInterviewsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultLiveConcertsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultPartnersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultShortsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByFacetValueResultVodConcertsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextInput = {
  text: Scalars['String']['input'];
};

export type SearchByTextResult = {
  __typename: 'SearchByTextResult';
  albums: SearchAlbumsResult;
  artists: SearchArtistsResult;
  documentaries: SearchVideosResult;
  groups: SearchGroupsResult;
  interviews: SearchVideosResult;
  liveConcerts: SearchLiveConcertsResult;
  partners: SearchPartnersResult;
  shorts: SearchVideosResult;
  vodConcerts: SearchVodConcertsResult;
};

export type SearchByTextResultAlbumsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultArtistsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultDocumentariesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultGroupsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultInterviewsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultLiveConcertsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultPartnersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultShortsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchByTextResultVodConcertsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchGlyphItem = {
  __typename: 'SearchGlyphItem';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type SearchGroup = {
  __typename: 'SearchGroup';
  id: Scalars['String']['output'];
  images: Array<SearchImage>;
  name: Scalars['String']['output'];
  subtype: Scalars['String']['output'];
};

export type SearchGroupsResult = {
  __typename: 'SearchGroupsResult';
  items: Array<SearchGroup>;
  totalResults: Scalars['Int']['output'];
};

export type SearchImage = {
  __typename: 'SearchImage';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SearchLiveConcert = {
  __typename: 'SearchLiveConcert';
  artists: Array<Maybe<Scalars['String']['output']>>;
  endDate: Scalars['String']['output'];
  finalEndTime: Scalars['String']['output'];
  geoAccessCountries: Array<Maybe<Scalars['String']['output']>>;
  geoAccessMode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<Maybe<SearchImage>>;
  liveConcertType: Scalars['String']['output'];
  partners: Array<Maybe<Scalars['String']['output']>>;
  plannedForVod: Scalars['Boolean']['output'];
  publicationLevel: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  typeDisplayName: Scalars['String']['output'];
};

export type SearchLiveConcertsResult = {
  __typename: 'SearchLiveConcertsResult';
  items: Array<SearchLiveConcert>;
  totalResults: Scalars['Int']['output'];
};

export type SearchPartner = {
  __typename: 'SearchPartner';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<SearchImage>;
  name: Scalars['String']['output'];
  partnerSubtype: Scalars['String']['output'];
  partnerType: Scalars['String']['output'];
};

export type SearchPartnersResult = {
  __typename: 'SearchPartnersResult';
  items: Array<SearchPartner>;
  totalResults: Scalars['Int']['output'];
};

export type SearchVideo = {
  __typename: 'SearchVideo';
  artists: Array<Maybe<Scalars['String']['output']>>;
  duration: Scalars['Int']['output'];
  geoAccessCountries: Array<Maybe<Scalars['String']['output']>>;
  geoAccessMode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<Maybe<SearchImage>>;
  publicationLevel: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  typeDisplayName: Scalars['String']['output'];
  videoType: Scalars['String']['output'];
};

export type SearchVideosResult = {
  __typename: 'SearchVideosResult';
  items: Array<SearchVideo>;
  totalResults: Scalars['Int']['output'];
};

export type SearchVodConcert = {
  __typename: 'SearchVodConcert';
  artists: Array<Maybe<Scalars['String']['output']>>;
  duration: Scalars['Int']['output'];
  geoAccessCountries: Array<Maybe<Scalars['String']['output']>>;
  geoAccessMode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images: Array<Maybe<SearchImage>>;
  partners: Array<Maybe<Scalars['String']['output']>>;
  publicationLevel: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  typeDisplayName: Scalars['String']['output'];
  vodConcertType: Scalars['String']['output'];
  works: Array<Maybe<Scalars['String']['output']>>;
};

export type SearchVodConcertsResult = {
  __typename: 'SearchVodConcertsResult';
  items: Array<SearchVodConcert>;
  totalResults: Scalars['Int']['output'];
};

export type SendChangeEmailAddressEmailInput = {
  newEmail: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SendChangeEmailAddressEmailPayload = {
  __typename: 'SendChangeEmailAddressEmailPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type SendResetPasswordEmailInput = {
  email: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SendResetPasswordEmailPayload = {
  __typename: 'SendResetPasswordEmailPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type SetCancelationReasonInput = {
  other?: InputMaybe<Scalars['String']['input']>;
  reasons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SetCancelationReasonPayload = {
  __typename: 'SetCancelationReasonPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type SetupIntentClientSecret = {
  __typename: 'SetupIntentClientSecret';
  clientSecret: Scalars['String']['output'];
};

export type Slider = Node & {
  __typename: 'Slider';
  headline: Scalars['String']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  /** @deprecated use sliderItems instead */
  items?: Maybe<NodeConnectionConnection>;
  sliderItems?: Maybe<SliderItemConnection>;
};

export type SliderItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ItemSortOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SliderSliderItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ItemSortOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SliderItem =
  | Album
  | Artist
  | Epoch
  | Favorite
  | Genre
  | Group
  | LiveConcert
  | Partner
  | PlaybackProgress
  | Video
  | VodConcert;

export type SliderItemConnection = {
  __typename: 'SliderItemConnection';
  edges: Array<SliderItemEdge>;
  pageInfo: PageInfo;
  /** The maximum publication_level of all items in this content container */
  publicationLevel?: Maybe<PublicationLevel>;
  totalResults: Scalars['Int']['output'];
};

export type SliderItemEdge = {
  __typename: 'SliderItemEdge';
  cursor: Scalars['String']['output'];
  node: SliderItem;
};

export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];
export type StageItem = {
  __typename: 'StageItem';
  /** The referenced item. Possible Types: `VodConcert`, `LiveConcert`, `Artist`, `Album`, `Video` */
  item: StageItemContent;
  /**
   * An *optional* `label` override, like 'New in Archive'. Clients can decide to display this based on context
   * instead of some information from the referenced content. The label will always be returned in caps.
   */
  label?: Maybe<Scalars['String']['output']>;
  /**
   * An *optional* `subtitle` override. Clients should display this instead of the title from
   * the referenced content. Note: It is not guaranteed that all referenced content has subtitles.
   */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * An *optional* `title` override. Clients should display this instead of the title from
   * the referenced content.
   */
  title?: Maybe<Scalars['String']['output']>;
};

export type StageItemContent = Album | Artist | LiveConcert | Video | VodConcert;

export type StageItemContentV2 = Album | Artist | Group | LiveConcert | Partner | Video | VodConcert;

export type StageItemV2 = {
  __typename: 'StageItemV2';
  /** The referenced item. Possible Types: `VodConcert`, `LiveConcert`, `Artist`, `Album`, `Video`, `Group`, `Partner` */
  item: StageItemContentV2;
  /**
   * An *optional* `label` override, like 'New in Archive'. Clients can decide to display this based on context
   * instead of some information from the referenced content. The label will always be returned in caps.
   */
  label?: Maybe<Scalars['String']['output']>;
  /**
   * An *optional* `subtitle` override. Clients should display this instead of the title from
   * the referenced content. Note: It is not guaranteed that all referenced content has subtitles.
   */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * An *optional* `title` override. Clients should display this instead of the title from
   * the referenced content.
   */
  title?: Maybe<Scalars['String']['output']>;
};

export type Stream = {
  __typename: 'Stream';
  /** Url for potential 3D-Stream of this video. Will always be `null` for audio. */
  mvVideoUrl?: Maybe<Scalars['String']['output']>;
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: Scalars['String']['output'];
};

export const StreamType = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type StreamType = (typeof StreamType)[keyof typeof StreamType];
export type StripeBillingInfo = {
  __typename: 'StripeBillingInfo';
  /** May be 0 when still in trial */
  amount: Scalars['String']['output'];
  /** May be null when out of the trial phase */
  billedFirstAt?: Maybe<Scalars['DateTime']['output']>;
  /** May be null when a ticket is canceled */
  billedNextAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * May be null when card info is not available (different form of payment)
   * @deprecated replaced by field paymentMethod with union type
   */
  card?: Maybe<StripeCard>;
  currency: Scalars['String']['output'];
  /** Payment method used - may be null when no payment method attached yet */
  paymentMethod?: Maybe<StripePaymentMethod>;
};

export type StripeCard = {
  __typename: 'StripeCard';
  /** Card brand. Can be amex, diners, discover, jcb, mastercard, unionpay, visa, or unknown. */
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  /** Two-digit number representing the card’s expiration month. */
  expMonth: Scalars['Int']['output'];
  /** Four-digit number representing the card’s expiration year. */
  expYear: Scalars['Int']['output'];
  /** Card funding type. Can be credit, debit, prepaid, or unknown. */
  funding: Scalars['String']['output'];
  /** The last four digits of the card. */
  last4: Scalars['String']['output'];
};

/** The Stripe client secret to pass into the Stripe JS form */
export type StripeClientSecret = PaymentIntentClientSecret | SetupIntentClientSecret;

export type StripeInvoice = {
  __typename: 'StripeInvoice';
  billedAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** Discount as absolute number */
  discount?: Maybe<Scalars['Int']['output']>;
  number: Scalars['String']['output'];
  pdfUrl: Scalars['String']['output'];
  /** Total undiscounted */
  subtotal: Scalars['Int']['output'];
  /** Actual total billed */
  total: Scalars['Int']['output'];
};

export type StripeInvoiceCompact = {
  __typename: 'StripeInvoiceCompact';
  billedAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  /** Discount as absolute number */
  discount?: Maybe<Scalars['Int']['output']>;
  /** Total undiscounted */
  subtotal: Scalars['Int']['output'];
  /** Actual total billed */
  total: Scalars['Int']['output'];
};

/** Payment method used - may be null when no payment method attached yet */
export type StripePaymentMethod = StripePaymentMethodCard | StripePaymentMethodPaypal;

export type StripePaymentMethodCard = {
  __typename: 'StripePaymentMethodCard';
  /** Card brand. Can be amex, diners, discover, jcb, mastercard, unionpay, visa, or unknown. */
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  /** Two-digit number representing the card’s expiration month. */
  expMonth: Scalars['Int']['output'];
  /** Four-digit number representing the card’s expiration year. */
  expYear: Scalars['Int']['output'];
  /** Card funding type. Can be credit, debit, prepaid, or unknown. */
  funding: Scalars['String']['output'];
  /** The last four digits of the card. */
  last4: Scalars['String']['output'];
};

export type StripePaymentMethodPaypal = {
  __typename: 'StripePaymentMethodPaypal';
  country: Scalars['String']['output'];
  payerEmail: Scalars['String']['output'];
  payerId: Scalars['String']['output'];
};

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  /** Coupon metadata when a valid code was given and it was applied */
  coupon?: Maybe<Coupon>;
  /** Invoiced amount for the subscription */
  invoice: StripeInvoiceCompact;
  stripeClientSecret: StripeClientSecret;
  timeLimitedDiscountInfo?: Maybe<StripeTimeLimitedDiscountInfo>;
};

export type StripeTimeLimitedDiscountInfo = {
  __typename: 'StripeTimeLimitedDiscountInfo';
  amount: Scalars['Int']['output'];
  couponEndsAt: Scalars['DateTime']['output'];
  durationInMonths: Scalars['Int']['output'];
};

export type SubscribeDgNewsletterInput = {
  email: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SubscribeDgNewsletterPayload = {
  __typename: 'SubscribeDgNewsletterPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SubscribeStagePlusEmailNotificationPayload = {
  __typename: 'SubscribeStagePlusEmailNotificationPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type SubscribeViaStripeInput = {
  couponCode?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SubscribeViaStripePayload = {
  __typename: 'SubscribeViaStripePayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<StripeClientSecret>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SubscribeViaStripeV2Payload = {
  __typename: 'SubscribeViaStripeV2Payload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<StripeSubscription>;
};

export type SubscriptionCancelation = {
  __typename: 'SubscriptionCancelation';
  ticketTerminatesAt: Scalars['DateTime']['output'];
};

export type SubscriptionPlanAmazon = {
  __typename: 'SubscriptionPlanAmazon';
  interval: SubscriptionPlanInterval;
  /** Indicates if the current user can purchase this plan */
  isPurchasePossible: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
  termSku: Scalars['String']['output'];
};

export type SubscriptionPlanApple = {
  __typename: 'SubscriptionPlanApple';
  interval: SubscriptionPlanInterval;
  /** Indicates if the current user can purchase this plan */
  isPurchasePossible: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
};

export const SubscriptionPlanInterval = {
  Days_7: 'DAYS_7',
  Monthly: 'MONTHLY',
  Yearly: 'YEARLY',
} as const;

export type SubscriptionPlanInterval = (typeof SubscriptionPlanInterval)[keyof typeof SubscriptionPlanInterval];
export type SubscriptionPlanPreviewStripe = {
  __typename: 'SubscriptionPlanPreviewStripe';
  /** The date when the user is billed */
  billedNextAt: Scalars['DateTime']['output'];
  /**
   * May be null when card info is not available (different form of payment)
   * @deprecated replaced by field paymentMethod with union type
   */
  card?: Maybe<StripeCard>;
  /** The billing currency */
  currency: Scalars['String']['output'];
  /** The users current subscription plan price */
  currentPlan: SubscriptionPlanPriceStripe;
  /** Payment method used - may be null when no payment method attached yet */
  paymentMethod?: Maybe<StripePaymentMethod>;
  /** Preview of the users subscription plan price */
  previewPlan: SubscriptionPlanPriceStripe;
  /** The unix timestamp of the preview proration needed for plan change */
  prorationDate: Scalars['Int']['output'];
  /** The start date of the new subscription */
  subscriptionStartsAt: Scalars['String']['output'];
  /** The tax amount of the total */
  tax?: Maybe<Scalars['Int']['output']>;
  /** The tax percentage of the total */
  taxPercentage?: Maybe<Scalars['Float']['output']>;
  /** The total charged on the `billed_next_at` date */
  total: Scalars['Int']['output'];
  /** The total excluding tax */
  totalExcludingTax: Scalars['Int']['output'];
};

export type SubscriptionPlanPriceStripe = {
  __typename: 'SubscriptionPlanPriceStripe';
  interval: SubscriptionPlanInterval;
  price: Price;
  productId: Scalars['String']['output'];
};

export type SubscriptionPlanStripe = {
  __typename: 'SubscriptionPlanStripe';
  interval: SubscriptionPlanInterval;
  /** Indicates if the current user can change into this plan */
  isPlanChangeSupported: Scalars['Boolean']['output'];
  /** Indicates if the current user can purchase this plan */
  isPurchasePossible: Scalars['Boolean']['output'];
  prices: Array<Price>;
  productId: Scalars['String']['output'];
  /** Indicates if a credit card or some other form of payment is required to subscribe */
  requiresPaymentMethod: Scalars['Boolean']['output'];
  ticketType: TicketType;
  trialPeriodDays: Scalars['Int']['output'];
};

export type SubscriptionPlansStripe = {
  __typename: 'SubscriptionPlansStripe';
  /** Coupon metadata when a valid code was given */
  coupon?: Maybe<Coupon>;
  plans: Array<SubscriptionPlanStripe>;
};

export type SwitchLiveConcertReminderInput = {
  liveConcertId: Scalars['String']['input'];
  remind: Scalars['Boolean']['input'];
  rerunId?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type SwitchLiveConcertReminderPayload = {
  __typename: 'SwitchLiveConcertReminderPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type Ticket = {
  __typename: 'Ticket';
  /** UUID that is also used to identify the order_id */
  id: Scalars['String']['output'];
  interval: SubscriptionPlanInterval;
  /** Wether the current ticket is still in its trial period, will become false when the trial ends. */
  isTrial: Scalars['Boolean']['output'];
  /** Payment method type e.g. card/paypal - currently Stripe only when a payment method has been attached to the subscription */
  paymentMethodType?: Maybe<Scalars['String']['output']>;
  paymentProvider: PaymentProvider;
  status: TicketStatus;
  /** NOTE: Please only query those fields when actually required, since we call the Stripe API for the data. */
  stripeBillingInfo?: Maybe<StripeBillingInfo>;
  ticketType: TicketType;
  /** marks the date until when stream access will end */
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export const TicketStatus = {
  /** access to content is granted till validUntil, renews automatically. */
  Active: 'ACTIVE',
  AwaitingPayment: 'AWAITING_PAYMENT',
  /** either user canceled the subscription, access to content is granted till validUntil OR payments didn't recover in time / grace period expired, no access to content. */
  Canceled: 'CANCELED',
  /** the last payment failed, access to content is granted till validUntil. */
  PaymentFailed: 'PAYMENT_FAILED',
} as const;

export type TicketStatus = (typeof TicketStatus)[keyof typeof TicketStatus];
export const TicketType = {
  ETicket: 'E_TICKET',
  OneTimePurchase: 'ONE_TIME_PURCHASE',
  Subscription: 'SUBSCRIPTION',
} as const;

export type TicketType = (typeof TicketType)[keyof typeof TicketType];
export type Track = Node & {
  __typename: 'Track';
  album: Album;
  /** The artists and groups on this track as displayed on the DG website, comma separated. */
  artistAndGroupDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** The soloists on this track, comma separated. */
  artistDisplayInfo?: Maybe<Scalars['String']['output']>;
  bitDepth?: Maybe<Scalars['Int']['output']>;
  /** The composers on this track, comma separated. */
  composerDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** The conductors on this track, comma separated. */
  conductorDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** Duration of the track in seconds */
  duration: Scalars['Int']['output'];
  /** The engineers on this track, comma separated. */
  engineerDisplayInfo?: Maybe<Scalars['String']['output']>;
  firstReleaseYear?: Maybe<Scalars['Int']['output']>;
  /** The groups on this track, comma separated. */
  groupDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isrc?: Maybe<Scalars['String']['output']>;
  isrcSurround?: Maybe<Scalars['String']['output']>;
  /** The producers on this track, comma separated. */
  producerDisplayInfo?: Maybe<Scalars['String']['output']>;
  recordingCountry?: Maybe<Scalars['String']['output']>;
  recordingLocation?: Maybe<Scalars['String']['output']>;
  /** ISO 8601 formatted date. May be year or year/month only. */
  recordingSessionEnd?: Maybe<Scalars['String']['output']>;
  /** ISO 8601 formatted date. May be year or year/month only. */
  recordingSessionStart?: Maybe<Scalars['String']['output']>;
  recordingSetting?: Maybe<RecordingSetting>;
  sampleRate?: Maybe<Scalars['Float']['output']>;
  /** The stream for the track */
  stream: Stream;
  subtitle?: Maybe<Scalars['String']['output']>;
  technicalFormat?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackNumber: Scalars['Int']['output'];
};

export type TrackSet = {
  __typename: 'TrackSet';
  /** The composer(s) of the work represented by this track set as comma-separated string. */
  composerDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** The group(s) assembled from all tracks of this trackset as comma-separated string. */
  groupDisplayInfo?: Maybe<Scalars['String']['output']>;
  /** Title of the work represented by this track set. Can be empty for sub sets of a work (eg 2nd act) */
  title?: Maybe<Scalars['String']['output']>;
  trackSetNumber: Scalars['Int']['output'];
  tracks: Array<Track>;
};

export const TrackingConsentType = {
  Allow: 'ALLOW',
  Revoke: 'REVOKE',
} as const;

export type TrackingConsentType = (typeof TrackingConsentType)[keyof typeof TrackingConsentType];
export type TrackingMetadata = {
  __typename: 'TrackingMetadata';
  userType?: Maybe<Scalars['String']['output']>;
};

export const TrackingServiceType = {
  Meta: 'META',
  Mixpanel: 'MIXPANEL',
} as const;

export type TrackingServiceType = (typeof TrackingServiceType)[keyof typeof TrackingServiceType];
export type UpdateDeviceInput = {
  deviceInfo: Scalars['String']['input'];
  osId: OsIdType;
  /** Device token for sending push notifications, not stored on backend */
  pushToken: Scalars['String']['input'];
  timeZoneId: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateDeviceInputPayload = {
  __typename: 'UpdateDeviceInputPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** Update device */
  result?: Maybe<Device>;
};

export type UpdateNewsletterInfoInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sendNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateNewsletterInfoPayload = {
  __typename: 'UpdateNewsletterInfoPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<User>;
};

export type UpdatePostalAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdatePostalAddressPayload = {
  __typename: 'UpdatePostalAddressPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<PostalAddress>;
};

export type UpdateShippingAddressInput = {
  city: Scalars['String']['input'];
  /** Must be valid ISO-3166 country code */
  country: Scalars['String']['input'];
  district?: InputMaybe<Scalars['String']['input']>;
  firstLine: Scalars['String']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateShippingAddressPayload = {
  __typename: 'UpdateShippingAddressPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<PostalAddress>;
};

export type UpdateTrackingInformationInput = {
  metaFbc?: InputMaybe<Scalars['String']['input']>;
  metaFbp?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateTrackingInformationPayload = {
  __typename: 'UpdateTrackingInformationPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserInput = {
  academicTitle?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  liveReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  liveReminderPush?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  pushOptin?: InputMaybe<Scalars['Boolean']['input']>;
  sendNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Payload of this mutation - contains either a result on success or a list of errors */
export type UpdateUserPayload = {
  __typename: 'UpdateUserPayload';
  /** A list of errors. May be null if mutation succeeded. */
  errors?: Maybe<Array<ApiError>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<User>;
};

export type User = {
  __typename: 'User';
  academicTitle?: Maybe<Scalars['String']['output']>;
  affiliateGroup?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  /** Every device that is connected to a user (via password grant or device auth grant */
  devices: Array<Device>;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  favorites?: Maybe<FavoriteConnection>;
  firstName: Scalars['String']['output'];
  /** The user either used a trial period or has/had a paid ticket */
  hasTrialedBefore: Scalars['Boolean']['output'];
  /** UUID that is also in the sub claim of the JWT */
  id: Scalars['String']['output'];
  insertedAt: Scalars['DateTime']['output'];
  /** @deprecated use is_trial on ticket instead */
  isOnTrial: Scalars['Boolean']['output'];
  lastAuthenticatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  liveReminderEmail: Scalars['Boolean']['output'];
  liveReminderPush: Scalars['Boolean']['output'];
  locale: Scalars['String']['output'];
  postalAddress?: Maybe<PostalAddress>;
  pushOptin: Scalars['Boolean']['output'];
  recentlyPlayed?: Maybe<PlaybackProgressConnection>;
  registrationCountry?: Maybe<Scalars['String']['output']>;
  sendNewsletter: Scalars['Boolean']['output'];
  /** NOTE: Please only query those fields when actually required, since we call the Stripe API for the data. */
  stripeInvoices: Array<StripeInvoice>;
  ticket?: Maybe<Ticket>;
  title?: Maybe<Scalars['String']['output']>;
  trackingMetadata: TrackingMetadata;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserFavoritesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<FavoriteOrdering>;
  filter?: InputMaybe<FavoriteType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserRecentlyPlayedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<PlaybackProgressOrdering>;
  filter?: InputMaybe<PlaybackProgressType>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAndToken = {
  __typename: 'UserAndToken';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type ValidationError = ApiError & {
  __typename: 'ValidationError';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  validation: Scalars['String']['output'];
};

export type Video = Node & {
  __typename: 'Video';
  /** Video is available from this date */
  archiveReleaseDate?: Maybe<Scalars['DateTime']['output']>;
  artists?: Maybe<ArtistWithRoleConnection>;
  copyright?: Maybe<Scalars['String']['output']>;
  courtesyOf?: Maybe<Scalars['String']['output']>;
  curatedContent: Array<CuratedContentContainerV2>;
  description?: Maybe<Scalars['String']['output']>;
  /** The duration of the video in seconds. */
  duration: Scalars['Int']['output'];
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>;
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode;
  groups: Array<Group>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isAtmos: Scalars['Boolean']['output'];
  isHdr: Scalars['Boolean']['output'];
  isLossless: Scalars['Boolean']['output'];
  isrc?: Maybe<Scalars['String']['output']>;
  licensor?: Maybe<Scalars['String']['output']>;
  maxResolution: MaxResolution;
  photographer?: Maybe<Scalars['String']['output']>;
  pictures: Array<Picture>;
  production?: Maybe<Scalars['String']['output']>;
  /** Production was finalized on this date */
  productionDate?: Maybe<Scalars['Date']['output']>;
  /** The level that is needed for a user to access the video-stream. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  /** The stream for the video */
  stream: Stream;
  subtitle?: Maybe<Scalars['String']['output']>;
  /** Video is available until this date */
  takedownDate?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  trailerIsrc?: Maybe<Scalars['String']['output']>;
  trailerStream?: Maybe<Stream>;
  type: VideoType;
  typeDisplayName: Scalars['String']['output'];
  /** The datetime the video was inserted, to be used for SEO */
  uploadDate: Scalars['DateTime']['output'];
  videoDirector?: Maybe<Scalars['String']['output']>;
};

export type VideoArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

export type VideoConnection = {
  __typename: 'VideoConnection';
  edges: Array<VideoEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type VideoEdge = {
  __typename: 'VideoEdge';
  cursor: Scalars['String']['output'];
  node: Video;
};

export const VideoType = {
  Documentary: 'DOCUMENTARY',
  Interview: 'INTERVIEW',
  Short: 'SHORT',
} as const;

export type VideoType = (typeof VideoType)[keyof typeof VideoType];
export type VodConcert = Node & {
  __typename: 'VodConcert';
  /** The concert was first aired on this date */
  airDate: Scalars['Date']['output'];
  /** The concert is available from this date */
  archiveReleaseDate?: Maybe<Scalars['DateTime']['output']>;
  artists?: Maybe<ArtistWithRoleConnection>;
  /** Copyright Credit */
  copyright?: Maybe<Scalars['String']['output']>;
  /** A courtesy credit for an appearing artist, usually given in the form "[artist]" appears courtesy of "[label]" */
  courtesyOf?: Maybe<Scalars['String']['output']>;
  curatedContent: Array<CuratedContentContainerV2>;
  /** A list of countries used to evaluate the geoblocking of the stream delivery. */
  geoAccessCountries: Array<Scalars['String']['output']>;
  /** Geo access mode mode. DENY=blocked in specified countries, ALLOW=allowed in specified countries */
  geoAccessMode: GeoAccessMode;
  groups: Array<Group>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  isAtmos: Scalars['Boolean']['output'];
  isHdr: Scalars['Boolean']['output'];
  isImmersive: Scalars['Boolean']['output'];
  isLossless: Scalars['Boolean']['output'];
  isMvVideo: Scalars['Boolean']['output'];
  isrc?: Maybe<Scalars['String']['output']>;
  /** Licensor Credit */
  licensor?: Maybe<Scalars['String']['output']>;
  longFormConcertIntroduction?: Maybe<Scalars['String']['output']>;
  maxResolution: MaxResolution;
  partners: Array<Partner>;
  performanceWorks: Array<PerformanceWork>;
  /** Photogapher Credit */
  photographer?: Maybe<Scalars['String']['output']>;
  pictures: Array<Picture>;
  /** Production Company Credit */
  production?: Maybe<Scalars['String']['output']>;
  /** Production was finalized on this date */
  productionDate?: Maybe<Scalars['Date']['output']>;
  /** The level that is needed for a user to access the video-stream of this concert. U1=everyone, U2=needs account, U3=needs account + ticket */
  publicationLevel: PublicationLevel;
  /** Maximum length: 25 characters */
  seriesTitle?: Maybe<Scalars['String']['output']>;
  /** Maximum length 750 characters */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use curatedContent instead */
  sliders: Array<CuratedContentContainer>;
  /** Maximum length: 25 characters */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** The concert is available till this date */
  takedownDate?: Maybe<Scalars['DateTime']['output']>;
  /** Maximum length: 70 characters */
  title: Scalars['String']['output'];
  /** The total duration of the concert in seconds. */
  totalDuration: Scalars['Int']['output'];
  trailerIsrc?: Maybe<Scalars['String']['output']>;
  trailerStream?: Maybe<Stream>;
  type: VodConcertType;
  typeDisplayName: Scalars['String']['output'];
  /** The datetime the concert was inserted, to be used for SEO */
  uploadDate: Scalars['DateTime']['output'];
  /** Video Director Credit */
  videoDirector?: Maybe<Scalars['String']['output']>;
};

export type VodConcertArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  by?: InputMaybe<ArtistOrdering>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<ArtistRoleFilter>;
};

export type VodConcertConnection = {
  __typename: 'VodConcertConnection';
  edges: Array<VodConcertEdge>;
  pageInfo: PageInfo;
  totalResults: Scalars['Int']['output'];
};

export type VodConcertEdge = {
  __typename: 'VodConcertEdge';
  cursor: Scalars['String']['output'];
  node: VodConcert;
};

export const VodConcertType = {
  Concert: 'CONCERT',
  Dance: 'DANCE',
  Opera: 'OPERA',
} as const;

export type VodConcertType = (typeof VodConcertType)[keyof typeof VodConcertType];
export type Voucher = {
  __typename: 'Voucher';
  /** The projected valid_until of the ticket that will be created if this voucher is used. */
  calculatedValidUntil?: Maybe<Scalars['DateTime']['output']>;
  code: Scalars['String']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  voucherType?: Maybe<Scalars['String']['output']>;
};

export type Work = Node & {
  __typename: 'Work';
  arrangers: Array<Artist>;
  composers: Array<Artist>;
  copyright?: Maybe<Scalars['String']['output']>;
  epochs: Array<Epoch>;
  genre?: Maybe<Genre>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  librettists: Array<Artist>;
  movements: Array<Movement>;
  publisher?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  yearOfComposition?: Maybe<Scalars['Int']['output']>;
};

export type AccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountSettingsQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        academicTitle?: string | undefined;
        country: string;
        email: string;
        firstName: string;
        lastName: string;
        locale: string;
        title?: string | undefined;
        postalAddress?:
          | {
              __typename: 'PostalAddress';
              city?: string | undefined;
              country?: string | undefined;
              district?: string | undefined;
              firstLine?: string | undefined;
              secondLine?: string | undefined;
              province?: string | undefined;
              zipCode?: string | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type ArtistCollectionPageQueryVariables = Exact<{
  artistId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ArtistCollectionPageQuery = {
  __typename: 'RootQueryType';
  artist?:
    | {
        __typename: 'Artist';
        id: string;
        name: string;
        title?: string | undefined;
        biography?: string | undefined;
        dateOfBirth?: string | undefined;
        dateOfDeath?: string | undefined;
        courtesyOf?: string | undefined;
        roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type AudioDetailPageQueryVariables = Exact<{
  albumId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AudioDetailPageQuery = {
  __typename: 'RootQueryType';
  album?:
    | {
        __typename: 'Album';
        artistAndGroupDisplayInfo?: string | undefined;
        upc: string;
        atmosUpc?: string | undefined;
        id: string;
        title: string;
        totalDuration: number;
        releaseDate?: string | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        trackSets: Array<{
          __typename: 'TrackSet';
          composerDisplayInfo?: string | undefined;
          title?: string | undefined;
          trackSetNumber: number;
          tracks: Array<{
            __typename: 'Track';
            id: string;
            artistAndGroupDisplayInfo?: string | undefined;
            composerDisplayInfo?: string | undefined;
            duration: number;
            title: string;
            trackNumber: number;
            album: { __typename: 'Album'; id: string };
            stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
          }>;
        }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type AudioPageQueryVariables = Exact<{ [key: string]: never }>;

export type AudioPageQuery = {
  __typename: 'RootQueryType';
  liveConcerts?:
    | {
        __typename: 'LiveConcertConnection';
        edges: Array<{
          __typename: 'LiveConcertEdge';
          node: {
            __typename: 'LiveConcert';
            id: string;
            title: string;
            startTime: string;
            endTime: string;
            streamStartTime?: string | undefined;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            reruns: Array<{
              __typename: 'LiveConcertRerun';
              startTime: string;
              endTime: string;
              streamStartTime?: string | undefined;
            }>;
          };
        }>;
      }
    | undefined;
  albums?:
    | {
        __typename: 'AlbumConnection';
        edges: Array<{
          __typename: 'AlbumEdge';
          node: {
            __typename: 'Album';
            id: string;
            title: string;
            releaseDate?: string | undefined;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          };
        }>;
      }
    | undefined;
  artists?:
    | {
        __typename: 'ArtistConnection';
        edges: Array<{
          __typename: 'ArtistEdge';
          node: {
            __typename: 'Artist';
            id: string;
            name: string;
            roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          };
        }>;
      }
    | undefined;
};

export type CancelationReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type CancelationReasonsQuery = { __typename: 'RootQueryType'; cancelationReasons: Array<string> };

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type CheckEmailQuery = {
  __typename: 'RootQueryType';
  checkRegistrationData?:
    | { __typename: 'RegistrationDataCheckResult'; emailLikelyValid?: boolean | undefined }
    | undefined;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename: 'RootQueryType';
  countries: Array<{ __typename: 'Country'; iso: string; displayName: string }>;
};

export type CouponCodeQueryVariables = Exact<{
  couponCode: Scalars['String']['input'];
}>;

export type CouponCodeQuery = {
  __typename: 'RootQueryType';
  subscriptionPlansStripe: {
    __typename: 'SubscriptionPlansStripe';
    coupon?:
      | {
          __typename: 'Coupon';
          durationInMonths?: number | undefined;
          name: string;
          percentOff: number;
          productIdsLimitedTo: Array<string>;
          promotionCode: string;
        }
      | undefined;
  };
};

export type CuratedContentQueryVariables = Exact<{
  slug: CuratedContentSlug;
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CuratedContentQuery = {
  __typename: 'RootQueryType';
  curatedContent: {
    __typename: 'CuratedContent';
    stageSlider: Array<{
      __typename: 'StageItemV2';
      label?: string | undefined;
      subtitle?: string | undefined;
      title?: string | undefined;
      item:
        | {
            __typename: 'Album';
            artistAndGroupDisplayInfo?: string | undefined;
            id: string;
            title: string;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          }
        | {
            __typename: 'Artist';
            id: string;
            name: string;
            roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          }
        | {
            __typename: 'Group';
            id: string;
            name: string;
            typeDisplayName: string;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          }
        | {
            __typename: 'LiveConcert';
            endTime: string;
            finalEndTime: string;
            geoAccessCountries: Array<string>;
            geoAccessMode: GeoAccessMode;
            id: string;
            plannedForVod: boolean;
            publicationLevel: PublicationLevel;
            startTime: string;
            streamStartTime?: string | undefined;
            subtitle?: string | undefined;
            title: string;
            type: LiveConcertType;
            typeDisplayName: string;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            reruns: Array<{
              __typename: 'LiveConcertRerun';
              id: string;
              streamStartTime?: string | undefined;
              endTime: string;
              startTime: string;
              stream: {
                __typename: 'RerunStream';
                publicationLevel: PublicationLevel;
                streamType: StreamType;
                url: string;
              };
            }>;
            stream: {
              __typename: 'LiveStream';
              publicationLevel: PublicationLevel;
              streamType: StreamType;
              url: string;
            };
            trailerStream?:
              | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
              | undefined;
          }
        | {
            __typename: 'Partner';
            id: string;
            name: string;
            typeDisplayName: string;
            subtypeDisplayName?: string | undefined;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
          }
        | {
            __typename: 'Video';
            id: string;
            archiveReleaseDate?: string | undefined;
            title: string;
            subtitle?: string | undefined;
            duration: number;
            typeDisplayName: string;
            geoAccessCountries: Array<string>;
            geoAccessMode: GeoAccessMode;
            publicationLevel: PublicationLevel;
            takedownDate?: string | undefined;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
            trailerStream?:
              | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
              | undefined;
          }
        | {
            __typename: 'VodConcert';
            id: string;
            archiveReleaseDate?: string | undefined;
            subtitle?: string | undefined;
            title: string;
            typeDisplayName: string;
            totalDuration: number;
            geoAccessCountries: Array<string>;
            geoAccessMode: GeoAccessMode;
            publicationLevel: PublicationLevel;
            takedownDate?: string | undefined;
            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            trailerStream?:
              | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
              | undefined;
          };
    }>;
    curatedContent: Array<
      | {
          __typename: 'Banner';
          description: string;
          link: string;
          title: string;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        }
      | {
          __typename: 'ListSlider';
          id: string;
          headline: string;
          items: Array<
            | {
                __typename: 'Album';
                artistAndGroupDisplayInfo?: string | undefined;
                id: string;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Artist';
                id: string;
                name: string;
                roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Epoch';
                id: string;
                endYear?: number | undefined;
                startYear?: number | undefined;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Favorite';
                id: string;
                content:
                  | {
                      __typename: 'Album';
                      artistAndGroupDisplayInfo?: string | undefined;
                      id: string;
                      title: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'PerformanceWork';
                      id: string;
                      duration: number;
                      vodConcert: {
                        __typename: 'VodConcert';
                        id: string;
                        title: string;
                        totalDuration: number;
                        typeDisplayName: string;
                        publicationLevel: PublicationLevel;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      work: { __typename: 'Work'; title: string };
                    }
                  | {
                      __typename: 'Video';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      title: string;
                      subtitle?: string | undefined;
                      duration: number;
                      typeDisplayName: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    };
              }
            | {
                __typename: 'Genre';
                id: string;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Group';
                id: string;
                name: string;
                typeDisplayName: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'LiveConcert';
                endTime: string;
                finalEndTime: string;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                id: string;
                plannedForVod: boolean;
                publicationLevel: PublicationLevel;
                startTime: string;
                streamStartTime?: string | undefined;
                subtitle?: string | undefined;
                title: string;
                type: LiveConcertType;
                typeDisplayName: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                reruns: Array<{
                  __typename: 'LiveConcertRerun';
                  id: string;
                  streamStartTime?: string | undefined;
                  endTime: string;
                  startTime: string;
                  stream: {
                    __typename: 'RerunStream';
                    publicationLevel: PublicationLevel;
                    streamType: StreamType;
                    url: string;
                  };
                }>;
                stream: {
                  __typename: 'LiveStream';
                  publicationLevel: PublicationLevel;
                  streamType: StreamType;
                  url: string;
                };
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
            | {
                __typename: 'Partner';
                id: string;
                name: string;
                typeDisplayName: string;
                subtypeDisplayName?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'PlaybackProgress';
                id: string;
                mark: number;
                content:
                  | {
                      __typename: 'PerformanceWork';
                      id: string;
                      duration: number;
                      vodConcert: {
                        __typename: 'VodConcert';
                        id: string;
                        title: string;
                        totalDuration: number;
                        typeDisplayName: string;
                        publicationLevel: PublicationLevel;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      work: { __typename: 'Work'; title: string };
                    }
                  | {
                      __typename: 'Track';
                      id: string;
                      artistAndGroupDisplayInfo?: string | undefined;
                      composerDisplayInfo?: string | undefined;
                      duration: number;
                      title: string;
                      trackNumber: number;
                      album: {
                        __typename: 'Album';
                        id: string;
                        artistAndGroupDisplayInfo?: string | undefined;
                        title: string;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }
                  | {
                      __typename: 'Video';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      title: string;
                      subtitle?: string | undefined;
                      duration: number;
                      typeDisplayName: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    };
              }
            | {
                __typename: 'Video';
                id: string;
                archiveReleaseDate?: string | undefined;
                title: string;
                subtitle?: string | undefined;
                duration: number;
                typeDisplayName: string;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                publicationLevel: PublicationLevel;
                takedownDate?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                stream: {
                  __typename: 'Stream';
                  publicationLevel: PublicationLevel;
                  streamType: StreamType;
                  url: string;
                };
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
            | {
                __typename: 'VodConcert';
                id: string;
                archiveReleaseDate?: string | undefined;
                subtitle?: string | undefined;
                title: string;
                typeDisplayName: string;
                totalDuration: number;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                publicationLevel: PublicationLevel;
                takedownDate?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
          >;
        }
    >;
  };
};

export type CuratedContentNodesQueryVariables = Exact<{ [key: string]: never }>;

export type CuratedContentNodesQuery = {
  __typename: 'RootQueryType';
  curatedContentDiscover: {
    __typename: 'CuratedContent';
    contentContainers: Array<
      | { __typename: 'Banner' }
      | {
          __typename: 'Slider';
          id: string;
          items?:
            | {
                __typename: 'NodeConnectionConnection';
                edges: Array<{
                  __typename: 'NodeConnectionEdge';
                  node:
                    | { __typename: 'Album'; id: string }
                    | { __typename: 'Artist'; id: string }
                    | { __typename: 'Epoch'; id: string }
                    | { __typename: 'Favorite'; id: string }
                    | { __typename: 'Genre'; id: string }
                    | { __typename: 'Group'; id: string }
                    | { __typename: 'Label'; id: string }
                    | { __typename: 'LiveConcert'; id: string }
                    | { __typename: 'LivePerformanceWork'; id: string }
                    | { __typename: 'Movement'; id: string }
                    | { __typename: 'Partner'; id: string }
                    | { __typename: 'PerformanceWork'; id: string }
                    | { __typename: 'Picture'; id: string }
                    | { __typename: 'PlaybackProgress'; id: string }
                    | { __typename: 'Role'; id: string }
                    | { __typename: 'Slider'; id: string }
                    | { __typename: 'Track'; id: string }
                    | { __typename: 'Video'; id: string }
                    | { __typename: 'VodConcert'; id: string }
                    | { __typename: 'Work'; id: string };
                }>;
              }
            | undefined;
        }
    >;
  };
  curatedContentSearch: {
    __typename: 'CuratedContent';
    contentContainers: Array<
      | { __typename: 'Banner' }
      | {
          __typename: 'Slider';
          id: string;
          items?:
            | {
                __typename: 'NodeConnectionConnection';
                edges: Array<{
                  __typename: 'NodeConnectionEdge';
                  node:
                    | { __typename: 'Album'; id: string }
                    | { __typename: 'Artist'; id: string }
                    | { __typename: 'Epoch'; id: string }
                    | { __typename: 'Favorite'; id: string }
                    | { __typename: 'Genre'; id: string }
                    | { __typename: 'Group'; id: string }
                    | { __typename: 'Label'; id: string }
                    | { __typename: 'LiveConcert'; id: string }
                    | { __typename: 'LivePerformanceWork'; id: string }
                    | { __typename: 'Movement'; id: string }
                    | { __typename: 'Partner'; id: string }
                    | { __typename: 'PerformanceWork'; id: string }
                    | { __typename: 'Picture'; id: string }
                    | { __typename: 'PlaybackProgress'; id: string }
                    | { __typename: 'Role'; id: string }
                    | { __typename: 'Slider'; id: string }
                    | { __typename: 'Track'; id: string }
                    | { __typename: 'Video'; id: string }
                    | { __typename: 'VodConcert'; id: string }
                    | { __typename: 'Work'; id: string };
                }>;
              }
            | undefined;
        }
    >;
  };
  curatedContentAudio: {
    __typename: 'CuratedContent';
    contentContainers: Array<
      | { __typename: 'Banner' }
      | {
          __typename: 'Slider';
          id: string;
          items?:
            | {
                __typename: 'NodeConnectionConnection';
                edges: Array<{
                  __typename: 'NodeConnectionEdge';
                  node:
                    | { __typename: 'Album'; id: string }
                    | { __typename: 'Artist'; id: string }
                    | { __typename: 'Epoch'; id: string }
                    | { __typename: 'Favorite'; id: string }
                    | { __typename: 'Genre'; id: string }
                    | { __typename: 'Group'; id: string }
                    | { __typename: 'Label'; id: string }
                    | { __typename: 'LiveConcert'; id: string }
                    | { __typename: 'LivePerformanceWork'; id: string }
                    | { __typename: 'Movement'; id: string }
                    | { __typename: 'Partner'; id: string }
                    | { __typename: 'PerformanceWork'; id: string }
                    | { __typename: 'Picture'; id: string }
                    | { __typename: 'PlaybackProgress'; id: string }
                    | { __typename: 'Role'; id: string }
                    | { __typename: 'Slider'; id: string }
                    | { __typename: 'Track'; id: string }
                    | { __typename: 'Video'; id: string }
                    | { __typename: 'VodConcert'; id: string }
                    | { __typename: 'Work'; id: string };
                }>;
              }
            | undefined;
        }
    >;
  };
};

export type DevicesQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        devices: Array<{ __typename: 'Device'; id: string; deviceInfo: string; updatedAt: string }>;
      }
    | undefined;
};

export type EpochPageQueryVariables = Exact<{
  epochId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type EpochPageQuery = {
  __typename: 'RootQueryType';
  epoch?:
    | {
        __typename: 'Epoch';
        id: string;
        title: string;
        subtitle?: string | undefined;
        description?: string | undefined;
        startYear?: number | undefined;
        endYear?: number | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type FavoriteByContentIdQueryVariables = Exact<{
  contentId: Scalars['ID']['input'];
}>;

export type FavoriteByContentIdQuery = {
  __typename: 'RootQueryType';
  favoriteByContentId?: { __typename: 'Favorite'; id: string } | undefined;
};

export type TrackSetFieldsFragment = {
  __typename: 'TrackSet';
  composerDisplayInfo?: string | undefined;
  title?: string | undefined;
  trackSetNumber: number;
  tracks: Array<{
    __typename: 'Track';
    id: string;
    artistAndGroupDisplayInfo?: string | undefined;
    composerDisplayInfo?: string | undefined;
    duration: number;
    title: string;
    trackNumber: number;
    album: { __typename: 'Album'; id: string };
    stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
  }>;
};

export type AlbumFieldsFragment = {
  __typename: 'Album';
  artistAndGroupDisplayInfo?: string | undefined;
  id: string;
  title: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

type ApiErrorFields_BasicError_Fragment = { __typename: 'BasicError'; code: string; message: string };

type ApiErrorFields_ValidationError_Fragment = {
  __typename: 'ValidationError';
  message: string;
  validation: string;
  field: string;
};

export type ApiErrorFieldsFragment = ApiErrorFields_BasicError_Fragment | ApiErrorFields_ValidationError_Fragment;

export type ArtistFieldsFragment = {
  __typename: 'Artist';
  id: string;
  name: string;
  roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

export type ArtistWithRoleConnectionFieldsFragment = {
  __typename: 'ArtistWithRoleConnection';
  edges: Array<{
    __typename: 'ArtistWithRoleEdge';
    role: { __typename: 'Role'; displayName: string };
    node: { __typename: 'Artist'; id: string; name: string };
  }>;
};

export type BannerFieldsFragment = {
  __typename: 'Banner';
  description: string;
  link: string;
  title: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

type BaseNodeFields_Album_Fragment = { __typename: 'Album'; id: string };

type BaseNodeFields_Artist_Fragment = { __typename: 'Artist'; id: string };

type BaseNodeFields_Epoch_Fragment = { __typename: 'Epoch'; id: string };

type BaseNodeFields_Favorite_Fragment = { __typename: 'Favorite'; id: string };

type BaseNodeFields_Genre_Fragment = { __typename: 'Genre'; id: string };

type BaseNodeFields_Group_Fragment = { __typename: 'Group'; id: string };

type BaseNodeFields_Label_Fragment = { __typename: 'Label'; id: string };

type BaseNodeFields_LiveConcert_Fragment = { __typename: 'LiveConcert'; id: string };

type BaseNodeFields_LivePerformanceWork_Fragment = { __typename: 'LivePerformanceWork'; id: string };

type BaseNodeFields_Movement_Fragment = { __typename: 'Movement'; id: string };

type BaseNodeFields_Partner_Fragment = { __typename: 'Partner'; id: string };

type BaseNodeFields_PerformanceWork_Fragment = { __typename: 'PerformanceWork'; id: string };

type BaseNodeFields_Picture_Fragment = { __typename: 'Picture'; id: string };

type BaseNodeFields_PlaybackProgress_Fragment = { __typename: 'PlaybackProgress'; id: string };

type BaseNodeFields_Role_Fragment = { __typename: 'Role'; id: string };

type BaseNodeFields_Slider_Fragment = { __typename: 'Slider'; id: string };

type BaseNodeFields_Track_Fragment = { __typename: 'Track'; id: string };

type BaseNodeFields_Video_Fragment = { __typename: 'Video'; id: string };

type BaseNodeFields_VodConcert_Fragment = { __typename: 'VodConcert'; id: string };

type BaseNodeFields_Work_Fragment = { __typename: 'Work'; id: string };

export type BaseNodeFieldsFragment =
  | BaseNodeFields_Album_Fragment
  | BaseNodeFields_Artist_Fragment
  | BaseNodeFields_Epoch_Fragment
  | BaseNodeFields_Favorite_Fragment
  | BaseNodeFields_Genre_Fragment
  | BaseNodeFields_Group_Fragment
  | BaseNodeFields_Label_Fragment
  | BaseNodeFields_LiveConcert_Fragment
  | BaseNodeFields_LivePerformanceWork_Fragment
  | BaseNodeFields_Movement_Fragment
  | BaseNodeFields_Partner_Fragment
  | BaseNodeFields_PerformanceWork_Fragment
  | BaseNodeFields_Picture_Fragment
  | BaseNodeFields_PlaybackProgress_Fragment
  | BaseNodeFields_Role_Fragment
  | BaseNodeFields_Slider_Fragment
  | BaseNodeFields_Track_Fragment
  | BaseNodeFields_Video_Fragment
  | BaseNodeFields_VodConcert_Fragment
  | BaseNodeFields_Work_Fragment;

export type BaseSliderNodeFieldsFragment = {
  __typename: 'Slider';
  id: string;
  items?:
    | {
        __typename: 'NodeConnectionConnection';
        edges: Array<{
          __typename: 'NodeConnectionEdge';
          node:
            | { __typename: 'Album'; id: string }
            | { __typename: 'Artist'; id: string }
            | { __typename: 'Epoch'; id: string }
            | { __typename: 'Favorite'; id: string }
            | { __typename: 'Genre'; id: string }
            | { __typename: 'Group'; id: string }
            | { __typename: 'Label'; id: string }
            | { __typename: 'LiveConcert'; id: string }
            | { __typename: 'LivePerformanceWork'; id: string }
            | { __typename: 'Movement'; id: string }
            | { __typename: 'Partner'; id: string }
            | { __typename: 'PerformanceWork'; id: string }
            | { __typename: 'Picture'; id: string }
            | { __typename: 'PlaybackProgress'; id: string }
            | { __typename: 'Role'; id: string }
            | { __typename: 'Slider'; id: string }
            | { __typename: 'Track'; id: string }
            | { __typename: 'Video'; id: string }
            | { __typename: 'VodConcert'; id: string }
            | { __typename: 'Work'; id: string };
        }>;
      }
    | undefined;
};

type CuratedContentContainerFields_Banner_Fragment = {
  __typename: 'Banner';
  description: string;
  link: string;
  title: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

type CuratedContentContainerFields_ListSlider_Fragment = {
  __typename: 'ListSlider';
  id: string;
  headline: string;
  items: Array<
    | {
        __typename: 'Album';
        artistAndGroupDisplayInfo?: string | undefined;
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Artist';
        id: string;
        name: string;
        roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Epoch';
        id: string;
        endYear?: number | undefined;
        startYear?: number | undefined;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Favorite';
        id: string;
        content:
          | {
              __typename: 'Album';
              artistAndGroupDisplayInfo?: string | undefined;
              id: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'PerformanceWork';
              id: string;
              duration: number;
              vodConcert: {
                __typename: 'VodConcert';
                id: string;
                title: string;
                totalDuration: number;
                typeDisplayName: string;
                publicationLevel: PublicationLevel;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              work: { __typename: 'Work'; title: string };
            }
          | {
              __typename: 'Video';
              id: string;
              archiveReleaseDate?: string | undefined;
              title: string;
              subtitle?: string | undefined;
              duration: number;
              typeDisplayName: string;
              geoAccessCountries: Array<string>;
              geoAccessMode: GeoAccessMode;
              publicationLevel: PublicationLevel;
              takedownDate?: string | undefined;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
              trailerStream?:
                | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                | undefined;
            };
      }
    | {
        __typename: 'Genre';
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Group';
        id: string;
        name: string;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'LiveConcert';
        endTime: string;
        finalEndTime: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        id: string;
        plannedForVod: boolean;
        publicationLevel: PublicationLevel;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        type: LiveConcertType;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'Partner';
        id: string;
        name: string;
        typeDisplayName: string;
        subtypeDisplayName?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'PlaybackProgress';
        id: string;
        mark: number;
        content:
          | {
              __typename: 'PerformanceWork';
              id: string;
              duration: number;
              vodConcert: {
                __typename: 'VodConcert';
                id: string;
                title: string;
                totalDuration: number;
                typeDisplayName: string;
                publicationLevel: PublicationLevel;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              work: { __typename: 'Work'; title: string };
            }
          | {
              __typename: 'Track';
              id: string;
              artistAndGroupDisplayInfo?: string | undefined;
              composerDisplayInfo?: string | undefined;
              duration: number;
              title: string;
              trackNumber: number;
              album: {
                __typename: 'Album';
                id: string;
                artistAndGroupDisplayInfo?: string | undefined;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
            }
          | {
              __typename: 'Video';
              id: string;
              archiveReleaseDate?: string | undefined;
              title: string;
              subtitle?: string | undefined;
              duration: number;
              typeDisplayName: string;
              geoAccessCountries: Array<string>;
              geoAccessMode: GeoAccessMode;
              publicationLevel: PublicationLevel;
              takedownDate?: string | undefined;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
              trailerStream?:
                | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                | undefined;
            };
      }
    | {
        __typename: 'Video';
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'VodConcert';
        id: string;
        archiveReleaseDate?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        typeDisplayName: string;
        totalDuration: number;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
  >;
};

export type CuratedContentContainerFieldsFragment =
  | CuratedContentContainerFields_Banner_Fragment
  | CuratedContentContainerFields_ListSlider_Fragment;

export type EpochFieldsFragment = {
  __typename: 'Epoch';
  id: string;
  endYear?: number | undefined;
  startYear?: number | undefined;
  title: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

export type FavoritesFieldsFragment = {
  __typename: 'Favorite';
  id: string;
  content:
    | {
        __typename: 'Album';
        artistAndGroupDisplayInfo?: string | undefined;
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'PerformanceWork';
        id: string;
        duration: number;
        vodConcert: {
          __typename: 'VodConcert';
          id: string;
          title: string;
          totalDuration: number;
          typeDisplayName: string;
          publicationLevel: PublicationLevel;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        };
        work: { __typename: 'Work'; title: string };
      }
    | {
        __typename: 'Video';
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      };
};

export type GenreFieldsFragment = {
  __typename: 'Genre';
  id: string;
  title: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

export type GroupFieldsFragment = {
  __typename: 'Group';
  id: string;
  name: string;
  typeDisplayName: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

export type LabelFieldsFragment = { __typename: 'Label'; id: string; title: string };

export type ListSliderFieldsFragment = {
  __typename: 'ListSlider';
  id: string;
  headline: string;
  items: Array<
    | {
        __typename: 'Album';
        artistAndGroupDisplayInfo?: string | undefined;
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Artist';
        id: string;
        name: string;
        roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Epoch';
        id: string;
        endYear?: number | undefined;
        startYear?: number | undefined;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Favorite';
        id: string;
        content:
          | {
              __typename: 'Album';
              artistAndGroupDisplayInfo?: string | undefined;
              id: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'PerformanceWork';
              id: string;
              duration: number;
              vodConcert: {
                __typename: 'VodConcert';
                id: string;
                title: string;
                totalDuration: number;
                typeDisplayName: string;
                publicationLevel: PublicationLevel;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              work: { __typename: 'Work'; title: string };
            }
          | {
              __typename: 'Video';
              id: string;
              archiveReleaseDate?: string | undefined;
              title: string;
              subtitle?: string | undefined;
              duration: number;
              typeDisplayName: string;
              geoAccessCountries: Array<string>;
              geoAccessMode: GeoAccessMode;
              publicationLevel: PublicationLevel;
              takedownDate?: string | undefined;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
              trailerStream?:
                | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                | undefined;
            };
      }
    | {
        __typename: 'Genre';
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'Group';
        id: string;
        name: string;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'LiveConcert';
        endTime: string;
        finalEndTime: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        id: string;
        plannedForVod: boolean;
        publicationLevel: PublicationLevel;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        type: LiveConcertType;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'Partner';
        id: string;
        name: string;
        typeDisplayName: string;
        subtypeDisplayName?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'PlaybackProgress';
        id: string;
        mark: number;
        content:
          | {
              __typename: 'PerformanceWork';
              id: string;
              duration: number;
              vodConcert: {
                __typename: 'VodConcert';
                id: string;
                title: string;
                totalDuration: number;
                typeDisplayName: string;
                publicationLevel: PublicationLevel;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              work: { __typename: 'Work'; title: string };
            }
          | {
              __typename: 'Track';
              id: string;
              artistAndGroupDisplayInfo?: string | undefined;
              composerDisplayInfo?: string | undefined;
              duration: number;
              title: string;
              trackNumber: number;
              album: {
                __typename: 'Album';
                id: string;
                artistAndGroupDisplayInfo?: string | undefined;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              };
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
            }
          | {
              __typename: 'Video';
              id: string;
              archiveReleaseDate?: string | undefined;
              title: string;
              subtitle?: string | undefined;
              duration: number;
              typeDisplayName: string;
              geoAccessCountries: Array<string>;
              geoAccessMode: GeoAccessMode;
              publicationLevel: PublicationLevel;
              takedownDate?: string | undefined;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
              trailerStream?:
                | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                | undefined;
            };
      }
    | {
        __typename: 'Video';
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'VodConcert';
        id: string;
        archiveReleaseDate?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        typeDisplayName: string;
        totalDuration: number;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
  >;
};

export type LiveConcertFieldsFragment = {
  __typename: 'LiveConcert';
  endTime: string;
  finalEndTime: string;
  geoAccessCountries: Array<string>;
  geoAccessMode: GeoAccessMode;
  id: string;
  plannedForVod: boolean;
  publicationLevel: PublicationLevel;
  startTime: string;
  streamStartTime?: string | undefined;
  subtitle?: string | undefined;
  title: string;
  type: LiveConcertType;
  typeDisplayName: string;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
  reruns: Array<{
    __typename: 'LiveConcertRerun';
    id: string;
    streamStartTime?: string | undefined;
    endTime: string;
    startTime: string;
    stream: { __typename: 'RerunStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
  }>;
  stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
  trailerStream?:
    | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
    | undefined;
};

export type LiveConcertRerunFieldsFragment = {
  __typename: 'LiveConcertRerun';
  id: string;
  streamStartTime?: string | undefined;
  endTime: string;
  startTime: string;
  stream: { __typename: 'RerunStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
};

export type LivePerformanceWorkFieldsFragment = {
  __typename: 'LivePerformanceWork';
  id: string;
  artists?:
    | {
        __typename: 'ArtistWithRoleConnection';
        edges: Array<{
          __typename: 'ArtistWithRoleEdge';
          role: { __typename: 'Role'; displayName: string };
          node: { __typename: 'Artist'; id: string; name: string };
        }>;
      }
    | undefined;
  soloists?:
    | {
        __typename: 'ArtistWithRoleConnection';
        edges: Array<{
          __typename: 'ArtistWithRoleEdge';
          role: { __typename: 'Role'; displayName: string };
          node: { __typename: 'Artist'; id: string; name: string };
        }>;
      }
    | undefined;
  groups?:
    | {
        __typename: 'GroupConnection';
        edges: Array<{
          __typename: 'GroupEdge';
          node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
        }>;
      }
    | undefined;
  work: {
    __typename: 'Work';
    id: string;
    title: string;
    movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
    composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
    genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
  };
};

export type LiveStreamFieldsFragment = {
  __typename: 'LiveStream';
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: string;
};

export type PartnerFieldsFragment = {
  __typename: 'Partner';
  id: string;
  name: string;
  typeDisplayName: string;
  subtypeDisplayName?: string | undefined;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
};

type PaymentMethodFields_StripePaymentMethodCard_Fragment = {
  __typename: 'StripePaymentMethodCard';
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
};

type PaymentMethodFields_StripePaymentMethodPaypal_Fragment = {
  __typename: 'StripePaymentMethodPaypal';
  payerEmail: string;
};

export type PaymentMethodFieldsFragment =
  | PaymentMethodFields_StripePaymentMethodCard_Fragment
  | PaymentMethodFields_StripePaymentMethodPaypal_Fragment;

export type PerformanceWorkConcertFieldsFragment = {
  __typename: 'PerformanceWork';
  id: string;
  duration: number;
  vodConcert: {
    __typename: 'VodConcert';
    id: string;
    title: string;
    totalDuration: number;
    typeDisplayName: string;
    publicationLevel: PublicationLevel;
    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
  };
  work: { __typename: 'Work'; title: string };
};

export type PerformanceWorkFieldsFragment = {
  __typename: 'PerformanceWork';
  id: string;
  duration: number;
  artists?:
    | {
        __typename: 'ArtistWithRoleConnection';
        edges: Array<{
          __typename: 'ArtistWithRoleEdge';
          role: { __typename: 'Role'; displayName: string };
          node: { __typename: 'Artist'; id: string; name: string };
        }>;
      }
    | undefined;
  soloists?:
    | {
        __typename: 'ArtistWithRoleConnection';
        edges: Array<{
          __typename: 'ArtistWithRoleEdge';
          role: { __typename: 'Role'; displayName: string };
          node: { __typename: 'Artist'; id: string; name: string };
        }>;
      }
    | undefined;
  groups?:
    | {
        __typename: 'GroupConnection';
        edges: Array<{
          __typename: 'GroupEdge';
          node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
        }>;
      }
    | undefined;
  work: {
    __typename: 'Work';
    id: string;
    title: string;
    movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
    composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
    genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
  };
  stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
  vodConcert: { __typename: 'VodConcert'; id: string };
  cuePoints: Array<{ __typename: 'CuePoint'; mark: number; title: string }>;
};

export type PictureFieldsFragment = { __typename: 'Picture'; id: string; url: string; type: PictureType };

export type PlaybackProgressFieldsFragment = {
  __typename: 'PlaybackProgress';
  id: string;
  mark: number;
  content:
    | {
        __typename: 'PerformanceWork';
        id: string;
        duration: number;
        vodConcert: {
          __typename: 'VodConcert';
          id: string;
          title: string;
          totalDuration: number;
          typeDisplayName: string;
          publicationLevel: PublicationLevel;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        };
        work: { __typename: 'Work'; title: string };
      }
    | {
        __typename: 'Track';
        id: string;
        artistAndGroupDisplayInfo?: string | undefined;
        composerDisplayInfo?: string | undefined;
        duration: number;
        title: string;
        trackNumber: number;
        album: {
          __typename: 'Album';
          id: string;
          artistAndGroupDisplayInfo?: string | undefined;
          title: string;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        };
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
      }
    | {
        __typename: 'Video';
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      };
};

export type RerunStreamFieldsFragment = {
  __typename: 'RerunStream';
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: string;
};

export type RoleFieldsFragment = { __typename: 'Role'; id: string; type: RoleType; displayName: string };

export type StreamFieldsFragment = {
  __typename: 'Stream';
  publicationLevel: PublicationLevel;
  streamType: StreamType;
  url: string;
};

export type SubscriptionPlansStripeCouponFieldsFragment = {
  __typename: 'SubscriptionPlansStripe';
  coupon?:
    | {
        __typename: 'Coupon';
        durationInMonths?: number | undefined;
        name: string;
        percentOff: number;
        productIdsLimitedTo: Array<string>;
        promotionCode: string;
      }
    | undefined;
};

export type SubscriptionPlansStripePlansFieldsFragment = {
  __typename: 'SubscriptionPlansStripe';
  plans: Array<{
    __typename: 'SubscriptionPlanStripe';
    interval: SubscriptionPlanInterval;
    isPlanChangeSupported: boolean;
    isPurchasePossible: boolean;
    productId: string;
    requiresPaymentMethod: boolean;
    ticketType: TicketType;
    trialPeriodDays: number;
    prices: Array<{ __typename: 'Price'; id: string; currency: string; amount: number; amountUndiscounted: number }>;
  }>;
};

export type TrackFieldsFragment = {
  __typename: 'Track';
  id: string;
  artistAndGroupDisplayInfo?: string | undefined;
  composerDisplayInfo?: string | undefined;
  duration: number;
  title: string;
  trackNumber: number;
  album: { __typename: 'Album'; id: string };
  stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
};

export type TrackWithAlbumFieldsFragment = {
  __typename: 'Track';
  id: string;
  artistAndGroupDisplayInfo?: string | undefined;
  composerDisplayInfo?: string | undefined;
  duration: number;
  title: string;
  trackNumber: number;
  album: {
    __typename: 'Album';
    id: string;
    artistAndGroupDisplayInfo?: string | undefined;
    title: string;
    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
  };
  stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
};

export type VideoFieldsFragment = {
  __typename: 'Video';
  id: string;
  archiveReleaseDate?: string | undefined;
  title: string;
  subtitle?: string | undefined;
  duration: number;
  typeDisplayName: string;
  geoAccessCountries: Array<string>;
  geoAccessMode: GeoAccessMode;
  publicationLevel: PublicationLevel;
  takedownDate?: string | undefined;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
  stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
  trailerStream?:
    | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
    | undefined;
};

export type VodConcertFieldsFragment = {
  __typename: 'VodConcert';
  id: string;
  archiveReleaseDate?: string | undefined;
  subtitle?: string | undefined;
  title: string;
  typeDisplayName: string;
  totalDuration: number;
  geoAccessCountries: Array<string>;
  geoAccessMode: GeoAccessMode;
  publicationLevel: PublicationLevel;
  takedownDate?: string | undefined;
  pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
  trailerStream?:
    | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
    | undefined;
};

export type WorkFieldsFragment = {
  __typename: 'Work';
  id: string;
  title: string;
  movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
  composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
  genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
};

export type GenrePageQueryVariables = Exact<{
  genreId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GenrePageQuery = {
  __typename: 'RootQueryType';
  genre?:
    | {
        __typename: 'Genre';
        id: string;
        title: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type GeoinfoQueryVariables = Exact<{ [key: string]: never }>;

export type GeoinfoQuery = {
  __typename: 'RootQueryType';
  geoinfo?: { __typename: 'GeoinfoPayload'; countryCode: string; currencyCode: string } | undefined;
};

export type GroupPageQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GroupPageQuery = {
  __typename: 'RootQueryType';
  group?:
    | {
        __typename: 'Group';
        id: string;
        name: string;
        description?: string | undefined;
        footnote?: string | undefined;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type InfoModalQueryVariables = Exact<{
  nodeId: Scalars['ID']['input'];
}>;

export type InfoModalQuery = {
  __typename: 'RootQueryType';
  node?:
    | {
        __typename: 'Album';
        atmosUpc?: string | undefined;
        id: string;
        phonographicCopyright: string;
        releaseDate?: string | undefined;
        title: string;
        totalDuration: number;
        upc: string;
        albumCopyright: string;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'Artist' }
    | { __typename: 'Epoch' }
    | { __typename: 'Favorite' }
    | { __typename: 'Genre' }
    | { __typename: 'Group' }
    | { __typename: 'Label' }
    | {
        __typename: 'LiveConcert';
        copyright?: string | undefined;
        endTime: string;
        finalEndTime: string;
        id: string;
        isrc?: string | undefined;
        longFormConcertIntroduction?: string | undefined;
        photographer?: string | undefined;
        production?: string | undefined;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        totalDuration: number;
        type: LiveConcertType;
        typeDisplayName: string;
        videoDirector?: string | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        partners: Array<{ __typename: 'Partner'; name: string; type: PartnerType }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
      }
    | { __typename: 'LivePerformanceWork' }
    | { __typename: 'Movement' }
    | { __typename: 'Partner' }
    | { __typename: 'PerformanceWork' }
    | { __typename: 'Picture' }
    | { __typename: 'PlaybackProgress' }
    | { __typename: 'Role' }
    | { __typename: 'Slider' }
    | {
        __typename: 'Track';
        composerDisplayInfo?: string | undefined;
        conductorDisplayInfo?: string | undefined;
        duration: number;
        engineerDisplayInfo?: string | undefined;
        firstReleaseYear?: number | undefined;
        groupDisplayInfo?: string | undefined;
        id: string;
        isrc?: string | undefined;
        recordingSessionEnd?: string | undefined;
        recordingLocation?: string | undefined;
        recordingSetting?: RecordingSetting | undefined;
        producerDisplayInfo?: string | undefined;
        title: string;
        trackNumber: number;
        album: {
          __typename: 'Album';
          copyright: string;
          phonographicCopyright: string;
          title: string;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        };
      }
    | {
        __typename: 'Video';
        copyright?: string | undefined;
        duration: number;
        id: string;
        isrc?: string | undefined;
        photographer?: string | undefined;
        production?: string | undefined;
        productionDate?: string | undefined;
        subtitle?: string | undefined;
        takedownDate?: string | undefined;
        title: string;
        typeDisplayName: string;
        videoDirector?: string | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | {
        __typename: 'VodConcert';
        copyright?: string | undefined;
        courtesyOf?: string | undefined;
        id: string;
        isrc?: string | undefined;
        photographer?: string | undefined;
        production?: string | undefined;
        productionDate?: string | undefined;
        longFormConcertIntroduction?: string | undefined;
        subtitle?: string | undefined;
        takedownDate?: string | undefined;
        title: string;
        totalDuration: number;
        typeDisplayName: string;
        videoDirector?: string | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        partners: Array<{ __typename: 'Partner'; name: string; type: PartnerType }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'Work' }
    | undefined;
};

export type LandingPageQueryVariables = Exact<{
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type LandingPageQuery = {
  __typename: 'RootQueryType';
  curatedContent: {
    __typename: 'CuratedContent';
    curatedContent: Array<
      | {
          __typename: 'Banner';
          description: string;
          link: string;
          title: string;
          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        }
      | {
          __typename: 'ListSlider';
          id: string;
          headline: string;
          items: Array<
            | {
                __typename: 'Album';
                artistAndGroupDisplayInfo?: string | undefined;
                id: string;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Artist';
                id: string;
                name: string;
                roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Epoch';
                id: string;
                endYear?: number | undefined;
                startYear?: number | undefined;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Favorite';
                id: string;
                content:
                  | {
                      __typename: 'Album';
                      artistAndGroupDisplayInfo?: string | undefined;
                      id: string;
                      title: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'PerformanceWork';
                      id: string;
                      duration: number;
                      vodConcert: {
                        __typename: 'VodConcert';
                        id: string;
                        title: string;
                        totalDuration: number;
                        typeDisplayName: string;
                        publicationLevel: PublicationLevel;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      work: { __typename: 'Work'; title: string };
                    }
                  | {
                      __typename: 'Video';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      title: string;
                      subtitle?: string | undefined;
                      duration: number;
                      typeDisplayName: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    };
              }
            | {
                __typename: 'Genre';
                id: string;
                title: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'Group';
                id: string;
                name: string;
                typeDisplayName: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'LiveConcert';
                endTime: string;
                finalEndTime: string;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                id: string;
                plannedForVod: boolean;
                publicationLevel: PublicationLevel;
                startTime: string;
                streamStartTime?: string | undefined;
                subtitle?: string | undefined;
                title: string;
                type: LiveConcertType;
                typeDisplayName: string;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                reruns: Array<{
                  __typename: 'LiveConcertRerun';
                  id: string;
                  streamStartTime?: string | undefined;
                  endTime: string;
                  startTime: string;
                  stream: {
                    __typename: 'RerunStream';
                    publicationLevel: PublicationLevel;
                    streamType: StreamType;
                    url: string;
                  };
                }>;
                stream: {
                  __typename: 'LiveStream';
                  publicationLevel: PublicationLevel;
                  streamType: StreamType;
                  url: string;
                };
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
            | {
                __typename: 'Partner';
                id: string;
                name: string;
                typeDisplayName: string;
                subtypeDisplayName?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
              }
            | {
                __typename: 'PlaybackProgress';
                id: string;
                mark: number;
                content:
                  | {
                      __typename: 'PerformanceWork';
                      id: string;
                      duration: number;
                      vodConcert: {
                        __typename: 'VodConcert';
                        id: string;
                        title: string;
                        totalDuration: number;
                        typeDisplayName: string;
                        publicationLevel: PublicationLevel;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      work: { __typename: 'Work'; title: string };
                    }
                  | {
                      __typename: 'Track';
                      id: string;
                      artistAndGroupDisplayInfo?: string | undefined;
                      composerDisplayInfo?: string | undefined;
                      duration: number;
                      title: string;
                      trackNumber: number;
                      album: {
                        __typename: 'Album';
                        id: string;
                        artistAndGroupDisplayInfo?: string | undefined;
                        title: string;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      };
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }
                  | {
                      __typename: 'Video';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      title: string;
                      subtitle?: string | undefined;
                      duration: number;
                      typeDisplayName: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    };
              }
            | {
                __typename: 'Video';
                id: string;
                archiveReleaseDate?: string | undefined;
                title: string;
                subtitle?: string | undefined;
                duration: number;
                typeDisplayName: string;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                publicationLevel: PublicationLevel;
                takedownDate?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                stream: {
                  __typename: 'Stream';
                  publicationLevel: PublicationLevel;
                  streamType: StreamType;
                  url: string;
                };
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
            | {
                __typename: 'VodConcert';
                id: string;
                archiveReleaseDate?: string | undefined;
                subtitle?: string | undefined;
                title: string;
                typeDisplayName: string;
                totalDuration: number;
                geoAccessCountries: Array<string>;
                geoAccessMode: GeoAccessMode;
                publicationLevel: PublicationLevel;
                takedownDate?: string | undefined;
                pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                trailerStream?:
                  | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
                  | undefined;
              }
          >;
        }
    >;
  };
  subscriptionPlansStripe: {
    __typename: 'SubscriptionPlansStripe';
    plans: Array<{
      __typename: 'SubscriptionPlanStripe';
      interval: SubscriptionPlanInterval;
      isPlanChangeSupported: boolean;
      isPurchasePossible: boolean;
      productId: string;
      requiresPaymentMethod: boolean;
      ticketType: TicketType;
      trialPeriodDays: number;
      prices: Array<{ __typename: 'Price'; id: string; currency: string; amount: number; amountUndiscounted: number }>;
    }>;
  };
};

export type AddFavoriteMutationVariables = Exact<{
  input?: InputMaybe<AddFavoriteInput>;
}>;

export type AddFavoriteMutation = {
  __typename: 'RootMutationType';
  addFavorite?:
    | {
        __typename: 'AddFavoritePayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'Favorite'; id: string } | undefined;
      }
    | undefined;
};

export type AuthorizeDeviceForUserMutationVariables = Exact<{
  input?: InputMaybe<AuthorizeDeviceForUserInput>;
}>;

export type AuthorizeDeviceForUserMutation = {
  __typename: 'RootMutationType';
  authorizeDeviceForUser?:
    | {
        __typename: 'AuthorizeDeviceForUserInputPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'Device'; id: string } | undefined;
      }
    | undefined;
};

export type CancelStripeSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelStripeSubscriptionMutation = {
  __typename: 'RootMutationType';
  cancelStripeSubscription?:
    | {
        __typename: 'CancelSubscriptionPayload';
        result?: { __typename: 'SubscriptionCancelation'; ticketTerminatesAt: string } | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type ChangePasswordMutationVariables = Exact<{
  input?: InputMaybe<ChangePasswordInput>;
}>;

export type ChangePasswordMutation = {
  __typename: 'RootMutationType';
  changePassword?:
    | {
        __typename: 'ChangePasswordPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type ChangeStripePaymentMethodMutationVariables = Exact<{ [key: string]: never }>;

export type ChangeStripePaymentMethodMutation = {
  __typename: 'RootMutationType';
  changeStripePaymentMethod?:
    | {
        __typename: 'ChangeStripePaymentMethodPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'SetupIntentClientSecret'; clientSecret: string } | undefined;
      }
    | undefined;
};

export type ChangeStripeSubscriptionPlanMutationVariables = Exact<{
  input?: InputMaybe<ChangeStripeSubscriptionPlanInput>;
}>;

export type ChangeStripeSubscriptionPlanMutation = {
  __typename: 'RootMutationType';
  changeStripeSubscriptionPlan?:
    | {
        __typename: 'ChangeStripeSubscriptionPlanPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type ConsentTrackingMutationVariables = Exact<{
  mixpanelConsent: TrackingConsentType;
  metaConsent: TrackingConsentType;
}>;

export type ConsentTrackingMutation = {
  __typename: 'RootMutationType';
  consentTrackingMixpanel?:
    | {
        __typename: 'ConsentTrackingPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
  consentTrackingMeta?:
    | {
        __typename: 'ConsentTrackingPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserMutation = {
  __typename: 'RootMutationType';
  deleteUser?:
    | {
        __typename: 'DeleteUserPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'User'; email: string } | undefined;
      }
    | undefined;
};

export type GeneratePinCodeMutationVariables = Exact<{ [key: string]: never }>;

export type GeneratePinCodeMutation = {
  __typename: 'RootMutationType';
  generatePinCode?:
    | {
        __typename: 'GeneratePinCodePayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'PinCode'; expiresAt: string; pinCode: string } | undefined;
      }
    | undefined;
};

export type ReactivateStripeSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type ReactivateStripeSubscriptionMutation = {
  __typename: 'RootMutationType';
  reactivateStripeSubscription?:
    | {
        __typename: 'ReactivateSubscriptionPayload';
        result?: { __typename: 'Ticket'; status: TicketStatus; validUntil?: string | undefined } | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type RecordPlaybackProgressMutationVariables = Exact<{
  input?: InputMaybe<RecordPlaybackProgressInput>;
}>;

export type RecordPlaybackProgressMutation = {
  __typename: 'RootMutationType';
  recordPlaybackProgress?:
    | {
        __typename: 'RecordPlaybackProgressPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?: { __typename: 'PlaybackProgress'; id: string } | undefined;
      }
    | undefined;
};

export type RegisterUserMutationVariables = Exact<{
  input?: InputMaybe<RegisterUserInput>;
}>;

export type RegisterUserMutation = {
  __typename: 'RootMutationType';
  registerUser?:
    | {
        __typename: 'RegisterUserPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?:
          | {
              __typename: 'UserAndToken';
              accessToken: string;
              refreshToken: string;
              user: { __typename: 'User'; locale: string };
            }
          | undefined;
      }
    | undefined;
};

export type RemoveDeviceMutationVariables = Exact<{
  input?: InputMaybe<RemoveDeviceInput>;
}>;

export type RemoveDeviceMutation = {
  __typename: 'RootMutationType';
  removeDevice?:
    | {
        __typename: 'RemoveDeviceInputPayload';
        result?: string | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type RemoveFavoriteByContentIdMutationVariables = Exact<{
  input?: InputMaybe<RemoveFavoriteByContentIdInput>;
}>;

export type RemoveFavoriteByContentIdMutation = {
  __typename: 'RootMutationType';
  removeFavoriteByContentId?:
    | {
        __typename: 'RemoveFavoriteByContentIdPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type RemoveFavoriteByContentTypeMutationVariables = Exact<{
  input?: InputMaybe<RemoveFavoriteByContentTypeInput>;
}>;

export type RemoveFavoriteByContentTypeMutation = {
  __typename: 'RootMutationType';
  removeFavoriteByContentType?:
    | {
        __typename: 'RemoveFavoriteByContentTypePayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type ResendEmailConfirmationMutationVariables = Exact<{ [key: string]: never }>;

export type ResendEmailConfirmationMutation = {
  __typename: 'RootMutationType';
  resendEmailConfirmation?:
    | {
        __typename: 'ResendEmailConfirmationPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type ResetPasswordMutationVariables = Exact<{
  input?: InputMaybe<ResetPasswordInput>;
}>;

export type ResetPasswordMutation = {
  __typename: 'RootMutationType';
  resetPassword?:
    | {
        __typename: 'ResetPasswordPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type SendChangeEmailAddressEmailMutationVariables = Exact<{
  input?: InputMaybe<SendChangeEmailAddressEmailInput>;
}>;

export type SendChangeEmailAddressEmailMutation = {
  __typename: 'RootMutationType';
  sendChangeEmailAddressEmail?:
    | {
        __typename: 'SendChangeEmailAddressEmailPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  input?: InputMaybe<SendResetPasswordEmailInput>;
}>;

export type SendResetPasswordEmailMutation = {
  __typename: 'RootMutationType';
  sendResetPasswordEmail?:
    | {
        __typename: 'SendResetPasswordEmailPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type SetCancelationReasonMutationVariables = Exact<{
  input?: InputMaybe<SetCancelationReasonInput>;
}>;

export type SetCancelationReasonMutation = {
  __typename: 'RootMutationType';
  setCancelationReason?:
    | {
        __typename: 'SetCancelationReasonPayload';
        result?: boolean | undefined;
        errors?:
          | Array<{ __typename: 'BasicError'; message: string } | { __typename: 'ValidationError'; message: string }>
          | undefined;
      }
    | undefined;
};

export type SubscribeDgNewsletterMutationVariables = Exact<{
  input?: InputMaybe<SubscribeDgNewsletterInput>;
}>;

export type SubscribeDgNewsletterMutation = {
  __typename: 'RootMutationType';
  subscribeDgNewsletter?:
    | {
        __typename: 'SubscribeDgNewsletterPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type SubscribeStagePlusEmailNotificationMutationVariables = Exact<{ [key: string]: never }>;

export type SubscribeStagePlusEmailNotificationMutation = {
  __typename: 'RootMutationType';
  subscribeStagePlusEmailNotification?:
    | {
        __typename: 'SubscribeStagePlusEmailNotificationPayload';
        result?: boolean | undefined;
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type SubscribeViaStripeMutationVariables = Exact<{
  input?: InputMaybe<SubscribeViaStripeInput>;
}>;

export type SubscribeViaStripeMutation = {
  __typename: 'RootMutationType';
  subscribeViaStripeV2?:
    | {
        __typename: 'SubscribeViaStripeV2Payload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
        result?:
          | {
              __typename: 'StripeSubscription';
              coupon?:
                | {
                    __typename: 'Coupon';
                    durationInMonths?: number | undefined;
                    name: string;
                    percentOff: number;
                    promotionCode: string;
                  }
                | undefined;
              invoice: {
                __typename: 'StripeInvoiceCompact';
                billedAt: string;
                currency: string;
                discount?: number | undefined;
                subtotal: number;
                total: number;
              };
              stripeClientSecret:
                | { __typename: 'PaymentIntentClientSecret'; clientSecret: string }
                | { __typename: 'SetupIntentClientSecret'; clientSecret: string };
              timeLimitedDiscountInfo?:
                | {
                    __typename: 'StripeTimeLimitedDiscountInfo';
                    amount: number;
                    couponEndsAt: string;
                    durationInMonths: number;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type UpdateShippingAddressMutationVariables = Exact<{
  input?: InputMaybe<UpdateShippingAddressInput>;
}>;

export type UpdateShippingAddressMutation = {
  __typename: 'RootMutationType';
  updateShippingAddress?:
    | {
        __typename: 'UpdateShippingAddressPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type UpdateUserMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserInput>;
}>;

export type UpdateUserMutation = {
  __typename: 'RootMutationType';
  updateUser?:
    | {
        __typename: 'UpdateUserPayload';
        errors?:
          | Array<
              | { __typename: 'BasicError'; code: string; message: string }
              | { __typename: 'ValidationError'; message: string; validation: string; field: string }
            >
          | undefined;
      }
    | undefined;
};

export type MyStageQueryVariables = Exact<{
  favoriteType: FavoriteType;
  playbackProgressType: PlaybackProgressType;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MyStageQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        favorites?:
          | {
              __typename: 'FavoriteConnection';
              edges: Array<{
                __typename: 'FavoriteEdge';
                node: {
                  __typename: 'Favorite';
                  id: string;
                  content:
                    | {
                        __typename: 'Album';
                        artistAndGroupDisplayInfo?: string | undefined;
                        id: string;
                        title: string;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      }
                    | {
                        __typename: 'PerformanceWork';
                        id: string;
                        duration: number;
                        vodConcert: {
                          __typename: 'VodConcert';
                          id: string;
                          title: string;
                          totalDuration: number;
                          typeDisplayName: string;
                          publicationLevel: PublicationLevel;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        work: { __typename: 'Work'; title: string };
                      }
                    | {
                        __typename: 'Video';
                        id: string;
                        archiveReleaseDate?: string | undefined;
                        title: string;
                        subtitle?: string | undefined;
                        duration: number;
                        typeDisplayName: string;
                        geoAccessCountries: Array<string>;
                        geoAccessMode: GeoAccessMode;
                        publicationLevel: PublicationLevel;
                        takedownDate?: string | undefined;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                        trailerStream?:
                          | {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            }
                          | undefined;
                      };
                };
              }>;
            }
          | undefined;
        recentlyPlayed?:
          | {
              __typename: 'PlaybackProgressConnection';
              edges: Array<{
                __typename: 'PlaybackProgressEdge';
                node: {
                  __typename: 'PlaybackProgress';
                  id: string;
                  mark: number;
                  content:
                    | {
                        __typename: 'PerformanceWork';
                        id: string;
                        duration: number;
                        vodConcert: {
                          __typename: 'VodConcert';
                          id: string;
                          title: string;
                          totalDuration: number;
                          typeDisplayName: string;
                          publicationLevel: PublicationLevel;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        work: { __typename: 'Work'; title: string };
                      }
                    | {
                        __typename: 'Track';
                        id: string;
                        artistAndGroupDisplayInfo?: string | undefined;
                        composerDisplayInfo?: string | undefined;
                        duration: number;
                        title: string;
                        trackNumber: number;
                        album: {
                          __typename: 'Album';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                      }
                    | {
                        __typename: 'Video';
                        id: string;
                        archiveReleaseDate?: string | undefined;
                        title: string;
                        subtitle?: string | undefined;
                        duration: number;
                        typeDisplayName: string;
                        geoAccessCountries: Array<string>;
                        geoAccessMode: GeoAccessMode;
                        publicationLevel: PublicationLevel;
                        takedownDate?: string | undefined;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                        trailerStream?:
                          | {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            }
                          | undefined;
                      };
                };
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type MyStageFavoritesQueryVariables = Exact<{
  favoriteType: FavoriteType;
  first: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyStageFavoritesQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        favorites?:
          | {
              __typename: 'FavoriteConnection';
              pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: string | undefined };
              edges: Array<{
                __typename: 'FavoriteEdge';
                node: {
                  __typename: 'Favorite';
                  id: string;
                  content:
                    | {
                        __typename: 'Album';
                        artistAndGroupDisplayInfo?: string | undefined;
                        id: string;
                        title: string;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      }
                    | {
                        __typename: 'PerformanceWork';
                        id: string;
                        duration: number;
                        vodConcert: {
                          __typename: 'VodConcert';
                          id: string;
                          title: string;
                          totalDuration: number;
                          typeDisplayName: string;
                          publicationLevel: PublicationLevel;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        work: { __typename: 'Work'; title: string };
                      }
                    | {
                        __typename: 'Video';
                        id: string;
                        archiveReleaseDate?: string | undefined;
                        title: string;
                        subtitle?: string | undefined;
                        duration: number;
                        typeDisplayName: string;
                        geoAccessCountries: Array<string>;
                        geoAccessMode: GeoAccessMode;
                        publicationLevel: PublicationLevel;
                        takedownDate?: string | undefined;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                        trailerStream?:
                          | {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            }
                          | undefined;
                      };
                };
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type MyStageRecentlyPlayedQueryVariables = Exact<{
  playbackProgressType: PlaybackProgressType;
  first: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyStageRecentlyPlayedQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        recentlyPlayed?:
          | {
              __typename: 'PlaybackProgressConnection';
              pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: string | undefined };
              edges: Array<{
                __typename: 'PlaybackProgressEdge';
                node: {
                  __typename: 'PlaybackProgress';
                  id: string;
                  mark: number;
                  content:
                    | {
                        __typename: 'PerformanceWork';
                        id: string;
                        duration: number;
                        vodConcert: {
                          __typename: 'VodConcert';
                          id: string;
                          title: string;
                          totalDuration: number;
                          typeDisplayName: string;
                          publicationLevel: PublicationLevel;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        work: { __typename: 'Work'; title: string };
                      }
                    | {
                        __typename: 'Track';
                        id: string;
                        artistAndGroupDisplayInfo?: string | undefined;
                        composerDisplayInfo?: string | undefined;
                        duration: number;
                        title: string;
                        trackNumber: number;
                        album: {
                          __typename: 'Album';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        };
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                      }
                    | {
                        __typename: 'Video';
                        id: string;
                        archiveReleaseDate?: string | undefined;
                        title: string;
                        subtitle?: string | undefined;
                        duration: number;
                        typeDisplayName: string;
                        geoAccessCountries: Array<string>;
                        geoAccessMode: GeoAccessMode;
                        publicationLevel: PublicationLevel;
                        takedownDate?: string | undefined;
                        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        stream: {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                        trailerStream?:
                          | {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            }
                          | undefined;
                      };
                };
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type PartnerPageQueryVariables = Exact<{
  partnerId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PartnerPageQuery = {
  __typename: 'RootQueryType';
  partner?:
    | {
        __typename: 'Partner';
        id: string;
        name: string;
        description?: string | undefined;
        typeDisplayName: string;
        subtypeDisplayName?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
      }
    | undefined;
};

export type PaymentPageQueryVariables = Exact<{
  couponCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type PaymentPageQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        id: string;
        ticket?:
          | {
              __typename: 'Ticket';
              status: TicketStatus;
              validUntil?: string | undefined;
              id: string;
              isTrial: boolean;
              ticketType: TicketType;
            }
          | undefined;
      }
    | undefined;
  subscriptionPlansStripe: {
    __typename: 'SubscriptionPlansStripe';
    coupon?:
      | {
          __typename: 'Coupon';
          durationInMonths?: number | undefined;
          name: string;
          percentOff: number;
          productIdsLimitedTo: Array<string>;
          promotionCode: string;
        }
      | undefined;
    plans: Array<{
      __typename: 'SubscriptionPlanStripe';
      interval: SubscriptionPlanInterval;
      isPlanChangeSupported: boolean;
      isPurchasePossible: boolean;
      productId: string;
      requiresPaymentMethod: boolean;
      ticketType: TicketType;
      trialPeriodDays: number;
      prices: Array<{ __typename: 'Price'; id: string; currency: string; amount: number; amountUndiscounted: number }>;
    }>;
  };
};

export type PlaybackProgressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PlaybackProgressQuery = {
  __typename: 'RootQueryType';
  playbackProgressByContentId?: { __typename: 'PlaybackProgress'; mark: number } | undefined;
};

export type PlayerContentQueryVariables = Exact<{
  contentId: Scalars['ID']['input'];
}>;

export type PlayerContentQuery = {
  __typename: 'RootQueryType';
  node?:
    | {
        __typename: 'Album';
        upc: string;
        atmosUpc?: string | undefined;
        totalDuration: number;
        releaseDate?: string | undefined;
        artistAndGroupDisplayInfo?: string | undefined;
        id: string;
        title: string;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        trackSets: Array<{
          __typename: 'TrackSet';
          composerDisplayInfo?: string | undefined;
          title?: string | undefined;
          trackSetNumber: number;
          tracks: Array<{
            __typename: 'Track';
            id: string;
            artistAndGroupDisplayInfo?: string | undefined;
            composerDisplayInfo?: string | undefined;
            duration: number;
            title: string;
            trackNumber: number;
            album: { __typename: 'Album'; id: string };
            stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
          }>;
        }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'Artist' }
    | { __typename: 'Epoch' }
    | { __typename: 'Favorite' }
    | { __typename: 'Genre' }
    | { __typename: 'Group' }
    | { __typename: 'Label' }
    | {
        __typename: 'LiveConcert';
        shortDescription?: string | undefined;
        isAtmos: boolean;
        endTime: string;
        finalEndTime: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        id: string;
        plannedForVod: boolean;
        publicationLevel: PublicationLevel;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        type: LiveConcertType;
        typeDisplayName: string;
        liveConcertType: LiveConcertType;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        performanceWorks: Array<{
          __typename: 'LivePerformanceWork';
          id: string;
          artists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          soloists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          groups?:
            | {
                __typename: 'GroupConnection';
                edges: Array<{
                  __typename: 'GroupEdge';
                  node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
                }>;
              }
            | undefined;
          work: {
            __typename: 'Work';
            id: string;
            title: string;
            movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
            composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
            genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
          };
        }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | { __typename: 'LivePerformanceWork' }
    | { __typename: 'Movement' }
    | { __typename: 'Partner' }
    | { __typename: 'PerformanceWork' }
    | { __typename: 'Picture' }
    | { __typename: 'PlaybackProgress' }
    | { __typename: 'Role' }
    | { __typename: 'Slider' }
    | { __typename: 'Track' }
    | {
        __typename: 'Video';
        isrc?: string | undefined;
        trailerIsrc?: string | undefined;
        description?: string | undefined;
        isAtmos: boolean;
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        videoType: VideoType;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'VodConcert';
        isrc?: string | undefined;
        trailerIsrc?: string | undefined;
        shortDescription?: string | undefined;
        isAtmos: boolean;
        id: string;
        archiveReleaseDate?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        typeDisplayName: string;
        totalDuration: number;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        takedownDate?: string | undefined;
        vodConcertType: VodConcertType;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        performanceWorks: Array<{
          __typename: 'PerformanceWork';
          isrc: string;
          id: string;
          duration: number;
          artists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          soloists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          groups?:
            | {
                __typename: 'GroupConnection';
                edges: Array<{
                  __typename: 'GroupEdge';
                  node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
                }>;
              }
            | undefined;
          work: {
            __typename: 'Work';
            id: string;
            title: string;
            movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
            composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
            genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
          };
          stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
          vodConcert: { __typename: 'VodConcert'; id: string };
          cuePoints: Array<{ __typename: 'CuePoint'; mark: number; title: string }>;
        }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | { __typename: 'Work' }
    | undefined;
};

export type PreviewSubscriptionPlanStripeQueryVariables = Exact<{
  productId: Scalars['String']['input'];
}>;

export type PreviewSubscriptionPlanStripeQuery = {
  __typename: 'RootQueryType';
  previewSubscriptionPlanStripe: {
    __typename: 'SubscriptionPlanPreviewStripe';
    billedNextAt: string;
    currency: string;
    prorationDate: number;
    subscriptionStartsAt: string;
    total: number;
    previewPlan: { __typename: 'SubscriptionPlanPriceStripe'; interval: SubscriptionPlanInterval; productId: string };
    paymentMethod?:
      | { __typename: 'StripePaymentMethodCard'; brand: string; last4: string; expMonth: number; expYear: number }
      | { __typename: 'StripePaymentMethodPaypal'; payerEmail: string }
      | undefined;
  };
};

export type ProvincesQueryVariables = Exact<{
  countryCode: Scalars['String']['input'];
}>;

export type ProvincesQuery = {
  __typename: 'RootQueryType';
  provinces: Array<{ __typename: 'Province'; code: string; displayName: string }>;
};

export type ReminderModalQueryVariables = Exact<{
  liveConcertId: Scalars['ID']['input'];
}>;

export type ReminderModalQuery = {
  __typename: 'RootQueryType';
  liveConcert?:
    | {
        __typename: 'LiveConcert';
        endTime: string;
        finalEndTime: string;
        id: string;
        seriesTitle?: string | undefined;
        shortDescription?: string | undefined;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        type: LiveConcertType;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
      }
    | undefined;
};

export type SearchDetailPageQueryVariables = Exact<{
  nodeId: Scalars['ID']['input'];
}>;

export type SearchDetailPageQuery = {
  __typename: 'RootQueryType';
  node?:
    | { __typename: 'Album' }
    | {
        __typename: 'Artist';
        id: string;
        name: string;
        roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'Epoch' }
    | { __typename: 'Favorite' }
    | { __typename: 'Genre' }
    | {
        __typename: 'Group';
        id: string;
        name: string;
        typeDisplayName: string;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'Label' }
    | { __typename: 'LiveConcert' }
    | { __typename: 'LivePerformanceWork' }
    | { __typename: 'Movement' }
    | {
        __typename: 'Partner';
        id: string;
        name: string;
        typeDisplayName: string;
        subtypeDisplayName?: string | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
      }
    | { __typename: 'PerformanceWork' }
    | { __typename: 'Picture' }
    | { __typename: 'PlaybackProgress' }
    | { __typename: 'Role' }
    | { __typename: 'Slider' }
    | { __typename: 'Track' }
    | { __typename: 'Video' }
    | { __typename: 'VodConcert' }
    | { __typename: 'Work' }
    | undefined;
};

export type SearchFacetPageQueryVariables = Exact<{
  facetType: FacetType;
}>;

export type SearchFacetPageQuery = {
  __typename: 'RootQueryType';
  searchByFacetType?:
    | {
        __typename: 'SearchByFacetTypeResult';
        sections: Array<{
          __typename: 'SearchByFacetTypeSection';
          glyph: string;
          items: Array<{ __typename: 'SearchGlyphItem'; id: string; count: number; label: string }>;
        }>;
      }
    | undefined;
};

export type SessionQueryVariables = Exact<{ [key: string]: never }>;

export type SessionQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        id: string;
        email: string;
        emailVerified: boolean;
        firstName: string;
        lastName: string;
        insertedAt: string;
        hasTrialedBefore: boolean;
        ticket?:
          | {
              __typename: 'Ticket';
              id: string;
              interval: SubscriptionPlanInterval;
              isTrial: boolean;
              status: TicketStatus;
              validUntil?: string | undefined;
              paymentProvider: PaymentProvider;
              paymentMethodType?: string | undefined;
              ticketType: TicketType;
            }
          | undefined;
        trackingMetadata: { __typename: 'TrackingMetadata'; userType?: string | undefined };
      }
    | undefined;
};

export type ShareModalQueryVariables = Exact<{
  nodeId: Scalars['ID']['input'];
}>;

export type ShareModalQuery = {
  __typename: 'RootQueryType';
  node?:
    | { __typename: 'Album'; id: string; title: string; artistAndGroupDisplayInfo?: string | undefined }
    | { __typename: 'Artist'; id: string; name: string }
    | { __typename: 'Epoch'; id: string; title: string }
    | { __typename: 'Favorite' }
    | { __typename: 'Genre'; id: string; title: string }
    | { __typename: 'Group'; id: string; name: string }
    | { __typename: 'Label' }
    | { __typename: 'LiveConcert'; id: string; title: string; shortDescription?: string | undefined }
    | { __typename: 'LivePerformanceWork' }
    | { __typename: 'Movement' }
    | { __typename: 'Partner'; id: string; name: string }
    | { __typename: 'PerformanceWork'; id: string; work: { __typename: 'Work'; title: string } }
    | { __typename: 'Picture' }
    | { __typename: 'PlaybackProgress' }
    | { __typename: 'Role' }
    | { __typename: 'Slider' }
    | { __typename: 'Track'; id: string; title: string }
    | { __typename: 'Video'; id: string; title: string; description?: string | undefined }
    | { __typename: 'VodConcert'; id: string; title: string; shortDescription?: string | undefined }
    | { __typename: 'Work' }
    | undefined;
};

export type SliderPageQueryVariables = Exact<{
  sliderId: Scalars['ID']['input'];
  sliderPageSize: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type SliderPageQuery = {
  __typename: 'RootQueryType';
  slider?:
    | {
        __typename: 'Slider';
        id: string;
        headline: string;
        sliderItems?:
          | {
              __typename: 'SliderItemConnection';
              totalResults: number;
              publicationLevel?: PublicationLevel | undefined;
              pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: string | undefined };
              edges: Array<{
                __typename: 'SliderItemEdge';
                node:
                  | {
                      __typename: 'Album';
                      artistAndGroupDisplayInfo?: string | undefined;
                      id: string;
                      title: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'Artist';
                      id: string;
                      name: string;
                      roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'Epoch';
                      id: string;
                      endYear?: number | undefined;
                      startYear?: number | undefined;
                      title: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'Favorite';
                      id: string;
                      content:
                        | {
                            __typename: 'Album';
                            artistAndGroupDisplayInfo?: string | undefined;
                            id: string;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          }
                        | {
                            __typename: 'PerformanceWork';
                            id: string;
                            duration: number;
                            vodConcert: {
                              __typename: 'VodConcert';
                              id: string;
                              title: string;
                              totalDuration: number;
                              typeDisplayName: string;
                              publicationLevel: PublicationLevel;
                              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                            };
                            work: { __typename: 'Work'; title: string };
                          }
                        | {
                            __typename: 'Video';
                            id: string;
                            archiveReleaseDate?: string | undefined;
                            title: string;
                            subtitle?: string | undefined;
                            duration: number;
                            typeDisplayName: string;
                            geoAccessCountries: Array<string>;
                            geoAccessMode: GeoAccessMode;
                            publicationLevel: PublicationLevel;
                            takedownDate?: string | undefined;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                            stream: {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            };
                            trailerStream?:
                              | {
                                  __typename: 'Stream';
                                  publicationLevel: PublicationLevel;
                                  streamType: StreamType;
                                  url: string;
                                }
                              | undefined;
                          };
                    }
                  | {
                      __typename: 'Genre';
                      id: string;
                      title: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'Group';
                      id: string;
                      name: string;
                      typeDisplayName: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'LiveConcert';
                      endTime: string;
                      finalEndTime: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      id: string;
                      plannedForVod: boolean;
                      publicationLevel: PublicationLevel;
                      startTime: string;
                      streamStartTime?: string | undefined;
                      subtitle?: string | undefined;
                      title: string;
                      type: LiveConcertType;
                      typeDisplayName: string;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      reruns: Array<{
                        __typename: 'LiveConcertRerun';
                        id: string;
                        streamStartTime?: string | undefined;
                        endTime: string;
                        startTime: string;
                        stream: {
                          __typename: 'RerunStream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        };
                      }>;
                      stream: {
                        __typename: 'LiveStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    }
                  | {
                      __typename: 'Partner';
                      id: string;
                      name: string;
                      typeDisplayName: string;
                      subtypeDisplayName?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    }
                  | {
                      __typename: 'PlaybackProgress';
                      id: string;
                      mark: number;
                      content:
                        | {
                            __typename: 'PerformanceWork';
                            id: string;
                            duration: number;
                            vodConcert: {
                              __typename: 'VodConcert';
                              id: string;
                              title: string;
                              totalDuration: number;
                              typeDisplayName: string;
                              publicationLevel: PublicationLevel;
                              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                            };
                            work: { __typename: 'Work'; title: string };
                          }
                        | {
                            __typename: 'Track';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            composerDisplayInfo?: string | undefined;
                            duration: number;
                            title: string;
                            trackNumber: number;
                            album: {
                              __typename: 'Album';
                              id: string;
                              artistAndGroupDisplayInfo?: string | undefined;
                              title: string;
                              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                            };
                            stream: {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            };
                          }
                        | {
                            __typename: 'Video';
                            id: string;
                            archiveReleaseDate?: string | undefined;
                            title: string;
                            subtitle?: string | undefined;
                            duration: number;
                            typeDisplayName: string;
                            geoAccessCountries: Array<string>;
                            geoAccessMode: GeoAccessMode;
                            publicationLevel: PublicationLevel;
                            takedownDate?: string | undefined;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                            stream: {
                              __typename: 'Stream';
                              publicationLevel: PublicationLevel;
                              streamType: StreamType;
                              url: string;
                            };
                            trailerStream?:
                              | {
                                  __typename: 'Stream';
                                  publicationLevel: PublicationLevel;
                                  streamType: StreamType;
                                  url: string;
                                }
                              | undefined;
                          };
                    }
                  | {
                      __typename: 'Video';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      title: string;
                      subtitle?: string | undefined;
                      duration: number;
                      typeDisplayName: string;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      stream: {
                        __typename: 'Stream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    }
                  | {
                      __typename: 'VodConcert';
                      id: string;
                      archiveReleaseDate?: string | undefined;
                      subtitle?: string | undefined;
                      title: string;
                      typeDisplayName: string;
                      totalDuration: number;
                      geoAccessCountries: Array<string>;
                      geoAccessMode: GeoAccessMode;
                      publicationLevel: PublicationLevel;
                      takedownDate?: string | undefined;
                      pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                      trailerStream?:
                        | {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          }
                        | undefined;
                    };
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type SubscriptionPlansQueryVariables = Exact<{
  couponCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscriptionPlansQuery = {
  __typename: 'RootQueryType';
  subscriptionPlansStripe: {
    __typename: 'SubscriptionPlansStripe';
    plans: Array<{
      __typename: 'SubscriptionPlanStripe';
      interval: SubscriptionPlanInterval;
      isPlanChangeSupported: boolean;
      isPurchasePossible: boolean;
      productId: string;
      requiresPaymentMethod: boolean;
      ticketType: TicketType;
      trialPeriodDays: number;
      prices: Array<{ __typename: 'Price'; id: string; currency: string; amount: number; amountUndiscounted: number }>;
    }>;
  };
};

export type SubscriptionSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionSettingsQuery = {
  __typename: 'RootQueryType';
  currentUser?:
    | {
        __typename: 'User';
        hasTrialedBefore: boolean;
        stripeInvoices: Array<{
          __typename: 'StripeInvoice';
          billedAt: string;
          currency: string;
          description: string;
          number: string;
          pdfUrl: string;
          total: number;
        }>;
        ticket?:
          | {
              __typename: 'Ticket';
              interval: SubscriptionPlanInterval;
              paymentProvider: PaymentProvider;
              status: TicketStatus;
              validUntil?: string | undefined;
              ticketType: TicketType;
              stripeBillingInfo?:
                | {
                    __typename: 'StripeBillingInfo';
                    amount: string;
                    billedFirstAt?: string | undefined;
                    billedNextAt?: string | undefined;
                    currency: string;
                    paymentMethod?:
                      | {
                          __typename: 'StripePaymentMethodCard';
                          brand: string;
                          last4: string;
                          expMonth: number;
                          expYear: number;
                        }
                      | { __typename: 'StripePaymentMethodPaypal'; payerEmail: string }
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type TicketsPageQueryVariables = Exact<{ [key: string]: never }>;

export type TicketsPageQuery = {
  __typename: 'RootQueryType';
  subscriptionPlansStripe: {
    __typename: 'SubscriptionPlansStripe';
    plans: Array<{
      __typename: 'SubscriptionPlanStripe';
      interval: SubscriptionPlanInterval;
      isPlanChangeSupported: boolean;
      isPurchasePossible: boolean;
      productId: string;
      requiresPaymentMethod: boolean;
      ticketType: TicketType;
      trialPeriodDays: number;
      prices: Array<{ __typename: 'Price'; id: string; currency: string; amount: number; amountUndiscounted: number }>;
    }>;
  };
};

export type VideoDetailPageQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
  sliderItemsFirst?: InputMaybe<Scalars['Int']['input']>;
}>;

export type VideoDetailPageQuery = {
  __typename: 'RootQueryType';
  node?:
    | { __typename: 'Album' }
    | { __typename: 'Artist' }
    | { __typename: 'Epoch' }
    | { __typename: 'Favorite' }
    | { __typename: 'Genre' }
    | { __typename: 'Group' }
    | { __typename: 'Label' }
    | {
        __typename: 'LiveConcert';
        isAtmos: boolean;
        maxResolution: MaxResolution;
        shortDescription?: string | undefined;
        totalDuration: number;
        endTime: string;
        finalEndTime: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        id: string;
        plannedForVod: boolean;
        publicationLevel: PublicationLevel;
        startTime: string;
        streamStartTime?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        type: LiveConcertType;
        typeDisplayName: string;
        liveConcertType: LiveConcertType;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        performanceWorks: Array<{
          __typename: 'LivePerformanceWork';
          id: string;
          artists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          soloists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          groups?:
            | {
                __typename: 'GroupConnection';
                edges: Array<{
                  __typename: 'GroupEdge';
                  node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
                }>;
              }
            | undefined;
          work: {
            __typename: 'Work';
            id: string;
            title: string;
            movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
            composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
            genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
          };
        }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
        vodConcert?: { __typename: 'VodConcert'; id: string } | undefined;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        reruns: Array<{
          __typename: 'LiveConcertRerun';
          id: string;
          streamStartTime?: string | undefined;
          endTime: string;
          startTime: string;
          stream: {
            __typename: 'RerunStream';
            publicationLevel: PublicationLevel;
            streamType: StreamType;
            url: string;
          };
        }>;
        stream: { __typename: 'LiveStream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | { __typename: 'LivePerformanceWork' }
    | { __typename: 'Movement' }
    | { __typename: 'Partner' }
    | { __typename: 'PerformanceWork' }
    | { __typename: 'Picture' }
    | { __typename: 'PlaybackProgress' }
    | { __typename: 'Role' }
    | { __typename: 'Slider' }
    | { __typename: 'Track' }
    | {
        __typename: 'Video';
        isAtmos: boolean;
        maxResolution: MaxResolution;
        isLossless: boolean;
        description?: string | undefined;
        productionDate?: string | undefined;
        takedownDate?: string | undefined;
        uploadDate: string;
        id: string;
        archiveReleaseDate?: string | undefined;
        title: string;
        subtitle?: string | undefined;
        duration: number;
        typeDisplayName: string;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        videoType: VideoType;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | {
        __typename: 'VodConcert';
        isAtmos: boolean;
        maxResolution: MaxResolution;
        shortDescription?: string | undefined;
        productionDate?: string | undefined;
        takedownDate?: string | undefined;
        uploadDate: string;
        id: string;
        archiveReleaseDate?: string | undefined;
        subtitle?: string | undefined;
        title: string;
        typeDisplayName: string;
        totalDuration: number;
        geoAccessCountries: Array<string>;
        geoAccessMode: GeoAccessMode;
        publicationLevel: PublicationLevel;
        vodConcertType: VodConcertType;
        conductors?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        soloists?:
          | {
              __typename: 'ArtistWithRoleConnection';
              edges: Array<{
                __typename: 'ArtistWithRoleEdge';
                role: { __typename: 'Role'; displayName: string };
                node: { __typename: 'Artist'; id: string; name: string };
              }>;
            }
          | undefined;
        groups: Array<{ __typename: 'Group'; id: string; name: string; typeDisplayName: string }>;
        performanceWorks: Array<{
          __typename: 'PerformanceWork';
          id: string;
          duration: number;
          artists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          soloists?:
            | {
                __typename: 'ArtistWithRoleConnection';
                edges: Array<{
                  __typename: 'ArtistWithRoleEdge';
                  role: { __typename: 'Role'; displayName: string };
                  node: { __typename: 'Artist'; id: string; name: string };
                }>;
              }
            | undefined;
          groups?:
            | {
                __typename: 'GroupConnection';
                edges: Array<{
                  __typename: 'GroupEdge';
                  node: { __typename: 'Group'; id: string; name: string; typeDisplayName: string };
                }>;
              }
            | undefined;
          work: {
            __typename: 'Work';
            id: string;
            title: string;
            movements: Array<{ __typename: 'Movement'; id: string; title: string }>;
            composers: Array<{ __typename: 'Artist'; id: string; name: string }>;
            genre?: { __typename: 'Genre'; id: string; title: string } | undefined;
          };
          stream: { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string };
          vodConcert: { __typename: 'VodConcert'; id: string };
          cuePoints: Array<{ __typename: 'CuePoint'; mark: number; title: string }>;
        }>;
        curatedContent: Array<
          | {
              __typename: 'Banner';
              description: string;
              link: string;
              title: string;
              pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
            }
          | {
              __typename: 'ListSlider';
              id: string;
              headline: string;
              items: Array<
                | {
                    __typename: 'Album';
                    artistAndGroupDisplayInfo?: string | undefined;
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Artist';
                    id: string;
                    name: string;
                    roles: Array<{ __typename: 'Role'; id: string; type: RoleType; displayName: string }>;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Epoch';
                    id: string;
                    endYear?: number | undefined;
                    startYear?: number | undefined;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Favorite';
                    id: string;
                    content:
                      | {
                          __typename: 'Album';
                          artistAndGroupDisplayInfo?: string | undefined;
                          id: string;
                          title: string;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                        }
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Genre';
                    id: string;
                    title: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'Group';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'LiveConcert';
                    endTime: string;
                    finalEndTime: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    id: string;
                    plannedForVod: boolean;
                    publicationLevel: PublicationLevel;
                    startTime: string;
                    streamStartTime?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    type: LiveConcertType;
                    typeDisplayName: string;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    reruns: Array<{
                      __typename: 'LiveConcertRerun';
                      id: string;
                      streamStartTime?: string | undefined;
                      endTime: string;
                      startTime: string;
                      stream: {
                        __typename: 'RerunStream';
                        publicationLevel: PublicationLevel;
                        streamType: StreamType;
                        url: string;
                      };
                    }>;
                    stream: {
                      __typename: 'LiveStream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'Partner';
                    id: string;
                    name: string;
                    typeDisplayName: string;
                    subtypeDisplayName?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                  }
                | {
                    __typename: 'PlaybackProgress';
                    id: string;
                    mark: number;
                    content:
                      | {
                          __typename: 'PerformanceWork';
                          id: string;
                          duration: number;
                          vodConcert: {
                            __typename: 'VodConcert';
                            id: string;
                            title: string;
                            totalDuration: number;
                            typeDisplayName: string;
                            publicationLevel: PublicationLevel;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          work: { __typename: 'Work'; title: string };
                        }
                      | {
                          __typename: 'Track';
                          id: string;
                          artistAndGroupDisplayInfo?: string | undefined;
                          composerDisplayInfo?: string | undefined;
                          duration: number;
                          title: string;
                          trackNumber: number;
                          album: {
                            __typename: 'Album';
                            id: string;
                            artistAndGroupDisplayInfo?: string | undefined;
                            title: string;
                            pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          };
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                        }
                      | {
                          __typename: 'Video';
                          id: string;
                          archiveReleaseDate?: string | undefined;
                          title: string;
                          subtitle?: string | undefined;
                          duration: number;
                          typeDisplayName: string;
                          geoAccessCountries: Array<string>;
                          geoAccessMode: GeoAccessMode;
                          publicationLevel: PublicationLevel;
                          takedownDate?: string | undefined;
                          pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                          stream: {
                            __typename: 'Stream';
                            publicationLevel: PublicationLevel;
                            streamType: StreamType;
                            url: string;
                          };
                          trailerStream?:
                            | {
                                __typename: 'Stream';
                                publicationLevel: PublicationLevel;
                                streamType: StreamType;
                                url: string;
                              }
                            | undefined;
                        };
                  }
                | {
                    __typename: 'Video';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    title: string;
                    subtitle?: string | undefined;
                    duration: number;
                    typeDisplayName: string;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    stream: {
                      __typename: 'Stream';
                      publicationLevel: PublicationLevel;
                      streamType: StreamType;
                      url: string;
                    };
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
                | {
                    __typename: 'VodConcert';
                    id: string;
                    archiveReleaseDate?: string | undefined;
                    subtitle?: string | undefined;
                    title: string;
                    typeDisplayName: string;
                    totalDuration: number;
                    geoAccessCountries: Array<string>;
                    geoAccessMode: GeoAccessMode;
                    publicationLevel: PublicationLevel;
                    takedownDate?: string | undefined;
                    pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
                    trailerStream?:
                      | {
                          __typename: 'Stream';
                          publicationLevel: PublicationLevel;
                          streamType: StreamType;
                          url: string;
                        }
                      | undefined;
                  }
              >;
            }
        >;
        pictures: Array<{ __typename: 'Picture'; id: string; url: string; type: PictureType }>;
        trailerStream?:
          | { __typename: 'Stream'; publicationLevel: PublicationLevel; streamType: StreamType; url: string }
          | undefined;
      }
    | { __typename: 'Work' }
    | undefined;
};

export const StreamFieldsFragmentDoc = gql`
  fragment streamFields on Stream {
    publicationLevel
    streamType
    url
  }
`;
export const TrackFieldsFragmentDoc = gql`
  fragment trackFields on Track {
    __typename
    id
    artistAndGroupDisplayInfo
    composerDisplayInfo
    duration
    title
    trackNumber
    duration
    album {
      id
    }
    stream {
      ...streamFields
    }
  }
  ${StreamFieldsFragmentDoc}
`;
export const TrackSetFieldsFragmentDoc = gql`
  fragment TrackSetFields on TrackSet {
    composerDisplayInfo
    title
    trackSetNumber
    tracks {
      ...trackFields
    }
  }
  ${TrackFieldsFragmentDoc}
`;
export const ApiErrorFieldsFragmentDoc = gql`
  fragment ApiErrorFields on ApiError {
    ... on BasicError {
      code
      message
    }
    ... on ValidationError {
      message
      validation
      field
    }
  }
`;
export const BaseNodeFieldsFragmentDoc = gql`
  fragment BaseNodeFields on Node {
    __typename
    id
  }
`;
export const BaseSliderNodeFieldsFragmentDoc = gql`
  fragment baseSliderNodeFields on Slider {
    ...BaseNodeFields
    items(first: 100) {
      edges {
        node {
          ...BaseNodeFields
        }
      }
    }
  }
  ${BaseNodeFieldsFragmentDoc}
`;
export const PictureFieldsFragmentDoc = gql`
  fragment PictureFields on Picture {
    id
    url
    type
  }
`;
export const AlbumFieldsFragmentDoc = gql`
  fragment AlbumFields on Album {
    artistAndGroupDisplayInfo
    id
    pictures {
      ...PictureFields
    }
    title
  }
  ${PictureFieldsFragmentDoc}
`;
export const RoleFieldsFragmentDoc = gql`
  fragment RoleFields on Role {
    __typename
    id
    type
    displayName
  }
`;
export const ArtistFieldsFragmentDoc = gql`
  fragment ArtistFields on Artist {
    id
    name
    roles {
      ...RoleFields
    }
    pictures {
      ...PictureFields
    }
  }
  ${RoleFieldsFragmentDoc}
  ${PictureFieldsFragmentDoc}
`;
export const EpochFieldsFragmentDoc = gql`
  fragment EpochFields on Epoch {
    id
    endYear
    pictures {
      ...PictureFields
    }
    startYear
    title
  }
  ${PictureFieldsFragmentDoc}
`;
export const GenreFieldsFragmentDoc = gql`
  fragment GenreFields on Genre {
    id
    pictures {
      ...PictureFields
    }
    title
  }
  ${PictureFieldsFragmentDoc}
`;
export const GroupFieldsFragmentDoc = gql`
  fragment GroupFields on Group {
    id
    name
    typeDisplayName
    pictures {
      ...PictureFields
    }
  }
  ${PictureFieldsFragmentDoc}
`;
export const RerunStreamFieldsFragmentDoc = gql`
  fragment rerunStreamFields on RerunStream {
    publicationLevel
    streamType
    url
  }
`;
export const LiveConcertRerunFieldsFragmentDoc = gql`
  fragment liveConcertRerunFields on LiveConcertRerun {
    id
    streamStartTime
    endTime
    startTime
    stream {
      ...rerunStreamFields
    }
  }
  ${RerunStreamFieldsFragmentDoc}
`;
export const LiveStreamFieldsFragmentDoc = gql`
  fragment liveStreamFields on LiveStream {
    publicationLevel
    streamType
    url
  }
`;
export const LiveConcertFieldsFragmentDoc = gql`
  fragment LiveConcertFields on LiveConcert {
    endTime
    finalEndTime
    geoAccessCountries
    geoAccessMode
    finalEndTime
    id
    pictures {
      ...PictureFields
    }
    plannedForVod
    publicationLevel
    reruns {
      ...liveConcertRerunFields
    }
    startTime
    stream {
      ...liveStreamFields
    }
    streamStartTime
    subtitle
    title
    trailerStream {
      ...streamFields
    }
    type
    typeDisplayName
  }
  ${PictureFieldsFragmentDoc}
  ${LiveConcertRerunFieldsFragmentDoc}
  ${LiveStreamFieldsFragmentDoc}
  ${StreamFieldsFragmentDoc}
`;
export const PartnerFieldsFragmentDoc = gql`
  fragment PartnerFields on Partner {
    id
    name
    typeDisplayName
    subtypeDisplayName
    pictures {
      ...PictureFields
    }
  }
  ${PictureFieldsFragmentDoc}
`;
export const VideoFieldsFragmentDoc = gql`
  fragment VideoFields on Video {
    id
    archiveReleaseDate
    title
    subtitle
    pictures {
      ...PictureFields
    }
    stream {
      ...streamFields
    }
    trailerStream {
      ...streamFields
    }
    duration
    typeDisplayName
    duration
    geoAccessCountries
    geoAccessMode
    publicationLevel
    takedownDate
  }
  ${PictureFieldsFragmentDoc}
  ${StreamFieldsFragmentDoc}
`;
export const VodConcertFieldsFragmentDoc = gql`
  fragment VodConcertFields on VodConcert {
    id
    archiveReleaseDate
    pictures {
      ...PictureFields
    }
    subtitle
    title
    typeDisplayName
    totalDuration
    geoAccessCountries
    geoAccessMode
    trailerStream {
      ...streamFields
    }
    publicationLevel
    takedownDate
  }
  ${PictureFieldsFragmentDoc}
  ${StreamFieldsFragmentDoc}
`;
export const PerformanceWorkConcertFieldsFragmentDoc = gql`
  fragment performanceWorkConcertFields on PerformanceWork {
    id
    vodConcert {
      __typename
      id
      pictures {
        ...PictureFields
      }
      title
      totalDuration
      typeDisplayName
      publicationLevel
    }
    duration
    work {
      title
    }
  }
  ${PictureFieldsFragmentDoc}
`;
export const FavoritesFieldsFragmentDoc = gql`
  fragment favoritesFields on Favorite {
    id
    __typename
    content {
      __typename
      ... on Album {
        ...AlbumFields
      }
      ... on PerformanceWork {
        ...performanceWorkConcertFields
      }
      ... on Video {
        ...VideoFields
      }
    }
  }
  ${AlbumFieldsFragmentDoc}
  ${PerformanceWorkConcertFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
`;
export const TrackWithAlbumFieldsFragmentDoc = gql`
  fragment trackWithAlbumFields on Track {
    ...trackFields
    album {
      __typename
      ...AlbumFields
    }
  }
  ${TrackFieldsFragmentDoc}
  ${AlbumFieldsFragmentDoc}
`;
export const PlaybackProgressFieldsFragmentDoc = gql`
  fragment playbackProgressFields on PlaybackProgress {
    __typename
    id
    content {
      __typename
      ... on PerformanceWork {
        ...performanceWorkConcertFields
      }
      ... on Track {
        ...trackWithAlbumFields
      }
      ... on Video {
        ...VideoFields
      }
    }
    mark
  }
  ${PerformanceWorkConcertFieldsFragmentDoc}
  ${TrackWithAlbumFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
`;
export const ListSliderFieldsFragmentDoc = gql`
  fragment ListSliderFields on ListSlider {
    id
    headline
    items(first: $sliderItemsFirst) {
      __typename
      ...AlbumFields
      ...ArtistFields
      ...EpochFields
      ...GenreFields
      ...GroupFields
      ...LiveConcertFields
      ...PartnerFields
      ...VideoFields
      ...VodConcertFields
      ...favoritesFields
      ...playbackProgressFields
    }
  }
  ${AlbumFieldsFragmentDoc}
  ${ArtistFieldsFragmentDoc}
  ${EpochFieldsFragmentDoc}
  ${GenreFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${LiveConcertFieldsFragmentDoc}
  ${PartnerFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${VodConcertFieldsFragmentDoc}
  ${FavoritesFieldsFragmentDoc}
  ${PlaybackProgressFieldsFragmentDoc}
`;
export const BannerFieldsFragmentDoc = gql`
  fragment BannerFields on Banner {
    description
    link
    pictures {
      ...PictureFields
    }
    title
  }
  ${PictureFieldsFragmentDoc}
`;
export const CuratedContentContainerFieldsFragmentDoc = gql`
  fragment curatedContentContainerFields on CuratedContentContainerV2 {
    __typename
    ...ListSliderFields
    ...BannerFields
  }
  ${ListSliderFieldsFragmentDoc}
  ${BannerFieldsFragmentDoc}
`;
export const LabelFieldsFragmentDoc = gql`
  fragment LabelFields on Label {
    id
    title
  }
`;
export const ArtistWithRoleConnectionFieldsFragmentDoc = gql`
  fragment ArtistWithRoleConnectionFields on ArtistWithRoleConnection {
    edges {
      role {
        displayName
      }
      node {
        id
        name
      }
    }
  }
`;
export const WorkFieldsFragmentDoc = gql`
  fragment workFields on Work {
    id
    title
    movements {
      id
      title
    }
    composers {
      id
      name
    }
    genre {
      id
      title
    }
  }
`;
export const LivePerformanceWorkFieldsFragmentDoc = gql`
  fragment livePerformanceWorkFields on LivePerformanceWork {
    __typename
    id
    artists {
      ...ArtistWithRoleConnectionFields
    }
    soloists: artists(role: SOLOISTS) {
      ...ArtistWithRoleConnectionFields
    }
    groups {
      edges {
        node {
          id
          name
          typeDisplayName
        }
      }
    }
    work {
      ...workFields
    }
  }
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${WorkFieldsFragmentDoc}
`;
export const PaymentMethodFieldsFragmentDoc = gql`
  fragment PaymentMethodFields on StripePaymentMethod {
    __typename
    ... on StripePaymentMethodCard {
      brand
      last4
      expMonth
      expYear
    }
    ... on StripePaymentMethodPaypal {
      payerEmail
    }
  }
`;
export const PerformanceWorkFieldsFragmentDoc = gql`
  fragment PerformanceWorkFields on PerformanceWork {
    __typename
    id
    artists {
      ...ArtistWithRoleConnectionFields
    }
    soloists: artists(role: SOLOISTS) {
      ...ArtistWithRoleConnectionFields
    }
    groups {
      edges {
        node {
          id
          name
          typeDisplayName
        }
      }
    }
    work {
      ...workFields
    }
    stream {
      ...streamFields
    }
    vodConcert {
      __typename
      id
    }
    duration
    cuePoints {
      mark
      title
    }
  }
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${WorkFieldsFragmentDoc}
  ${StreamFieldsFragmentDoc}
`;
export const SubscriptionPlansStripeCouponFieldsFragmentDoc = gql`
  fragment subscriptionPlansStripeCouponFields on SubscriptionPlansStripe {
    coupon {
      durationInMonths
      name
      percentOff
      productIdsLimitedTo
      promotionCode
    }
  }
`;
export const SubscriptionPlansStripePlansFieldsFragmentDoc = gql`
  fragment subscriptionPlansStripePlansFields on SubscriptionPlansStripe {
    plans {
      interval
      isPlanChangeSupported
      isPurchasePossible
      prices {
        id
        currency
        amount
        amountUndiscounted
      }
      productId
      requiresPaymentMethod
      ticketType
      trialPeriodDays
    }
  }
`;
export const AccountSettingsDocument = gql`
  query accountSettings {
    currentUser {
      academicTitle
      country
      email
      firstName
      lastName
      locale
      title
      postalAddress {
        city
        country
        district
        firstLine
        secondLine
        province
        zipCode
      }
    }
  }
`;
export const ArtistCollectionPageDocument = gql`
  query artistCollectionPage($artistId: ID!, $sliderItemsFirst: Int = 24) {
    artist(id: $artistId) {
      __typename
      id
      name
      title
      biography
      dateOfBirth
      dateOfDeath
      courtesyOf
      roles {
        ...RoleFields
      }
      pictures {
        ...PictureFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${RoleFieldsFragmentDoc}
  ${PictureFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const AudioDetailPageDocument = gql`
  query audioDetailPage($albumId: ID!, $sliderItemsFirst: Int = 24) {
    album(id: $albumId) {
      __typename
      conductors: artists(role: CONDUCTORS) {
        ...ArtistWithRoleConnectionFields
      }
      soloists: artists(role: SOLOISTS) {
        ...ArtistWithRoleConnectionFields
      }
      artistAndGroupDisplayInfo
      upc
      atmosUpc
      groups {
        id
        name
        typeDisplayName
      }
      id
      title
      totalDuration
      releaseDate
      pictures {
        ...PictureFields
      }
      trackSets {
        ...TrackSetFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${PictureFieldsFragmentDoc}
  ${TrackSetFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const AudioPageDocument = gql`
  query audioPage {
    liveConcerts(first: 10) {
      edges {
        node {
          id
          title
          startTime
          endTime
          streamStartTime
          pictures {
            ...PictureFields
          }
          reruns {
            startTime
            endTime
            streamStartTime
          }
        }
      }
    }
    albums(first: 10) {
      edges {
        node {
          id
          title
          releaseDate
          pictures {
            ...PictureFields
          }
        }
      }
    }
    artists(first: 10) {
      edges {
        node {
          ...ArtistFields
        }
      }
    }
  }
  ${PictureFieldsFragmentDoc}
  ${ArtistFieldsFragmentDoc}
`;
export const CancelationReasonsDocument = gql`
  query cancelationReasons {
    cancelationReasons
  }
`;
export const CheckEmailDocument = gql`
  query checkEmail($email: String!) {
    checkRegistrationData(input: { email: $email }) {
      emailLikelyValid
    }
  }
`;
export const CountriesDocument = gql`
  query countries {
    countries {
      __typename
      iso
      displayName
    }
  }
`;
export const CouponCodeDocument = gql`
  query couponCode($couponCode: String!) {
    subscriptionPlansStripe(couponCode: $couponCode) {
      ...subscriptionPlansStripeCouponFields
    }
  }
  ${SubscriptionPlansStripeCouponFieldsFragmentDoc}
`;
export const CuratedContentDocument = gql`
  query curatedContent($slug: CuratedContentSlug!, $sliderItemsFirst: Int = 6) {
    curatedContent(slug: $slug) {
      stageSlider {
        label
        subtitle
        title
        item {
          ... on Node {
            __typename
          }
          ...AlbumFields
          ...ArtistFields
          ...GroupFields
          ...LiveConcertFields
          ...PartnerFields
          ...VideoFields
          ...VodConcertFields
        }
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${AlbumFieldsFragmentDoc}
  ${ArtistFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${LiveConcertFieldsFragmentDoc}
  ${PartnerFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${VodConcertFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const CuratedContentNodesDocument = gql`
  query curatedContentNodes {
    curatedContentDiscover: curatedContent(slug: DISCOVER) {
      contentContainers {
        ...baseSliderNodeFields
      }
    }
    curatedContentSearch: curatedContent(slug: SEARCH) {
      contentContainers {
        ...baseSliderNodeFields
      }
    }
    curatedContentAudio: curatedContent(slug: AUDIO) {
      contentContainers {
        ...baseSliderNodeFields
      }
    }
  }
  ${BaseSliderNodeFieldsFragmentDoc}
`;
export const DevicesDocument = gql`
  query devices {
    currentUser {
      devices {
        id
        deviceInfo
        updatedAt
      }
    }
  }
`;
export const EpochPageDocument = gql`
  query epochPage($epochId: ID!, $sliderItemsFirst: Int = 24) {
    epoch(id: $epochId) {
      __typename
      id
      title
      subtitle
      description
      startYear
      endYear
      pictures {
        ...PictureFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${PictureFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const FavoriteByContentIdDocument = gql`
  query favoriteByContentId($contentId: ID!) {
    favoriteByContentId(contentId: $contentId) {
      __typename
      id
    }
  }
`;
export const GenrePageDocument = gql`
  query genrePage($genreId: ID!, $sliderItemsFirst: Int = 6) {
    genre(id: $genreId) {
      __typename
      id
      title
      pictures {
        ...PictureFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${PictureFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const GeoinfoDocument = gql`
  query geoinfo {
    geoinfo {
      countryCode
      currencyCode
    }
  }
`;
export const GroupPageDocument = gql`
  query groupPage($groupId: ID!, $sliderItemsFirst: Int = 24) {
    group(id: $groupId) {
      __typename
      id
      name
      description
      footnote
      typeDisplayName
      pictures {
        ...PictureFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${PictureFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const InfoModalDocument = gql`
  query infoModal($nodeId: ID!) {
    node(id: $nodeId) {
      __typename
      __typename
      ... on Album {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        atmosUpc
        albumCopyright: copyright
        groups {
          id
          name
          typeDisplayName
        }
        id
        phonographicCopyright
        pictures {
          ...PictureFields
        }
        releaseDate
        title
        totalDuration
        upc
      }
      ... on Track {
        album {
          __typename
          copyright
          phonographicCopyright
          pictures {
            ...PictureFields
          }
          title
        }
        composerDisplayInfo
        conductorDisplayInfo
        duration
        engineerDisplayInfo
        firstReleaseYear
        groupDisplayInfo
        id
        isrc
        recordingSessionEnd
        recordingLocation
        recordingSetting
        producerDisplayInfo
        title
        trackNumber
      }
      ... on LiveConcert {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        copyright
        endTime
        groups {
          id
          name
          typeDisplayName
        }
        finalEndTime
        id
        isrc
        longFormConcertIntroduction
        partners {
          name
          type
        }
        photographer
        pictures {
          ...PictureFields
        }
        production
        reruns {
          ...liveConcertRerunFields
        }
        startTime
        streamStartTime
        stream {
          ...liveStreamFields
        }
        subtitle
        title
        totalDuration
        type
        typeDisplayName
        videoDirector
      }
      ... on Video {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        copyright
        duration
        groups {
          id
          name
          typeDisplayName
        }
        id
        isrc
        photographer
        pictures {
          ...PictureFields
        }
        production
        productionDate
        subtitle
        takedownDate
        title
        typeDisplayName
        videoDirector
      }
      ... on VodConcert {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        copyright
        courtesyOf
        groups {
          id
          name
          typeDisplayName
        }
        id
        isrc
        partners {
          name
          type
        }
        photographer
        pictures {
          ...PictureFields
        }
        production
        productionDate
        longFormConcertIntroduction
        subtitle
        takedownDate
        title
        totalDuration
        typeDisplayName
        videoDirector
      }
    }
  }
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${PictureFieldsFragmentDoc}
  ${LiveConcertRerunFieldsFragmentDoc}
  ${LiveStreamFieldsFragmentDoc}
`;
export const LandingPageDocument = gql`
  query landingPage($sliderItemsFirst: Int = 11) {
    curatedContent(slug: LANDING_PAGE) {
      curatedContent(first: $sliderItemsFirst) {
        __typename
        ...ListSliderFields
        ...BannerFields
      }
    }
    subscriptionPlansStripe {
      ...subscriptionPlansStripePlansFields
    }
  }
  ${ListSliderFieldsFragmentDoc}
  ${BannerFieldsFragmentDoc}
  ${SubscriptionPlansStripePlansFieldsFragmentDoc}
`;
export const AddFavoriteDocument = gql`
  mutation addFavorite($input: AddFavoriteInput) {
    addFavorite(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result {
        id
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const AuthorizeDeviceForUserDocument = gql`
  mutation authorizeDeviceForUser($input: AuthorizeDeviceForUserInput) {
    authorizeDeviceForUser(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result {
        id
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const CancelStripeSubscriptionDocument = gql`
  mutation cancelStripeSubscription {
    cancelStripeSubscription {
      result {
        ticketTerminatesAt
      }
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ChangePasswordDocument = gql`
  mutation changePassword($input: ChangePasswordInput) {
    changePassword(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ChangeStripePaymentMethodDocument = gql`
  mutation changeStripePaymentMethod {
    changeStripePaymentMethod {
      errors {
        ...ApiErrorFields
      }
      result {
        clientSecret
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ChangeStripeSubscriptionPlanDocument = gql`
  mutation changeStripeSubscriptionPlan($input: ChangeStripeSubscriptionPlanInput) {
    changeStripeSubscriptionPlan(input: $input) {
      result
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ConsentTrackingDocument = gql`
  mutation consentTracking($mixpanelConsent: TrackingConsentType!, $metaConsent: TrackingConsentType!) {
    consentTrackingMixpanel: consentTracking(input: { consent: $mixpanelConsent, service: MIXPANEL }) {
      errors {
        ...ApiErrorFields
      }
      result
    }
    consentTrackingMeta: consentTracking(input: { consent: $metaConsent, service: META }) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const DeleteUserDocument = gql`
  mutation deleteUser {
    deleteUser {
      errors {
        ...ApiErrorFields
      }
      result {
        email
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const GeneratePinCodeDocument = gql`
  mutation generatePinCode {
    generatePinCode {
      errors {
        ...ApiErrorFields
      }
      result {
        expiresAt
        pinCode
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ReactivateStripeSubscriptionDocument = gql`
  mutation reactivateStripeSubscription {
    reactivateStripeSubscription {
      result {
        status
        validUntil
      }
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const RecordPlaybackProgressDocument = gql`
  mutation recordPlaybackProgress($input: RecordPlaybackProgressInput) {
    recordPlaybackProgress(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result {
        id
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const RegisterUserDocument = gql`
  mutation registerUser($input: RegisterUserInput) {
    registerUser(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result {
        accessToken
        refreshToken
        user {
          locale
        }
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const RemoveDeviceDocument = gql`
  mutation removeDevice($input: RemoveDeviceInput) {
    removeDevice(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const RemoveFavoriteByContentIdDocument = gql`
  mutation removeFavoriteByContentId($input: RemoveFavoriteByContentIdInput) {
    removeFavoriteByContentId(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const RemoveFavoriteByContentTypeDocument = gql`
  mutation removeFavoriteByContentType($input: RemoveFavoriteByContentTypeInput) {
    removeFavoriteByContentType(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ResendEmailConfirmationDocument = gql`
  mutation resendEmailConfirmation {
    resendEmailConfirmation {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const SendChangeEmailAddressEmailDocument = gql`
  mutation sendChangeEmailAddressEmail($input: SendChangeEmailAddressEmailInput) {
    sendChangeEmailAddressEmail(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($input: SendResetPasswordEmailInput) {
    sendResetPasswordEmail(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const SetCancelationReasonDocument = gql`
  mutation setCancelationReason($input: SetCancelationReasonInput) {
    setCancelationReason(input: $input) {
      errors {
        message
      }
      result
    }
  }
`;
export const SubscribeDgNewsletterDocument = gql`
  mutation subscribeDgNewsletter($input: SubscribeDgNewsletterInput) {
    subscribeDgNewsletter(input: $input) {
      result
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const SubscribeStagePlusEmailNotificationDocument = gql`
  mutation subscribeStagePlusEmailNotification {
    subscribeStagePlusEmailNotification {
      result
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const SubscribeViaStripeDocument = gql`
  mutation subscribeViaStripe($input: SubscribeViaStripeInput) {
    subscribeViaStripeV2(input: $input) {
      errors {
        ...ApiErrorFields
      }
      result {
        __typename
        coupon {
          durationInMonths
          name
          percentOff
          promotionCode
        }
        invoice {
          billedAt
          currency
          discount
          subtotal
          total
        }
        stripeClientSecret {
          __typename
          ... on PaymentIntentClientSecret {
            clientSecret
          }
          ... on SetupIntentClientSecret {
            clientSecret
          }
        }
        timeLimitedDiscountInfo {
          amount
          couponEndsAt
          durationInMonths
        }
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const UpdateShippingAddressDocument = gql`
  mutation updateShippingAddress($input: UpdateShippingAddressInput) {
    updateShippingAddress(input: $input) {
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      errors {
        ...ApiErrorFields
      }
    }
  }
  ${ApiErrorFieldsFragmentDoc}
`;
export const MyStageDocument = gql`
  query myStage($favoriteType: FavoriteType!, $playbackProgressType: PlaybackProgressType!, $first: Int = 24) {
    currentUser {
      favorites(filter: $favoriteType, first: $first) {
        edges {
          node {
            ...favoritesFields
          }
        }
      }
      recentlyPlayed(filter: $playbackProgressType, first: $first) {
        edges {
          node {
            ...playbackProgressFields
          }
        }
      }
    }
  }
  ${FavoritesFieldsFragmentDoc}
  ${PlaybackProgressFieldsFragmentDoc}
`;
export const MyStageFavoritesDocument = gql`
  query myStageFavorites($favoriteType: FavoriteType!, $first: Int!, $cursor: String) {
    currentUser {
      favorites(filter: $favoriteType, first: $first, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...favoritesFields
          }
        }
      }
    }
  }
  ${FavoritesFieldsFragmentDoc}
`;
export const MyStageRecentlyPlayedDocument = gql`
  query myStageRecentlyPlayed($playbackProgressType: PlaybackProgressType!, $first: Int!, $cursor: String) {
    currentUser {
      recentlyPlayed(filter: $playbackProgressType, first: $first, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...playbackProgressFields
          }
        }
      }
    }
  }
  ${PlaybackProgressFieldsFragmentDoc}
`;
export const PartnerPageDocument = gql`
  query partnerPage($partnerId: ID!, $sliderItemsFirst: Int = 24) {
    partner(id: $partnerId) {
      __typename
      id
      name
      description
      typeDisplayName
      subtypeDisplayName
      pictures {
        ...PictureFields
      }
      curatedContent {
        ...curatedContentContainerFields
      }
    }
  }
  ${PictureFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
`;
export const PaymentPageDocument = gql`
  query paymentPage($couponCode: String) {
    currentUser {
      id
      ticket {
        status
        validUntil
        id
        isTrial
        ticketType
      }
    }
    subscriptionPlansStripe(couponCode: $couponCode) {
      ...subscriptionPlansStripeCouponFields
      ...subscriptionPlansStripePlansFields
    }
  }
  ${SubscriptionPlansStripeCouponFieldsFragmentDoc}
  ${SubscriptionPlansStripePlansFieldsFragmentDoc}
`;
export const PlaybackProgressDocument = gql`
  query playbackProgress($id: ID!) {
    playbackProgressByContentId(contentId: $id) {
      mark
    }
  }
`;
export const PlayerContentDocument = gql`
  query playerContent($contentId: ID!) {
    node(id: $contentId) {
      __typename
      ...LiveConcertFields
      ... on LiveConcert {
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        groups {
          id
          name
          typeDisplayName
        }
        shortDescription
        isAtmos
        performanceWorks {
          ...livePerformanceWorkFields
        }
        liveConcertType: type
      }
      ...VideoFields
      ... on Video {
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        isrc
        trailerIsrc
        description
        isAtmos
        videoType: type
      }
      ...AlbumFields
      ... on Album {
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        groups {
          id
          name
          typeDisplayName
        }
        upc
        atmosUpc
        totalDuration
        releaseDate
        trackSets {
          ...TrackSetFields
        }
      }
      ...VodConcertFields
      ... on VodConcert {
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        groups {
          id
          name
          typeDisplayName
        }
        performanceWorks {
          ...PerformanceWorkFields
          isrc
        }
        isrc
        trailerIsrc
        shortDescription
        isAtmos
        vodConcertType: type
      }
    }
  }
  ${LiveConcertFieldsFragmentDoc}
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${LivePerformanceWorkFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${AlbumFieldsFragmentDoc}
  ${TrackSetFieldsFragmentDoc}
  ${VodConcertFieldsFragmentDoc}
  ${PerformanceWorkFieldsFragmentDoc}
`;
export const PreviewSubscriptionPlanStripeDocument = gql`
  query previewSubscriptionPlanStripe($productId: String!) {
    previewSubscriptionPlanStripe(productId: $productId) {
      billedNextAt
      currency
      previewPlan {
        interval
        productId
      }
      prorationDate
      subscriptionStartsAt
      total
      paymentMethod {
        ...PaymentMethodFields
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export const ProvincesDocument = gql`
  query provinces($countryCode: String!) {
    provinces(countryCode: $countryCode) {
      __typename
      code
      displayName
    }
  }
`;
export const ReminderModalDocument = gql`
  query reminderModal($liveConcertId: ID!) {
    liveConcert(id: $liveConcertId) {
      __typename
      endTime
      finalEndTime
      id
      pictures {
        ...PictureFields
      }
      reruns {
        ...liveConcertRerunFields
      }
      seriesTitle
      shortDescription
      startTime
      streamStartTime
      stream {
        ...liveStreamFields
      }
      subtitle
      title
      type
      typeDisplayName
    }
  }
  ${PictureFieldsFragmentDoc}
  ${LiveConcertRerunFieldsFragmentDoc}
  ${LiveStreamFieldsFragmentDoc}
`;
export const SearchDetailPageDocument = gql`
  query searchDetailPage($nodeId: ID!) {
    node(id: $nodeId) {
      __typename
      ...ArtistFields
      ...GroupFields
      ...PartnerFields
    }
  }
  ${ArtistFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${PartnerFieldsFragmentDoc}
`;
export const SearchFacetPageDocument = gql`
  query searchFacetPage($facetType: FacetType!) {
    searchByFacetType(input: { facetType: $facetType }) {
      sections {
        glyph
        items {
          id
          count
          label
        }
      }
    }
  }
`;
export const SessionDocument = gql`
  query session {
    currentUser {
      id
      email
      emailVerified
      firstName
      lastName
      insertedAt
      ticket {
        id
        interval
        isTrial
        status
        validUntil
        paymentProvider
        paymentMethodType
        ticketType
      }
      trackingMetadata {
        userType
      }
      hasTrialedBefore
    }
  }
`;
export const ShareModalDocument = gql`
  query shareModal($nodeId: ID!) {
    node(id: $nodeId) {
      __typename
      ... on Album {
        id
        title
        artistAndGroupDisplayInfo
      }
      ... on LiveConcert {
        id
        title
        shortDescription
      }
      ... on Video {
        id
        title
        description
      }
      ... on Track {
        id
        title
      }
      ... on PerformanceWork {
        id
        work {
          title
        }
      }
      ... on VodConcert {
        id
        title
        shortDescription
        title
      }
      ... on Artist {
        id
        name
      }
      ... on Group {
        id
        name
      }
      ... on Partner {
        id
        name
      }
      ... on Epoch {
        id
        title
      }
      ... on Genre {
        id
        title
      }
    }
  }
`;
export const SliderPageDocument = gql`
  query sliderPage($sliderId: ID!, $sliderPageSize: Int!, $cursor: String) {
    slider(id: $sliderId) {
      id
      headline
      sliderItems(first: $sliderPageSize, by: CUSTOM, after: $cursor) {
        totalResults
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            __typename
            ...AlbumFields
            ...ArtistFields
            ...EpochFields
            ...GenreFields
            ...GroupFields
            ...LiveConcertFields
            ...PartnerFields
            ...VideoFields
            ...VodConcertFields
            ...favoritesFields
            ...playbackProgressFields
          }
        }
        publicationLevel
      }
    }
  }
  ${AlbumFieldsFragmentDoc}
  ${ArtistFieldsFragmentDoc}
  ${EpochFieldsFragmentDoc}
  ${GenreFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${LiveConcertFieldsFragmentDoc}
  ${PartnerFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${VodConcertFieldsFragmentDoc}
  ${FavoritesFieldsFragmentDoc}
  ${PlaybackProgressFieldsFragmentDoc}
`;
export const SubscriptionPlansDocument = gql`
  query subscriptionPlans($couponCode: String) {
    subscriptionPlansStripe(couponCode: $couponCode) {
      ...subscriptionPlansStripePlansFields
    }
  }
  ${SubscriptionPlansStripePlansFieldsFragmentDoc}
`;
export const SubscriptionSettingsDocument = gql`
  query subscriptionSettings {
    currentUser {
      hasTrialedBefore
      stripeInvoices {
        billedAt
        currency
        description
        number
        pdfUrl
        total
      }
      ticket {
        interval
        paymentProvider
        status
        validUntil
        stripeBillingInfo {
          amount
          billedFirstAt
          billedNextAt
          currency
          paymentMethod {
            ...PaymentMethodFields
          }
        }
        ticketType
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export const TicketsPageDocument = gql`
  query ticketsPage {
    subscriptionPlansStripe {
      ...subscriptionPlansStripePlansFields
    }
  }
  ${SubscriptionPlansStripePlansFieldsFragmentDoc}
`;
export const VideoDetailPageDocument = gql`
  query videoDetailPage($videoId: ID!, $sliderItemsFirst: Int = 24) {
    node(id: $videoId) {
      __typename
      ...LiveConcertFields
      ... on LiveConcert {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        isAtmos
        liveConcertType: type
        maxResolution
        groups {
          id
          name
          typeDisplayName
        }
        shortDescription
        performanceWorks {
          ...livePerformanceWorkFields
        }
        totalDuration
        curatedContent {
          ...curatedContentContainerFields
        }
        vodConcert {
          __typename
          id
        }
      }
      ...VideoFields
      ... on Video {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        groups {
          id
          name
          typeDisplayName
        }
        isAtmos
        maxResolution
        isLossless
        description
        productionDate
        takedownDate
        uploadDate
        curatedContent {
          ...curatedContentContainerFields
        }
        videoType: type
      }
      ...VodConcertFields
      ... on VodConcert {
        conductors: artists(role: CONDUCTORS) {
          ...ArtistWithRoleConnectionFields
        }
        soloists: artists(role: SOLOISTS) {
          ...ArtistWithRoleConnectionFields
        }
        isAtmos
        maxResolution
        groups {
          id
          name
          typeDisplayName
        }
        performanceWorks {
          ...PerformanceWorkFields
        }
        shortDescription
        productionDate
        takedownDate
        uploadDate
        curatedContent {
          ...curatedContentContainerFields
        }
        vodConcertType: type
      }
    }
  }
  ${LiveConcertFieldsFragmentDoc}
  ${ArtistWithRoleConnectionFieldsFragmentDoc}
  ${LivePerformanceWorkFieldsFragmentDoc}
  ${CuratedContentContainerFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${VodConcertFieldsFragmentDoc}
  ${PerformanceWorkFieldsFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    accountSettings(
      variables?: AccountSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AccountSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AccountSettingsQuery>(AccountSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'accountSettings',
        'query',
        variables,
      );
    },
    artistCollectionPage(
      variables: ArtistCollectionPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ArtistCollectionPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArtistCollectionPageQuery>(ArtistCollectionPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'artistCollectionPage',
        'query',
        variables,
      );
    },
    audioDetailPage(
      variables: AudioDetailPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AudioDetailPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AudioDetailPageQuery>(AudioDetailPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'audioDetailPage',
        'query',
        variables,
      );
    },
    audioPage(
      variables?: AudioPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AudioPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AudioPageQuery>(AudioPageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'audioPage',
        'query',
        variables,
      );
    },
    cancelationReasons(
      variables?: CancelationReasonsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelationReasonsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelationReasonsQuery>(CancelationReasonsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'cancelationReasons',
        'query',
        variables,
      );
    },
    checkEmail(
      variables: CheckEmailQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CheckEmailQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckEmailQuery>(CheckEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'checkEmail',
        'query',
        variables,
      );
    },
    countries(
      variables?: CountriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CountriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CountriesQuery>(CountriesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'countries',
        'query',
        variables,
      );
    },
    couponCode(
      variables: CouponCodeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CouponCodeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CouponCodeQuery>(CouponCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'couponCode',
        'query',
        variables,
      );
    },
    curatedContent(
      variables: CuratedContentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CuratedContentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CuratedContentQuery>(CuratedContentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'curatedContent',
        'query',
        variables,
      );
    },
    curatedContentNodes(
      variables?: CuratedContentNodesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CuratedContentNodesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CuratedContentNodesQuery>(CuratedContentNodesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'curatedContentNodes',
        'query',
        variables,
      );
    },
    devices(variables?: DevicesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DevicesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DevicesQuery>(DevicesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'devices',
        'query',
        variables,
      );
    },
    epochPage(
      variables: EpochPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EpochPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EpochPageQuery>(EpochPageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'epochPage',
        'query',
        variables,
      );
    },
    favoriteByContentId(
      variables: FavoriteByContentIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<FavoriteByContentIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FavoriteByContentIdQuery>(FavoriteByContentIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'favoriteByContentId',
        'query',
        variables,
      );
    },
    genrePage(
      variables: GenrePageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GenrePageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GenrePageQuery>(GenrePageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'genrePage',
        'query',
        variables,
      );
    },
    geoinfo(variables?: GeoinfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GeoinfoQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GeoinfoQuery>(GeoinfoDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'geoinfo',
        'query',
        variables,
      );
    },
    groupPage(
      variables: GroupPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GroupPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GroupPageQuery>(GroupPageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'groupPage',
        'query',
        variables,
      );
    },
    infoModal(
      variables: InfoModalQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<InfoModalQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InfoModalQuery>(InfoModalDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'infoModal',
        'query',
        variables,
      );
    },
    landingPage(
      variables?: LandingPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LandingPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LandingPageQuery>(LandingPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'landingPage',
        'query',
        variables,
      );
    },
    addFavorite(
      variables?: AddFavoriteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddFavoriteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddFavoriteMutation>(AddFavoriteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addFavorite',
        'mutation',
        variables,
      );
    },
    authorizeDeviceForUser(
      variables?: AuthorizeDeviceForUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AuthorizeDeviceForUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthorizeDeviceForUserMutation>(AuthorizeDeviceForUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'authorizeDeviceForUser',
        'mutation',
        variables,
      );
    },
    cancelStripeSubscription(
      variables?: CancelStripeSubscriptionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelStripeSubscriptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelStripeSubscriptionMutation>(CancelStripeSubscriptionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'cancelStripeSubscription',
        'mutation',
        variables,
      );
    },
    changePassword(
      variables?: ChangePasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChangePasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangePasswordMutation>(ChangePasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'changePassword',
        'mutation',
        variables,
      );
    },
    changeStripePaymentMethod(
      variables?: ChangeStripePaymentMethodMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChangeStripePaymentMethodMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangeStripePaymentMethodMutation>(ChangeStripePaymentMethodDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'changeStripePaymentMethod',
        'mutation',
        variables,
      );
    },
    changeStripeSubscriptionPlan(
      variables?: ChangeStripeSubscriptionPlanMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChangeStripeSubscriptionPlanMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangeStripeSubscriptionPlanMutation>(ChangeStripeSubscriptionPlanDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'changeStripeSubscriptionPlan',
        'mutation',
        variables,
      );
    },
    consentTracking(
      variables: ConsentTrackingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ConsentTrackingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConsentTrackingMutation>(ConsentTrackingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'consentTracking',
        'mutation',
        variables,
      );
    },
    deleteUser(
      variables?: DeleteUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserMutation>(DeleteUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteUser',
        'mutation',
        variables,
      );
    },
    generatePinCode(
      variables?: GeneratePinCodeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GeneratePinCodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GeneratePinCodeMutation>(GeneratePinCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'generatePinCode',
        'mutation',
        variables,
      );
    },
    reactivateStripeSubscription(
      variables?: ReactivateStripeSubscriptionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReactivateStripeSubscriptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReactivateStripeSubscriptionMutation>(ReactivateStripeSubscriptionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'reactivateStripeSubscription',
        'mutation',
        variables,
      );
    },
    recordPlaybackProgress(
      variables?: RecordPlaybackProgressMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RecordPlaybackProgressMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RecordPlaybackProgressMutation>(RecordPlaybackProgressDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'recordPlaybackProgress',
        'mutation',
        variables,
      );
    },
    registerUser(
      variables?: RegisterUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RegisterUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterUserMutation>(RegisterUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'registerUser',
        'mutation',
        variables,
      );
    },
    removeDevice(
      variables?: RemoveDeviceMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveDeviceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveDeviceMutation>(RemoveDeviceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'removeDevice',
        'mutation',
        variables,
      );
    },
    removeFavoriteByContentId(
      variables?: RemoveFavoriteByContentIdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveFavoriteByContentIdMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveFavoriteByContentIdMutation>(RemoveFavoriteByContentIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'removeFavoriteByContentId',
        'mutation',
        variables,
      );
    },
    removeFavoriteByContentType(
      variables?: RemoveFavoriteByContentTypeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RemoveFavoriteByContentTypeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveFavoriteByContentTypeMutation>(RemoveFavoriteByContentTypeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'removeFavoriteByContentType',
        'mutation',
        variables,
      );
    },
    resendEmailConfirmation(
      variables?: ResendEmailConfirmationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResendEmailConfirmationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResendEmailConfirmationMutation>(ResendEmailConfirmationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'resendEmailConfirmation',
        'mutation',
        variables,
      );
    },
    resetPassword(
      variables?: ResetPasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(ResetPasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'resetPassword',
        'mutation',
        variables,
      );
    },
    sendChangeEmailAddressEmail(
      variables?: SendChangeEmailAddressEmailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SendChangeEmailAddressEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendChangeEmailAddressEmailMutation>(SendChangeEmailAddressEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'sendChangeEmailAddressEmail',
        'mutation',
        variables,
      );
    },
    sendResetPasswordEmail(
      variables?: SendResetPasswordEmailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SendResetPasswordEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendResetPasswordEmailMutation>(SendResetPasswordEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'sendResetPasswordEmail',
        'mutation',
        variables,
      );
    },
    setCancelationReason(
      variables?: SetCancelationReasonMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SetCancelationReasonMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetCancelationReasonMutation>(SetCancelationReasonDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'setCancelationReason',
        'mutation',
        variables,
      );
    },
    subscribeDgNewsletter(
      variables?: SubscribeDgNewsletterMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscribeDgNewsletterMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeDgNewsletterMutation>(SubscribeDgNewsletterDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'subscribeDgNewsletter',
        'mutation',
        variables,
      );
    },
    subscribeStagePlusEmailNotification(
      variables?: SubscribeStagePlusEmailNotificationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscribeStagePlusEmailNotificationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeStagePlusEmailNotificationMutation>(
            SubscribeStagePlusEmailNotificationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'subscribeStagePlusEmailNotification',
        'mutation',
        variables,
      );
    },
    subscribeViaStripe(
      variables?: SubscribeViaStripeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscribeViaStripeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeViaStripeMutation>(SubscribeViaStripeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'subscribeViaStripe',
        'mutation',
        variables,
      );
    },
    updateShippingAddress(
      variables?: UpdateShippingAddressMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateShippingAddressMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateShippingAddressMutation>(UpdateShippingAddressDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateShippingAddress',
        'mutation',
        variables,
      );
    },
    updateUser(
      variables?: UpdateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateUser',
        'mutation',
        variables,
      );
    },
    myStage(variables: MyStageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MyStageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MyStageQuery>(MyStageDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'myStage',
        'query',
        variables,
      );
    },
    myStageFavorites(
      variables: MyStageFavoritesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MyStageFavoritesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MyStageFavoritesQuery>(MyStageFavoritesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'myStageFavorites',
        'query',
        variables,
      );
    },
    myStageRecentlyPlayed(
      variables: MyStageRecentlyPlayedQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MyStageRecentlyPlayedQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MyStageRecentlyPlayedQuery>(MyStageRecentlyPlayedDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'myStageRecentlyPlayed',
        'query',
        variables,
      );
    },
    partnerPage(
      variables: PartnerPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PartnerPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PartnerPageQuery>(PartnerPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'partnerPage',
        'query',
        variables,
      );
    },
    paymentPage(
      variables?: PaymentPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PaymentPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaymentPageQuery>(PaymentPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'paymentPage',
        'query',
        variables,
      );
    },
    playbackProgress(
      variables: PlaybackProgressQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PlaybackProgressQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlaybackProgressQuery>(PlaybackProgressDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'playbackProgress',
        'query',
        variables,
      );
    },
    playerContent(
      variables: PlayerContentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PlayerContentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlayerContentQuery>(PlayerContentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'playerContent',
        'query',
        variables,
      );
    },
    previewSubscriptionPlanStripe(
      variables: PreviewSubscriptionPlanStripeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PreviewSubscriptionPlanStripeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PreviewSubscriptionPlanStripeQuery>(PreviewSubscriptionPlanStripeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'previewSubscriptionPlanStripe',
        'query',
        variables,
      );
    },
    provinces(
      variables: ProvincesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ProvincesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProvincesQuery>(ProvincesDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'provinces',
        'query',
        variables,
      );
    },
    reminderModal(
      variables: ReminderModalQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReminderModalQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReminderModalQuery>(ReminderModalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'reminderModal',
        'query',
        variables,
      );
    },
    searchDetailPage(
      variables: SearchDetailPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SearchDetailPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchDetailPageQuery>(SearchDetailPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'searchDetailPage',
        'query',
        variables,
      );
    },
    searchFacetPage(
      variables: SearchFacetPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SearchFacetPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchFacetPageQuery>(SearchFacetPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'searchFacetPage',
        'query',
        variables,
      );
    },
    session(variables?: SessionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SessionQuery>(SessionDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'session',
        'query',
        variables,
      );
    },
    shareModal(
      variables: ShareModalQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ShareModalQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShareModalQuery>(ShareModalDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'shareModal',
        'query',
        variables,
      );
    },
    sliderPage(
      variables: SliderPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SliderPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SliderPageQuery>(SliderPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'sliderPage',
        'query',
        variables,
      );
    },
    subscriptionPlans(
      variables?: SubscriptionPlansQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscriptionPlansQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscriptionPlansQuery>(SubscriptionPlansDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'subscriptionPlans',
        'query',
        variables,
      );
    },
    subscriptionSettings(
      variables?: SubscriptionSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SubscriptionSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscriptionSettingsQuery>(SubscriptionSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'subscriptionSettings',
        'query',
        variables,
      );
    },
    ticketsPage(
      variables?: TicketsPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TicketsPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TicketsPageQuery>(TicketsPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ticketsPage',
        'query',
        variables,
      );
    },
    videoDetailPage(
      variables: VideoDetailPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<VideoDetailPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VideoDetailPageQuery>(VideoDetailPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'videoDetailPage',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
